/**
 * 
 * HC-6028
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../api/api'
import { customerCode, userId, userName } from '../data/Atom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';

function HC_6028() {
  const naviagte = useNavigate();
  const userIdValue = useRecoilValue(userId);
  const userNameValue = useRecoilValue(userName);
  const customerCodeValue = useRecoilValue(customerCode);

  const [title, setTitle] = useState('');
  const [divCode, setDivCode] = useState('A');
  const [content, setContent] = useState('');

  function save() {
    if (title === '') {
      Swal.fire({
        title: '실패',
        text: '제목을 입력해주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      })
    } else if (content === '') {
      Swal.fire({
        title: '실패',
        text: '내용을 입력해주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      })
    } else {
      const reqData = {
        "content": content,
        "customerCode": customerCodeValue,
        "divCode": divCode,
        "memberId": userIdValue,
        "memberName": userNameValue,
        "title": title
      }
      api.post('/qst', reqData).then(
        response => {
          Swal.fire({
            title: '성공',
            text: '등록이 완료되었습니다.',
            icon: 'success',
            confirmButtonText: '확인',
          }).then(response => {
            naviagte('/HC_6027')
          })
        }
      ).catch(
        error => {
          console.log("ERROR : " + JSON.stringify(error))
        }
      )
    }
  }

  const titleHandle = (e) => {
    setTitle(e.target.value);
  }

  const divCodeHandle = (e) => {
    setDivCode(e.target.value);
  }

  const contentHandle = (e) => {
    setContent(e.target.value);
  }

  return (<React.Fragment>
    <section>
      <div class="dp-flex list-title">
        <div class="mr-auto">
          <b class="ft-l">1:1 문의등록</b>
        </div>
      </div>
      <div class="space-l"></div>
      <div class="form-wp">
        <div class="input-wp">
          <p>문의제목</p>
          <input class="w-100" type="text" placeholder="문의제목을 입력하세요." value={title} onChange={(e) => titleHandle(e)} />
        </div>
        <div class="input-wp">
          <p>문의유형</p>
          <select class="w-100" value={divCode} onChange={(e) => { divCodeHandle(e) }}>
            <option value="A">시스템문의</option>
            <option value="B">검진문의</option>
          </select>
        </div>
        <div class="input-wp">
          <p>문의내용</p>
          <textarea class="fm-def" style={{ width: '100%', height: '200px' }} placeholder="문의내용을 작성해 주세요."
            value={content} onChange={(e) => contentHandle(e)}
          ></textarea>
        </div>
        <div class="ft-s ft-red">* 상담이 많은 경우 답변이 지연될 수 있습니다.</div>
      </div>
    </section>
    <section>
      <div class="dp-flex">
        <button class="btn page-btn flex1 m-r-s" onClick={() => naviagte(-1)}>취소</button>
        <button class="btn page-btn btn-primary flex2" onClick={() => save()}>문의등록</button>
      </div>
    </section>
  </React.Fragment>)
}

export default HC_6028; 
