/**
 * 
 * HC-6016
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function HC_6016() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  const [drugFlag, setDrugFlag] = useState('N');
  const [confirm1, setConfirm1] = useState('');
  const [confirm2Flag, setConfirm2Flag] = useState('N');
  const [confirm2, setConfirm2] = useState('');
  const [confirm3, setConfirm3] = useState('');

  function dataCheck() {
    if (data === null || data === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    } else {
      if (data.resvConfirm !== null && data.resvConfirm !== undefined) {
        setDrugFlag(data.resvConfirm.confirm1);
        setConfirm1(data.resvConfirm.desc1);
        setConfirm2Flag(data.resvConfirm.confirm2);
        setConfirm2(data.resvConfirm.desc2);
        setConfirm3(data.resvConfirm.desc3);
      }
    }
  }

  function prePage() {
    naviagte('/HC_6015', { state: data })
  }

  function validation() {
    if (drugFlag === 'Y' && confirm1 === '') {
      Swal.fire({
        title: '실패',
        text: '복용 중인 약에 대해 작성해 주세요',
        icon: 'warning',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (confirm2Flag === 'Y' && confirm2 === '') {
      Swal.fire({
        title: '실패',
        text: '질환 또는 수술 이력에 대해 작성해 주세요',
        icon: 'warning',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else {
      return true;
    }
  }

  function nextPage() {
    if (validation()) {
      const resvConfirm = {
        confirm1: drugFlag,
        desc1: confirm1,
        confirm2: confirm2Flag,
        desc2: confirm2,
        confirm3: confirm3 === '' ? 'N' : 'Y',
        desc3: confirm3
      }
      data.resvConfirm = resvConfirm;
      naviagte('/HC_6017', { state: data })
    }
  }


  const confirm1FlagHandle = (data) => {
    setDrugFlag(data);
    if (data === 'N') {
      setConfirm1('');
    }
  }

  const confirm2FlagHandle = (data) => {
    setConfirm2Flag(data);

    if (data === 'N') {
      setConfirm2('');
    }
  }

  const confirm1Handle = (e) => {
    setConfirm1(e.target.value);
  }


  const confirm2Handle = (e) => {
    setConfirm2(e.target.value);
  }


  const confirm3Handle = (e) => {
    setConfirm3(e.target.value);
  }

  useEffect(() => {
    dataCheck()
  }, [])
  return (
    <React.Fragment>
      <div class="pg-title">
        <span>추가확인사항</span>
      </div>
      <section>
        <div class="form-wp">
          <div class="input-wp">
            <p>복용 중인 약이 있나요?</p>
            <div class="radio">
              <label>
                <input type="radio" name="drug"
                  checked={drugFlag === 'N'} onClick={() => confirm1FlagHandle('N')}
                /> 없음
              </label>
              <label>
                <input type="radio" name="drug"
                  checked={drugFlag === 'Y'} onClick={() => confirm1FlagHandle('Y')}
                /> 있음
              </label>
            </div>
            <textarea class="fm-def" style={{ width: '100%', height: '100px' }}
              placeholder="복용 중인 약 이름을 작성해 주세요."
              value={confirm1} onChange={(e) => confirm1Handle(e)}
              readOnly={drugFlag === 'N' ? true : false}
            ></textarea>
          </div>
          <div class="input-wp">
            <p>질환 또는 수술 이력이 있나요?</p>
            <div class="radio">
              <label>
                <input type="radio" name="option"
                  checked={confirm2Flag === 'N'} onClick={() => confirm2FlagHandle('N')}
                /> 없음
              </label>
              <label>
                <input type="radio" name="option"
                  checked={confirm2Flag === 'Y'} onClick={() => confirm2FlagHandle('Y')}
                /> 있음
              </label>
            </div>
            <textarea class="fm-def" style={{ width: '100%', height: '100px' }}
              placeholder="질환 또는 수술 이력을 작성해 주세요."
              value={confirm2} onChange={(e) => confirm2Handle(e)}
              readOnly={confirm2Flag === 'N' ? true : false}
            ></textarea>
          </div>
          <div class="input-wp">
            <p>추가로 전달하실 사항이 있나요?</p>
            <textarea class="fm-def" style={{ width: '100%', height: '100px' }} placeholder="추가 전달하실 사항을 작성해 주세요." value={confirm3} onChange={(e) => confirm3Handle(e)}></textarea>
          </div>
        </div>
      </section>
      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
          <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
        </div>
      </section>
    </React.Fragment>
  )
}

export default HC_6016; 
