/**
 * App 
 * 메인 
 */
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PrivateRoute from './components/common/PrivateRoute';
import TimeExpired  from './components/common/TimeExpired';

import WebLayout from './components/common/WebLayout';
import Home from './pages/HC_6000'
import HC_6001 from './pages/HC_6001'
import HC_6001_certi from './pages/HC_6001_certi';
import HC_6002 from './pages/HC_6002';
import HC_6003 from './pages/HC_6003';
import HC_6004 from './pages/HC_6004';
import HC_6005 from './pages/HC_6005';
import HC_6006 from './pages/HC_6006';
import HC_6007 from './pages/HC_6007';
import HC_6008 from './pages/HC_6008';
import HC_6009 from './pages/HC_6009';
import HC_6010 from './pages/HC_6010';
import HC_6011 from './pages/HC_6011';
import HC_6012 from './pages/HC_6012';
import HC_6013 from './pages/HC_6013';
import HC_6014 from './pages/HC_6014';
import HC_6015 from './pages/HC_6015';
import HC_6016 from './pages/HC_6016';
import HC_6017 from './pages/HC_6017';
import HC_6018 from './pages/HC_6018';
import HC_6019 from './pages/HC_6019';
import HC_6020 from './pages/HC_6020';
import HC_6021 from './pages/HC_6021';
import HC_6022 from './pages/HC_6022';
import HC_6023 from './pages/HC_6023';
import HC_6024 from './pages/HC_6024';
import HC_6027 from './pages/HC_6027';
import HC_6028 from './pages/HC_6028';
import HC_6029 from './pages/HC_6029';
import HC_6009_PREVIEW from './pages/HC_6009_PREVIEW'
import {CacheProvider} from './components/common/CacheProvider';
import HC_Fail from './pages/HC_Fail';
import ErrorPage from './pages/ErrorPage';
function App() {
  return (
    <CacheProvider>
    <Router>
       <TimeExpired />
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route element={<WebLayout />}>
          <Route exact path='HC_6001_certi' element={<HC_6001_certi />} />
          <Route exact path='HC_6002' element={<HC_6002 />} />
          <Route exact path='HC_6003' element={<HC_6003 />} />
          <Route exact path='HC_6004' element={<HC_6004 />} />
          <Route exact path='HC_6005' element={<HC_6005 />} />
          <Route path='HC_6006' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6006 />}
            />
          } />
          <Route exact path='HC_6007' element={<HC_6007 />} />
          <Route exact path='HC_6009_PREVIEW' element={<HC_6009_PREVIEW />} />
          
          <Route path='HC_6008' element={<HC_6008 /> } />
          <Route path='HC_6009' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6009 />}
            />
          } />
          <Route path='HC_6010' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6010 />}
            />
          } />
          <Route path='HC_6011' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6011 />}
            />
          } />
          <Route path='HC_6012' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6012 />}
            />
          } />
          <Route path='HC_6013' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6013 />}
            />
          } />
          <Route path='HC_6014' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6014 />}
            />
          } />
          <Route path='HC_6015' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6015 />}
            />
          } />
          <Route path='HC_6016' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6016 />}
            />
          } />
          <Route path='HC_6017' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6017 />}
            />
          } />
          <Route path='HC_6018' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6018 />}
            />
          } />
          <Route path='HC_6019' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6019 />}
            />
          } />
          <Route path='HC_6020' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6020 />}
            />
          } />
          <Route path='HC_6021' element={<HC_6021 />
          } />
          <Route path='HC_6021/*' element={<HC_6021 />
          } />
          <Route path='HC_6022' element={<HC_6022 />
          } />
           <Route path='HC_6023' element={<HC_6023 />
          } />
          <Route path='HC_6024' element={<HC_6024 />
          } />
          <Route path='HC_6027' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6027 />}
            />
          } />
          <Route path='HC_6028' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6028 />}
            />
          } />
          <Route path='HC_6029' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_6029 />}
            />
          } />
          <Route path='HC_Fail' element={
            <PrivateRoute
              allowedRoles={['CU']}
              component={<HC_Fail />}
            />
          } />
          <Route path='/:customer' element={<HC_6001 />} />
          <Route path='/:customer/:salesIdParam' element={<HC_6001/>} />

          {/* <Route exact path='HC_6006' element={<HC_6006 />} />
          <Route exact path='HC_6007' element={<HC_6007 />} />
          <Route exact path='HC_6008' element={<HC_6008 />} />
          <Route exact path='HC_6009' element={<HC_6009 />} />
          <Route exact path='HC_6010' element={<HC_6010 />} />
          <Route exact path='HC_6011' element={<HC_6011 />} />
          <Route exact path='HC_6012' element={<HC_6012 />} />
          <Route exact path='HC_6013' element={<HC_6013 />} />
          <Route exact path='HC_6014' element={<HC_6014 />} />
          <Route exact path='HC_6015' element={<HC_6015 />} />
          <Route exact path='HC_6016' element={<HC_6016 />} />
          <Route exact path='HC_6017' element={<HC_6017 />} />
          <Route exact path='HC_6018' element={<HC_6018 />} />
          <Route exact path='HC_6019' element={<HC_6019 />} />
          <Route exact path='HC_6020' element={<HC_6020 />} />
          <Route exact path='HC_6021' element={<HC_6021 />} />
          <Route exact path='HC_6022' element={<HC_6022 />} />
          <Route exact path='HC_6025' element={<HC_6025 />} />
          <Route exact path='HC_6026' element={<HC_6026 />} />
          <Route exact path='HC_6027' element={<HC_6027 />} />
          <Route exact path='HC_6028' element={<HC_6028 />} />
          <Route exact path='HC_6029' element={<HC_6029 />} /> */}

        </Route>
        <Route path='/*' element={<ErrorPage/>}/>
        <Route path='/notfound' element={<ErrorPage/>}/>
      </Routes>
      
    </Router>
    </CacheProvider>
  );
}

export default App;
