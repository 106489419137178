import React, { useEffect, useState } from 'react';
import jwtDecode from "jwt-decode";
import api from '../../api/api'
import { useRecoilState } from 'recoil';
import { useNavigate } from "react-router-dom";

import { userName, userId, userRole, customerCode, customerName, salesId, isLogout } from '../../data/Atom';
import Swal from 'sweetalert2';

function TokenCheckAndRenewal() {


    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName);
    const [salesIdValue, setSaledId] = useRecoilState(salesId);
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout);
    const navigate = useNavigate();

    // JWT 만료 체크 및 재갱신 함수
    async function checkAndRenewToken() {
        try {
            const token = localStorage.getItem('access');
            if (token !== null) {
                const decodedToken = jwtDecode(token);

                var sessionStorageData = sessionStorage.getItem('sessionStorage');
                var sessionDataObject = JSON.parse(sessionStorageData);

                const customerCodeValue = sessionDataObject.customerCode
                const salesIdValue = sessionDataObject.salesId
                if (decodedToken.id === '') {
                    localStorage.removeItem('access');
                    setUserName('');
                    setUserId('');
                    setUserRole('');
                    setIsLogout('');
                    //setCustomerCode('')
                    //setCustomerName('')
                } else {
                    if (decodedToken && decodedToken.exp) {
                        const currentTime = Math.floor(Date.now() / 1000); // 현재 시간을 초 단위로 변환
                        const tokenExpiration = decodedToken.exp;
                        const restTime = tokenExpiration - currentTime;
                        const expirationThreshold = 2 * 60; // 2분 (단위: 초)
                        if (restTime <= expirationThreshold) {
                            await renewToken(token); // 토큰 재갱신 함수 호출 (실제로는 해당 로직 구현 필요)
                        }
                    }
                }
            }
        } catch (error) {
            localStorage.removeItem('access');
            // localStorage.removeItem('refresh');
            setUserName('');
            setUserId('');
            setUserRole('');
            setIsLogout('');
            //setCustomerCode('')
            //setCustomerName('')
            // JWT 디코딩 오류 처리
            //console.error('JWT 디코딩 오류:', error);
        }
    }

    // 토큰 재갱신 함수 (가상의 예시)
    async function renewToken(token) {        
        var sessionStorageData = sessionStorage.getItem('sessionStorage');
        var sessionDataObject = JSON.parse(sessionStorageData);

        const customerCodeValue = sessionDataObject.customerCode
        const salesIdValue = sessionDataObject.salesId

        // const refresh = localStorage.getItem('refresh');
        api.post('/token/reGenerate'
            //,{ refreshToken: refresh }
        ).then(
            response => {
                const data = response.data;
                localStorage.setItem('access', data.accessToken);
                // if (refresh !== data.refreshToken) {
                //     localStorage.setItem('refresh', data.refreshToken)
                // }
                return data.accessToken;
            }
        ).catch(
            error => {
                //console.log("ERROR : " + JSON.stringify(error));
                Swal.fire({
                    title: '로그인만료',
                    text: '로그인이 만료되었습니다.',
                    icon: 'info',
                    confirmButtonText: '확인',
                }).then(
                    res => {
                        localStorage.removeItem('access');
                        // localStorage.removeItem('refresh');
                        setUserName('');
                        setUserId('');
                        setUserRole('');
                        setIsLogout('');
                        //setCustomerCode('')
                        //setCustomerName('')
                        if(salesIdValue) {
                            navigate('/'+customerCodeValue+'/'+salesIdValue);
                        }
                        else {
                            navigate('/'+customerCodeValue);
                        }
                        
                    }
                );
            }
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkAndRenewToken();
    }, 60 * 1000); // 60초 (단위: 밀리초)

        return () => {
            clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 제거
        };
    }, []);

    return (
        <React.Fragment></React.Fragment>
    );
}

export default TokenCheckAndRenewal;