/**
 * 
 * HC-6019
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';

import { amountDisplay, dateDisplay, getDayOfWeek } from '../utils/common'
import api from '../api/api'
import { userId, customerCode } from '../data/Atom';

function HC_6019() {
    const naviagte = useNavigate();
    const location = useLocation();
    // let data = location.state; // 전달된 데이터 가져오기
    const tempData = location.state;
    const [click, setClick] = useState(false);
    const [data, setData] = useState('');
    const userIdValue = useRecoilValue(userId);
    const customerCodeValue = useRecoilValue(customerCode);
    const [serviceAmount, setServiceAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [customerName, setCustomerName] = useState(0);

    function dataCheck() {
        if (tempData === null || tempData === undefined) {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        } else {
            if(tempData.type == 'NOMAL') {
                getData()
            }
            if(tempData.type =='TOSS_FAIL') {
                getOriginData();
            }
            //console.log(JSON.stringify(data))
        }
    }

    function getData() {
        let url = '/customer/detail/' + customerCodeValue;
        api.get(url).then(
            response => {
                if (response.data.amt !== null) {
                    setServiceAmount(response.data.amt);
                }
                if (response.data.discountAmt !== null) {
                    setDiscountAmount(response.data.discountAmt);
                }
                if(response.data.name !== null) {
                    setCustomerName(response.data.name)
                }
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
        setData(location.state);
    }
    
    function getOriginData() {
        // let url = '/cu/reserve/' + data.orderId
        api.get('/cu/reserve/' + tempData.orderId).then(response => {
            getData()
            setData(response.data);
        })
    }

    function prePage() {
        naviagte('/HC_6017', { state: data })
    }

    useEffect(() => {
    },[data])

    function nextPage() {
        if(click == false) {
            setClick(true)
            data.customerCode = customerCodeValue;
            data.memberId = userIdValue;
            data.apprAmount = serviceAmount - discountAmount;
            
            if(tempData.type == 'NOMAL') {
                if (data.reserveNo !== null && data.reserveNo !== undefined) {
                    naviagte('/HC_6020', { state: data })
                } else {
                    api.post('/cu/reserve', data).then(
                        response => {
                            data.serviceAmount = data.apprAmount;
                            data.reserveNo = response.data;
                            naviagte('/HC_6020', { state: data })
                        }
                    ).catch(
                        error => {
                            console.log("ERROR : " + JSON.stringify(error))
                            Swal.fire({
                                title: '에러',
                                text: '서버 에러가 발생했습니다. 다시 시도해 주세요',
                                icon: 'error',
                                confirmButtonText: '확인',
                            })
                        }
                    )
                }
            }
            if(tempData.type == 'TOSS_FAIL') {
                data.serviceAmount = data.apprAmount;
                data.orderId = tempData.orderId;
                data.type = tempData.type;
                naviagte('/HC_6020', { state: data })
            }
        }
    }

    useEffect(() => {
        dataCheck();
        setData(location.state);
    }, [])

    return (<React.Fragment>
        {/* {typeof data === 'object' ?<div> */}
        {typeof data.resvInfo != null ?<div>
        <div class="pg-title">
            <span>검진예약확인</span>
        </div>
        <section>
            <div class="list-title">
                <b class="ft-l">검진예약내용</b>
            </div>
            {data.resvInfo && (
            <ul class="list-wrap">
                <li>
                    <div class="dp-flex">
                        <div class="flex1">검진기관명</div>
                        <div class="flex1 align-right"><b>{data.hospitalName}</b></div>
                    </div>
                </li>
                 <li>
                    <div class="dp-flex">
                        <div class="flex1">검진패키지명</div>
                        <div class="flex1 align-right"><b>{data.packageName}</b></div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">희망일</div>
                        <div class="flex1 align-right"><b>
                        {data == undefined ? null : 
                            <>
                            {dateDisplay(data.firstHopeDay)}
                            ({getDayOfWeek(data.firstHopeDay)})
                            </>
                        }
                        </b></div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">수검자</div>
                      
                        <div class="flex1 align-right">
                            <b>{data.resvInfo.name}({data.resvInfo.relationName})</b>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">생년월일</div>
                        <div class="flex1 align-right">
                        {data.resvInfo.bYear != '' && data.resvInfo.bYear != undefined ? 
                        <b>{data.resvInfo.bYear}-{data.resvInfo.bMonth}-{data.resvInfo.bDay}</b>
                        : <b>{data.resvInfo.byear}-{data.resvInfo.bmonth}-{data.resvInfo.bday}</b>
            
                    }
                        </div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">연락처</div>
                        <div class="flex1 align-right"><b>{data.resvInfo.phone}</b></div>
                    </div>
                </li>
            </ul>
            )}
        </section>
        <section>
            <div class="list-title">
                <b class="ft-l">현장결제</b>
            </div>
            {data.resvInfo && (
            <ul class="list-wrap">
                <li>
                    <div class="dp-flex">
                        <div class="flex1">기본검사비용</div>
                        <div class="flex1 align-right"><b>{amountDisplay(data.packageAmount)}원</b></div>
                    </div>
                </li>
               <li>
                    <div class="dp-flex">
                        <div class="flex1">추가검수비용 <span class="ft-orange">({data.addItem.item.filter(i => i.select === 'Y').length}건)</span></div>
                        <div class="flex1 align-right"><b>{amountDisplay(data.addItem.totalAmount)}원</b></div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">결제금액 <span class="ft-gray">(VAT포함)</span></div>
                        <div class="flex1 align-right"><b>{amountDisplay(data.packageAmount + data.addItem.totalAmount)}원</b></div>
                    </div>
                </li>
            </ul>
            )}
        </section>
        <section>
            <div class="box-info box-blue">
                <span class="ft-l">검진비용은 <b class="ft-blue">검진당일 내원 시 직접 결제</b> 하세요.</span>
                <span class="dp-block p-t-s">고객관리비용은 별도의 결제가 필요합니다. <br />결제하셔야 정상적으로 예약신청이 완료됩니다.</span>
            </div>
        </section>
        <section>
            <div class="list-title">
                <b class="ft-l">선결제</b>
            </div>
            <ul class="list-wrap">
                <li>
                    <div class="dp-flex">
                        <div class="flex1">고객관리비용</div>
                        <div class="flex1 align-right"><b>{amountDisplay(serviceAmount)}원</b></div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">{customerName}고객 할인금액</div>
                        <div class="flex1 align-right"><b>{amountDisplay(discountAmount)}원</b></div>
                    </div>
                </li>
                <li>
                    <div class="dp-flex">
                        <div class="flex1">최종고객관리비용</div>
                        <div class="flex1 align-right"><b>{amountDisplay(serviceAmount - discountAmount)}원</b></div>
                    </div>
                </li>
            </ul>
        </section>
        <section>
            <div class="box-info box-red">
                <span class="ft-l"><b class="ft-orange">환불 유의사항</b></span>
                <span class="dp-block p-t-s">병원 안내전화 후 예약이 확정되면, 예약 확정일 이틀 전 까지 취소 및 환불 가능합니다. (취소수수료 없음) 하루 전, 당일 취소 및 노쇼일 경우 환불이 불가할 수 있으며, 고객센터로 별도 문의바랍니다.</span>
            </div>
        </section>
        <section>
            <div class="dp-flex">
                <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
                <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>결제하기</button>
            </div>
        </section>
                    </div>:<></>
        }
    </React.Fragment>)
}

export default HC_6019; 
