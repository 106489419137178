/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-5013
 *
 *
 */
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import { useRecoilValue } from 'recoil';
import { userId } from '../data/Atom';

function HC_6031({ showModal, closeModal, data }) {

  const [modalData, setModalData] = useState();

  function getData() {
      api.get("/memberReserve/detail/" + data.id + '/' + data.reserveSerialNo)
      .then(response => {
          setModalData(response.data)
      }).catch(error => {
          console.log("ERROR : " + JSON.stringify(error))
      })
  }

  


  useEffect(() => {
    if(data != undefined) {
      getData()
    }
      
  }, [showModal])


  const closeHandle = () => {
      closeModal();
  }


  return (
  <React.Fragment>
    {
      showModal && (
        <div id = "HC-6031" class="modal" >
          <div class="modal-flex">
            <div class="modal-flex-content">
              <div>
                <div class="dp-flex">
                  <div class="mr-auto"><h5>예약내역 상세보기</h5></div>
                  <div class="ml-auto"><a class="ml-auto" onClick={() => closeHandle(null)}><i class="icon-close ft-xl"></i></a></div>
                  </div>
                  <div class="space-l"></div>
                  <div class="dp-flex">
                    <div class="lst-group" style={{ width: '400px' }}>
                      <table class="grd" width="100%">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                          <th></th>
                          <th>기본항목</th>
                          <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData && modalData.basicItem.map((row, index) => (
                            <tr>
                              <td></td>
                              <td className="align-center">{row.name}</td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>


                      <table class="grd" width="100%">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                          <th></th>
                          <th>선택항목</th>
                          <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {modalData && modalData.selectItem.map((data, index) => (
                          data.itemRes.map((r,i) => (
                            <tr>
                              <td></td>
                              <td className="align-center">{r.name}</td>
                              <td></td>
                            </tr>))
                          ))}
                        </tbody>
                      </table>

                      
                      <table class="grd" width="100%">
                        <colgroup>
                          <col />
                          <col />
                          <col />
                        </colgroup>
                        <thead>
                          <tr>
                          <th></th>
                          <th>추가항목</th>
                          <th></th>
                          </tr>
                        </thead>
                        <tbody>
                        {modalData ?
                        modalData.addItem.length ==0 ? 
                        <tr>
                              <td></td>
                              <td className="align-center">없음</td>
                              <td></td>
                            </tr>
                        :
                        modalData.addItem.map((data, index) => (
                            <tr>
                              <td></td>
                              <td className="align-center">{data.name}</td>
                              <td></td>
                            </tr>))
                            :
                            <div></div>
                        }
                        </tbody>
                      </table>


                    </div>
                </div>
              </div>
              <div class="dp-flex">
                <button class="btn page-btn flex2 btn-primary" onClick={() => closeHandle()}>확인</button>
              </div>
            </div>
          </div>
        </div>           
      )
    }
  </React.Fragment>
  )
}

export default HC_6031; 
