/**
 * 
 * HC-5020
 *
 *
 */
import React, { useEffect, useContext  } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useCache} from "../components/common/CacheProvider";

function HC_Fail() {
  const naviagte = useNavigate();
  const { cache } = useCache()
  //const location = useLocation();
  //const data = location.state; // 전달된 데이터 가져오기
  const urlParams = new URLSearchParams(window.location.search);
  const errorCode = urlParams.get('code');
  const errorMessage = urlParams.get('message');
  const orderId = urlParams.get('orderId');

  function dataCheck() {
    if (orderId === null || orderId === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    }
    
    // 중복된 orderId에 해당하는 예외메세지
    // 해당메세지를 받았다면 이미 결제가 완료되었다는 것을 의미
    // (orderId는 기본적으로 PK값이라 트래픽이 많아지더라도 중복된 값이 나올 수 없음)

    if(errorCode == 'DUPLICATED_ORDER_ID') {
      naviagte('/HC_6022'); // 예약목록으로 page 이동
    }
    else {
      // const cachedValue = cache[orderId]; // 여기서 어떻게 캐시 꺼내올수도 있을 것 같은데...
      // console.log(cache) // {}
      // console.log(cached[orderId]) // undefined
      // cachedValue.type = 'TOSS_FAIL'; 

    


      Swal.fire({
        title: '에러',
        text: errorMessage,
        icon: 'error',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: '메인화면 이동',
        confirmButtonText: '재 결제 하기',
      }).then(response => {
        if (response.isConfirmed) {
          naviagte('/HC_6019',  { state: {orderId : orderId, type: 'TOSS_FAIL'} });
          // naviagte('/HC_6019',  { state: cachedValue });
        } else if(response.dismiss === Swal.DismissReason.cancel){
          naviagte('/HC_6006');
        }
      })
    }
  }

  useEffect(() => {
    dataCheck()
  }, []);

  return (
    <React.Fragment>
    </React.Fragment>
  )
}

export default HC_Fail; 