/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6001
 *
 *
 */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api/api'
import { dateDisplay } from '../utils/common'
import { amountDisplay, convertBase64ToURL } from '../utils/common';
import jwtDecode from "jwt-decode";
import { useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName, salesId } from '../data/Atom';
import ErrorPage from '../pages/ErrorPage.jsx';



function HC_6001() {
    const { customer, salesIdParam } = useParams();

    const navigate = useNavigate();
    
    const [customerCodeValue, setCustomerCode] = useState()
    const [salesIdValue, setSalesId] = useState()

    const [countValue, setCountValue] = useState(0);
    const [customerNotice, setCustomerNotice] = useState({});
    const [noticeValue, setNoticeValue] = useState([]);
    const [faqValue, setFaqValue] = useState([]);
    const [bgColor, setBgColor] = useState(null);

    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [code, setCode] = useRecoilState(customerCode);
    const [salesIdRecoilValue, setsalesIdRecoil] = useRecoilState(salesId);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName);

    const [image, setImage] = useState(null);
    const [subImage, setSubImage] = useState(null);
    const [logoTitle, setLogoTitle] = useState(null);
    const [logoContent, setLogoContent] = useState(null);
    const isInitialRender = useRef(true);
    const isInitialRender2 = useRef(true);
    const [isRender, setIsRender] = useState(false);
    const [notfound, setNotfound] = useState(false)

    // function checkAuth() {
    //     const token = localStorage.getItem('access');
    //     if (token !== null) {
    //         try {
    //             const decode = jwtDecode(token);
    //             setUserName(decode.name);
    //             setUserId(decode.sub.split("|")[1]);
    //             setUserRole(decode.auth.split("_")[1]);
    //             return true;
    //         } catch (error) {
    //             return false;
    //         }
    //     } else {
    //         return false;
    //     }
    // }


    function getCustomerInfo() {
        if(isInitialRender.current) {
          isInitialRender.current = false
          return
        }
      //   api.get('/customer/customerInfo/'+customerCodeValue).then(
        if(salesIdParam == null) {
            api.get('/guest/salesNotice/logo/'+customerCodeValue).then(
                response => {
                    if(response.data.customerCode == 'NOTFOUND') {
                        setNotfound(true)
                        return ;
                    }

                    if(response.data.customerCode == 'OURCARE') {
                        setCustomerCode(response.data.customerCode)
                    }
                    setCustomerName(response.data.customerName)
                    setLogoTitle(response.data.logoTitle)
                    setLogoContent(response.data.logoContent)
                    setCustomerNotice(response.data);
                    if (response.data.thumbNail !== undefined && response.data.thumbNail !== null) {
                        setImage(convertBase64ToURL(response.data.thumbNail.file));
                    }
                    if (response.data.subThumbNail !== undefined && response.data.subThumbNail !== null) {
                        setSubImage(convertBase64ToURL(response.data.subThumbNail.file));
                    }
                    setBgColor(response.data.bgColor)
                    setIsRender(true)
                }
              ).catch(
                error => {
                  console.log("ERROR : " + JSON.stringify(error))
                }
              )
        }
        else {
            api.get('/guest/salesNotice/logo/'+customerCodeValue + '/' + salesIdParam).then(
                response => {
                    if(response.data.customerCode == 'NOTFOUND') {
                        setNotfound(true)
                        return ;
                    }

                    if(response.data.customerCode == 'OURCARE') {
                        setCustomerCode(response.data.customerCode)
                    }
                    setCustomerName(response.data.customerName)
                    setLogoTitle(response.data.logoTitle)
                    setLogoContent(response.data.logoContent)
                    setCustomerNotice(response.data);
                    if (response.data.thumbNail !== undefined && response.data.thumbNail !== null) {
                        setImage(convertBase64ToURL(response.data.thumbNail.file));
                    }
                    if (response.data.subThumbNail !== undefined && response.data.subThumbNail !== null) {
                        setSubImage(convertBase64ToURL(response.data.subThumbNail.file));
                    }
                    setBgColor(response.data.bgColor)
                    setIsRender(true)
                }
              ).catch(
                error => {
                  console.log("ERROR : " + JSON.stringify(error))
                }
              )
        }
    }


    function getNotice() {
        api.get('/guest/notice/A/simple').then(
            response => {
                setNoticeValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }



    function getFaq() {
        api.get('/guest/notice/B/simple').then(
            response => {
                setFaqValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function goNoticeDetail(divCode, serialNo) {
        api.get('/guest/notice/detail/' + divCode + '/' + serialNo).then(
            response => {
                navigate('/HC_6024', { state: response.data })
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function goNoticeList(div) {
        const sendData = {
            div: div,
            list: div === 'A' ? noticeValue : faqValue
        }
        navigate('/HC_6023', { state: sendData })
    }

    useEffect(() => {
        getNotice()
    }, [])

    useEffect(() => {
        getFaq()
    }, [])

    useEffect(() => {
        //setCode(customer?customer.toUpperCase():'OURCARE')
        setCustomerCode(customer?customer.toUpperCase():'OURCARE')
        setsalesIdRecoil(salesIdParam?salesIdParam:'')
        // setCustomerCode(customer.toUpperCase())
    },[])

    useEffect(() => {
        setCode(customerCodeValue);
        getCustomerInfo();
    },[customerCodeValue]);

    useEffect(() => {
        if(notfound) {
            navigate('/notfound')
        }
    },[notfound])

    return (

        <React.Fragment>
        {notfound ? (
            <ErrorPage/>
        ) : (
            <>
                <section>
                    {isRender ? (
                        <div style={{ background: bgColor }}>
                            {subImage ? (
                                <div><img src={subImage} alt="대체이미지" style={{ width: '100%', height: 'auto', maxHeight: '100px' }} /></div>
                            ) : (
                                <div className="checkup-company-type2 ft-black">
                                    {image && <div className="company-logo"><img src={image} alt="로고자리" style={{ width: '100%', height: 'auto', maxHeight: '100px' }} /></div>}
                                    <div className="motor">{logoTitle}</div>
                                    <div className="introduce">{logoContent}</div>
                                </div>
                            )}
                        </div>
                    ) : null}
                </section>
                <section>
                    <div className="checkup-subject">
                        <b className="ft-blue ft-l">{customerNotice.name}</b>
                        <div className="space-l"></div>
                        <div className="overflow-auto-y" style={{ height: '110px' }}>
                            <div dangerouslySetInnerHTML={{ __html: customerNotice.content }} />
                        </div>
                    </div>
                </section>
                <section>
                    <div className="banner-hospital" onClick={() => { navigate('/HC_6008') }}>
                        검진기관둘러보기
                        <img src="/images/img_hospital.png" alt="" />
                    </div>
                </section>
                <section>
                    <div className="dp-flex list-title">
                        <div className="mr-auto">
                            <b className="ft-l">공지사항</b>
                        </div>
                        <div className="ml-auto">
                            <a onClick={() => { goNoticeList('A') }}><i className="icon-arrow-next"></i></a>
                        </div>
                    </div>
                    <ul className="list-wrap-noti">
                        {noticeValue.map((row) => (
                            <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                                <div className="ellipsis">
                                    <a className="ellipsis">{row.name}</a>
                                </div>
                                <span className="date">{dateDisplay(row.regDate)}</span>
                            </li>
                        ))}
                    </ul>
                </section>
                <section>
                    <div className="dp-flex list-title">
                        <div className="mr-auto">
                            <b className="ft-l">자주하시는 질문</b>
                        </div>
                        <div className="ml-auto">
                            <a onClick={() => { goNoticeList('B') }}><i className="icon-arrow-next"></i></a>
                        </div>
                    </div>
                    <ul className="list-wrap-noti">
                        {faqValue.map((row) => (
                            <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                                <div className="ellipsis">
                                    <a className="ellipsis">{row.name}</a>
                                </div>
                                <span className="date">{dateDisplay(row.regDate)}</span>
                            </li>
                        ))}
                    </ul>
                </section>
            </>
        )}
    </React.Fragment>
        )
}

export default HC_6001; 
