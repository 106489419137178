/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-5012
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import HC_6013 from './HC_6013';

function HC_6012() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  const [displayData, setDisplayData] = useState({});
  const [selectValue, setSelectValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});


  function getData() {
    //TODO 
    if (data !== null && data !== undefined) {
      api.get('/cu/addItem/' + data.hospitalCode).then(
        response => {
          const initSelect = response.data.addItemRes.map((item) => {
            return {
              itemCode: item.itemCode,
              name: item.name,
              amount: item.amount,
              selected: false
            }
          })
          setSelectValue(initSelect);
          setDisplayData(response.data);
        }
      ).catch(
        error => {
          console.log("ERROR : " + JSON.stringify(error))
        }
      )
    } else {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    }
  }

  function getModalData(itemCode) {
    api.get('/cu/addItem/detail/' + data.hospitalCode + '/' + itemCode).then(
      response => {
        response.data.packageName = data.packageName;
        setModalData(response.data);
        setShowModal(true);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
        Swal.fire({
          title: '에러',
          text: '서버 에러가 발생했습니다. 다시 시도해 주세요.',
          icon: 'error',
          confirmButtonText: '확인',
        })
      }
    )
  }

  const showModalHandle = (itemCode) => {
    getModalData(itemCode);
  }

  const closeModal = () => {
    setShowModal(false);
    setModalData({});
  }

  function prePage() {
    naviagte('/HC_6011', { state: data })
  }

  function nextPage() {
    const selectedItems = selectValue
            .map(item => ({
                itemCode: item.itemCode,
                name: item.name,
                amount: item.amount,
                select: item.selected === true ? 'Y' : 'N'
            }));

            let flag = true;
            let cnt = data.CTcnt;
            if(data.hospitalCode == 'HC000013' || data.hospitalCode == 'HC000014' ) {
              selectedItems.map((item) => { 
                    if(item.name.endsWith('CT') && item.select == 'Y') {
                        cnt++;
                    }
                })
                if(cnt> 2) {
                  Swal.fire({
                      title: '알림',
                      text: 'CT는 최대 2개까지 선택 가능합니다.',
                      icon: 'warning',
                      confirmButtonText: '확인',
                  }).then((response) => {
                      return;
                  });
                  flag = false
                }
              }

        const totalAmount = selectedItems.filter(item => item.select === 'Y').reduce((sum, item) => sum + item.amount, 0);
        data.addItem = {
            totalAmount: totalAmount,
            item: selectedItems
        }




        if (flag) {
          let showColonoscopyWarning = false;
          selectedItems.map((item) => {
              if (item.name.includes('대장') && item.name.includes('내시경') && item.select == 'Y') {
                  showColonoscopyWarning = true;
              }
          });
  
          if (showColonoscopyWarning) {
              Swal.fire({
                  title: '알림',
                  html: '* 대장내시경은 만 70세 미만인 경우 검진 가능합니다.<br><span style="color:red">*용종제거 시 별도 비용 발생</span>',
                  icon: 'info',
                  confirmButtonText: '확인',
              }).then((response) => {
                  if (response.isConfirmed) {
                      naviagte('/HC_6014', { state: data });
                  }
              });
          } else {
              naviagte('/HC_6014', { state: data });
          }
      }
      

      // if(flag) {
      //   naviagte('/HC_6014', { state: data })
      // }

  }

  
  function setSelectHandle(itemCode) {
    setSelectValue((prevSelectValue) => {
      const updatedSelectValue = prevSelectValue.map((item) => {
        if (item.itemCode === itemCode) {
          item.selected = !item.selected;
        }
        return item;
      });
      return [...updatedSelectValue];
    });
  }

  function checkedCount() {
    return selectValue.filter(item => item.selected === true).length;
  }

  useEffect(() => {
    getData()
  }, [])

  
  return (
    <React.Fragment>
      <HC_6013 showModal={showModal} closeModal={closeModal} data={modalData} />
      <div class="box-green align-center">
            <h5 class="ellipsis">{displayData.hospitalName}</h5>
      </div>
      <div class="space-l"></div>
      <div class="checkup-subject">
                <b class="ft-blue ft-l">{data.packageName}</b><br></br>
                <b class="ft-blue">각 항목을 탭하시면 상세내용을 확인하실 수 있습니다.</b>
            </div>
      {/* <b class="ft-blue ft-l">[ {data && data.packageName && data.packageName} ]</b> */}
      <div class="space-l"></div>
      <section>
        <div class="list-title">
          {/* <b class="ft-l">추가검사 <span class="ft-orange">({checkedCount()}개 선택)</span></b> */}
          <b class="ft-l">추가검사  <b class= "ft-red fl-m">(고객우대가) </b></b>
        </div>
        <ul class="list-wrap">
          {displayData && displayData.addItemRes &&
            displayData.addItemRes.map((row) => (
              <li>
                <div class="ellipsis">
                  <input type="checkbox" value="" onClick={() => setSelectHandle(row.itemCode)} />&nbsp;&nbsp; <a class="ellipsis" onClick={() => showModalHandle(row.itemCode)}>{row.name}</a>
                </div>
                <span class="ft-blue m-l-l">&nbsp;&nbsp;{row. stringAmount}</span>
              </li>
            ))
          }
        </ul>
      </section>

      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
          <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage('/HC_6014')}>다음</button>
        </div>
      </section>
      {/* 오른쪽 사이드 메뉴 */}
      <div id="float-sidenav" class="sidebanner">
          <a>
              <div class="link-icon" onClick={() => prePage()}><i class="icon-arrow-left-line-bold"></i></div>
              <p>이전</p>
          </a>
          <a>
              <div class="link-icon" onClick={() => nextPage()}><i class="icon-arrow-right-line-bold"></i></div>
              <p>다음</p>
          </a>
      </div>
      {/* 오른쪽 사이드 메뉴  */}
    </React.Fragment>
  )
}

export default HC_6012; 
