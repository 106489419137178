// 1안 - 뒤로가기로 제어

// import React, { useEffect, useState, useRef } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { useSetRecoilState, useRecoilState } from 'recoil';
// import { userName, userId, userRole, customerCode, customerName, isLogout } from '../../data/Atom'
// import Swal from 'sweetalert2';
// import jwtDecode from "jwt-decode";
// import {history}  from '../../data/history'
// import Home from '../../pages/HC_5001'

// function PrivateRoute({ allowedRoles, component: Component }) {
//     const setUserName = useSetRecoilState(userName);
//     const setUserId = useSetRecoilState(userId);
//     const setUserRole = useSetRecoilState(userRole);
//     // const setCustomerCode = useSetRecoilState(customerCode);
//     const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode);
//     const setCustomerName = useSetRecoilState(customerName);
//     const [isLogoutValue, setIsLogout] = useRecoilState(isLogout);

//     var isAuthorized = false;
//     const navigate = useNavigate();
//     const accessToken = localStorage.getItem('access');
//     const [isLogin,setIsLogin] = useState(false)
//     const isInitialRender = useRef(true);

//     useEffect(() => {
//       if (accessToken !== null && allowedRoles !== undefined && allowedRoles !== null
//         ) {
//           const decode = jwtDecode(accessToken);
//           const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로 변환
//           const tokenExpiration = decode.exp;
//           if (tokenExpiration > currentTime) {
//               const role = decode.auth.replace("ROLE_", "");
//               isAuthorized = allowedRoles.includes(role);
//           }
//         }
//         if(!isAuthorized) {
//             if(!isLogoutValue) {
//                 // console.log('if분기')
//                 Swal.fire({
//                     title: '알림',
//                     text: '로그인이 종료되었습니다.',
//                     icon: 'info',
//                     confirmButtonText: '확인',
//                 }).then(
//                     res => {
//                         navigate('/'+customerCodeValue);
//                         setUserName('')
//                         setUserId('')
//                         setUserRole('');
//                         //setCustomerCode('')
//                         //setCustomerName('')
//                         setIsLogout(true)
//                         localStorage.removeItem('access');
//                         localStorage.removeItem('refresh');
//                     }
//                 )
//             }else {
//             navigate(-1)
//             }
//         } else {
//             setIsLogin(true);
//         }
//     })
//     return isLogin ? Component : null
// }

// export default PrivateRoute 



// 2안 - 내용 랜더링 안하기

// import React, { useEffect, useState, useRef } from 'react'
// import { useNavigate } from 'react-router-dom';
// import { useSetRecoilState, useRecoilState } from 'recoil';
// import { userName, userId, userRole, customerCode, customerName, isLogout } from '../../data/Atom'
// import Swal from 'sweetalert2';
// import jwtDecode from "jwt-decode";

// function PrivateRoute({ allowedRoles, component: Component }) {
//     const setUserName = useSetRecoilState(userName);
//     const setUserId = useSetRecoilState(userId);
//     const setUserRole = useSetRecoilState(userRole);
//     // const setCustomerCode = useSetRecoilState(customerCode);
//     const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode);
//     const setCustomerName = useSetRecoilState(customerName);
//     const [isLogoutValue, setIsLogout] = useRecoilState(isLogout);

//     var isAuthorized = false;
//     const navigate = useNavigate();
//     const accessToken = localStorage.getItem('access');
//     const [render,SetRender] = useState(false)
//     const isInitialRender = useRef(true);


//     if (accessToken !== null && allowedRoles !== undefined && allowedRoles !== null
//     ) {
//         const decode = jwtDecode(accessToken);
//         const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로 변환
//         const tokenExpiration = decode.exp;
//         if (tokenExpiration > currentTime) {
//             const role = decode.auth.replace("ROLE_", "");
//             isAuthorized = allowedRoles.includes(role);
//         }
//     }


//     // useEffect(() => {
//     //   if(isInitialRender.current) {
//     //     isInitialRender.current = false
//     //     return
//     //   }
//     //   navigate(-1);
//     // },[render])


//     const error = () => {
//         if(!isLogoutValue) {
//             Swal.fire({
//                 title: '알림',
//                 text: '로그인이 종료되었습니다.',
//                 icon: 'info',
//                 confirmButtonText: '확인',
//             }).then(
//                 res => {
//                     navigate('/'+customerCodeValue);
//                     setUserName('')
//                     setUserId('')
//                     setUserRole('');
//                     //setCustomerCode('')
//                     //setCustomerName('')
//                     setIsLogout(true)
//                     localStorage.removeItem('access');
//                     localStorage.removeItem('refresh');
//                 }
//             )
//         }else {
//             setUserName('')
//             setUserId('')
//             setUserRole('');
//             localStorage.removeItem('access');
//             localStorage.removeItem('refresh');
//         }
//     }
//     return (
//         isAuthorized ? Component : error()
//     )
// }

// export default PrivateRoute 



// 3안 - 메인페이지 연결

import React, { useEffect, useState, useRef, useParams } from 'react'
import { Route, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName, isLogout, salesId } from '../../data/Atom'
import Swal from 'sweetalert2';
import jwtDecode from "jwt-decode";
//import Home from '././pages/HC_5001'
import Home from '../../pages/HC_6001_logout'

function PrivateRoute({ allowedRoles, component: Component }) {

    //const { customer } = useParams();

    const setUserName = useSetRecoilState(userName);
    //const setUserId = useSetRecoilState(userId);
    const setUserRole = useSetRecoilState(userRole);
    // const setCustomerCode = useSetRecoilState(customerCode);
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode);
    const setCustomerName = useSetRecoilState(customerName);
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout);
    const [salesIdValue, setSaledId] = useRecoilState(salesId);
    const [userIdValue, setUserId] = useRecoilState(userId);
    
    var isAuthorized = false;
    const navigate = useNavigate();
    const accessToken = localStorage.getItem('access');
    const [render,SetRender] = useState(false)
    const isInitialRender = useRef(true);
    
    if(userIdValue) {
        isAuthorized = true
    }

    // if (accessToken !== null && allowedRoles !== undefined && allowedRoles !== null
    // ) {
    //     const decode = jwtDecode(accessToken);
    //     const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로 변환
    //     const tokenExpiration = decode.exp;
    //     if (tokenExpiration > currentTime) {
    //         const role = decode.auth.replace("ROLE_", "");
    //         isAuthorized = allowedRoles.includes(role);
    //     }
    // }

    // const error = () => {
    //     if(!isLogoutValue) {
    //         Swal.fire({
    //             title: '알림',
    //             text: '로그인이 종료되었습니다.',
    //             icon: 'info',
    //             confirmButtonText: '확인',
    //         }).then(
    //             res => {
    //                 navigate('/'+customerCodeValue);
    //                 setUserName('')
    //                 setUserId('')
    //                 setUserRole('');
    //                 //setCustomerCode('')
    //                 //setCustomerName('')
    //                 setIsLogout(true)
    //                 localStorage.removeItem('access');
    //                 localStorage.removeItem('refresh');
    //             }
    //         )
    //     }else {
    //         setUserName('')
    //         setUserId('')
    //         setUserRole('');
    //         localStorage.removeItem('access');
    //         localStorage.removeItem('refresh');
    //     }
    // }



    // return (
        // isAuthorized ? Component : <Home customer={customerCodeValue}/>
    if (isAuthorized) {
        return Component;
    }
    if (salesIdValue) {
        return <Home customer={customerCodeValue} salesIdParam = {salesIdValue}/>;
    }
    else {
        return <Home customer={customerCodeValue} />;
    }
    // )
}

export default PrivateRoute 
