/* eslint-disable react-hooks/exhaustive-deps */
/**
 * 
 * HC-6015
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DaumAddress from '../components/common/DaumAddress';
import { formatNumber, phoneValidation, birthValidation } from '../utils/common';
import { customerCode, userId } from '../data/Atom'
import { useRecoilValue } from 'recoil';
import api from '../api/api';

function HC_6015() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기
    const customerCodeValue = useRecoilValue(customerCode);
    const userIdValue = useRecoilValue(userId);
    const [showDaum, setShowDaum] = useState(false);
    const [name, setName] = useState('');
    const [bYear, setBYear] = useState('');
    const [bMonth, setBMonth] = useState('');
    const [bDay, setBDay] = useState('');
    const [sex, setSex] = useState('');
    const [relation, setRelation] = useState('A');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [address, setAddressValue] = useState('');
    const [addressDetail, setAddressDetailValue] = useState('');

    function dataCheck() {
        if (data === null || data === undefined) {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        } else {
            if (data.resvInfo !== null && data.resvInfo !== undefined) {
                setName(data.resvInfo.name);
                setBYear(data.resvInfo.bYear);
                setBMonth(data.resvInfo.bMonth);
                setBDay(data.resvInfo.bDay);
                setSex(data.resvInfo.sex);
                setRelation(data.resvInfo.relation);
                setPhone(data.resvInfo.phone);
                setEmail(data.resvInfo.email);
                setZipCode(data.resvInfo.zipCode);
                setAddressValue(data.resvInfo.address);
                setAddressDetailValue(data.resvInfo.addressDetail);
            } else {
                const url = '/member/' + customerCodeValue + '/' + userIdValue + '/detail';
                api.get(url).then(
                    response => {
                        const res = response.data;
                        if (res.name !== null) {
                            setName(res.name);
                        }
                        if (res.birth !== null && res.birth.length === 8) {
                            setBYear(res.birth.slice(0, 4));
                            setBMonth(res.birth.slice(4, 6));
                            setBDay(res.birth.slice(6, 8));
                            //TODO birth set
                        }

                        if (res.sex !== null) {
                            setSex(res.sex);
                        }

                        if (res.telNo !== null) {
                            setPhone(res.telNo);
                        }

                        if (res.email !== null) {
                            setEmail(res.email);
                        }

                        if (res.zipCode !== null) {
                            setZipCode(res.zipCode);
                        }

                        if (res.address !== null) {
                            setAddressValue(res.address);
                        }

                        if (res.addressDetail !== null) {
                            setAddressDetailValue(res.addressDetail);
                        }
                    }
                ).catch(
                    error => {
                        console.log("ERROR : " + JSON.stringify(error))
                    }
                )
            }
        }
    }

    function getRelationName(value) {
        switch (value) {
            case 'A':
                return '본인';
            case 'B':
                return '배우자';
            case 'C':
                return '부모';
            case 'D':
                return '자녀';
            case 'E':
                return '형제';
            default:
                return '기타';
        }
    }

    function validation() {
        if (name === '') {
            Swal.fire({
                title: '실패',
                text: '수검자 성명을 입력해 주세요',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } else if (bYear === '' || bMonth === '' || bDay === '') {
            Swal.fire({
                title: '실패',
                text: '생년월일을 입력해 주세요',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } else if (phone === '') {
            Swal.fire({
                title: '실패',
                text: '연락처를 입력해 주세요',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } else if (zipCode === '' || address === '') {
            Swal.fire({
                title: '실패',
                text: '주소를 입력해 주세요',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        }else if (addressDetail === '') {
            Swal.fire({
                title: '실패',
                text: '상세주소를 입력해 주세요',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } else {
            if (!phoneValidation(phone)) {
                Swal.fire({
                    title: '실패',
                    text: '유효하지 않은 전화번호 입니다.',
                    icon: 'error',
                    confirmButtonText: '확인',
                }).then(response => {
                    return false;
                })
            }
            else if (!birthValidation(bYear, bMonth, bDay)) {
                Swal.fire({
                    title: '실패',
                    text: '유효하지 않은 생년월일 입니다.',
                    icon: 'error',
                    confirmButtonText: '확인',
                }).then(response => {
                    return false;
                })
            } else {
                return true;
            }
        }
    }

    function prePage() {
        naviagte('/HC_6014', { state: data })
    }


    function nextPage() {
        if (validation()) {
            const resvInfo = {
                name: name,
                bYear: bYear,
                bMonth: bMonth,
                bDay: bDay,
                birth: bYear + bMonth + formatNumber(bDay),
                sex: sex,
                relation: relation,
                relationName: getRelationName(relation),
                phone: phone,
                email: email,
                zipCode: zipCode,
                address: address,
                addressDetail: addressDetail
            }
            data.resvInfo = resvInfo;
            naviagte('/HC_6016', { state: data })
        }
    }

    const nameHandle = (e) => {
        setName(e.target.value)
    }

    const phoneHandle = (e) => {
        const regex = /^[0-9\b -]{0,13}$/;
        if (regex.test(e.target.value)) {
            setPhone(e.target.value);
        } else {
            Swal.fire({
                title: '실패',
                text: '숫자만 입력해 주세요',
                icon: 'error',
                confirmButtonText: '확인',
            })
        }
    }

    const emailHandel = (e) => {
        setEmail(e.target.value);
    }

    const sexHandle = (data) => {
        setSex(data);
    }


    const relationHandle = (e) => {
        if(e.target.value == 'A') {
            setRelation(e.target.value);
            dataCheck()
        }else {
            setRelation(e.target.value);
            setName('')
            setBYear('')
            setBMonth('')
            setBDay('')
            setSex('')
            setPhone('')
            setEmail('')
            setZipCode('')
            setAddressValue('')
            setAddressDetailValue('')
        }
    }


    const bYearHandle = (e) => {
        setBYear(e.target.value);
    }

    const bMonthHandle = (e) => {
        setBMonth(e.target.value);
    }

    const bDayHandle = (e) => {
        setBDay(e.target.value);
    }

    const addressDetailHandel = (e) => {
        setAddressDetailValue(e.target.value);
    }

    const fillAddress = (data) => {
        setShowDaum(false)
        setZipCode(data.zipCode)
        setAddressValue(data.address)
    }

    useEffect(() => {
        dataCheck()
    }, [])

    return (
        <React.Fragment>
            <DaumAddress showModal={showDaum} setAddress={fillAddress} />
            <div class="pg-title">
                <span>수검자정보입력</span>
            </div>
            <section>
                <div class="form-wp">
                    <div class="input-wp">
                        <p>신청자와의 관계</p>
                        <select class="w-100" value={relation} onChange={(e) => relationHandle(e)}>
                            <option value="A">본인</option>
                            <option value="B">배우자</option>
                            <option value="C">부모</option>
                            <option value="D">자녀</option>
                            <option value="E">형제</option>
                            <option value="Z">기타</option>
                        </select>
                    </div>
                    <div class="input-wp">
                        <p>수검자 성명</p>
                        <input class="w-100" type="text" value={name} onChange={(e) => nameHandle(e)} />
                    </div>
                    <div class="input-wp">
                        <p>생년월일</p>
                        <div class="dp-flex">
                            <input class="flex1 m-r-s" placeholder="년(4자)" value={bYear} onChange={(e) => bYearHandle(e)} />
                            <select class="flex1 m-r-s" value={bMonth} onChange={(e) => { bMonthHandle(e) }}>
                                <option value="">월</option>
                                <option value="01">1월</option>
                                <option value="02">2월</option>
                                <option value="03">3월</option>
                                <option value="04">4월</option>
                                <option value="05">5월</option>
                                <option value="06">6월</option>
                                <option value="07">7월</option>
                                <option value="08">8월</option>
                                <option value="09">9월</option>
                                <option value="10">10월</option>
                                <option value="11">11월</option>
                                <option value="12">12월</option>
                            </select>
                            <input class="flex1" placeholder="일" value={bDay} onChange={(e) => { bDayHandle(e) }} />
                        </div>
                    </div>
                    <div class="input-wp">
                        <p>성별</p>
                        <div class="radio">
                            <label>
                                <input type="radio" name="option" id="" value="M"
                                    checked={sex === 'M'} onClick={() => sexHandle('M')} /> 남자
                            </label>
                            <label>
                                <input type="radio" name="option" id="" value="F"
                                    checked={sex === 'F'} onClick={() => sexHandle('F')} /> 여자
                            </label>
                        </div>
                    </div>
                    <div class="input-wp">
                        <p>연락처</p>
                        <input class="w-100" type="text" value={phone} onChange={(e) => { phoneHandle(e) }} />
                    </div>
                    <div class="input-wp">
                        <p>이메일<span class="ft-s ft-gray" value={email} onChange={(e) => { emailHandel(e) }}>(선택)</span></p>
                        <input class="w-100" type="text" />
                    </div>
                    <div class="input-wp">
                        <p>주소</p>
                        <div class="dp-flex m-b-s">
                            <input readOnly class="flex2 m-r-s" type="text" placeholder="주소" value={address} />
                            <button class="flex1 btn-formbt" onClick={() => { setShowDaum(true) }}>검색</button>
                        </div>
                        <div class="space-s"></div>
                        <div class="dp-flex">
                            <input class="flex1" type="text" placeholder="상세주소" value={addressDetail} onChange={addressDetailHandel} />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HC_6015; 
