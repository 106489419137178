import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
    key: "sessionStorage", // 고유한 key 값
    storage: sessionStorage,
  })

export const userName = atom({
    key: 'userName',
    default : '',
    effects_UNSTABLE: [persistAtom],
})

export const userId = atom({
    key: 'userId',
    default : '',
    effects_UNSTABLE: [persistAtom],
})

export const userRole = atom({
    key: 'userRole',
    default : '',
    effects_UNSTABLE: [persistAtom],
})

export const customerCode = atom({
    key: 'customerCode',
    // default: process.env.REACT_APP_CUSTOMER_CODE,
    default: '',
    effects_UNSTABLE: [persistAtom],
})

export const customerName = atom({
    key: 'customerName',
    // default: process.env.REACT_APP_CUSTOMER_CODE,
    default: '아워케어',
    effects_UNSTABLE: [persistAtom],
})

export const isLogin = atom({
    key: 'isLogin',
    default : false,
    effects_UNSTABLE: [persistAtom],
})

export const showLogo = atom({
    key: 'showLogo',
    default : false,
    effects_UNSTABLE: [persistAtom],
})

export const isLogout = atom({
    key: 'isLogout',
    default : false,
    effects_UNSTABLE: [persistAtom],
})
export const salesId = atom({
    key: 'salesId',
    default: '',
    effects_UNSTABLE: [persistAtom],
})

export const delayedItem = atom({
    key: 'delayedItem',
    default: '',
    effects_UNSTABLE: [persistAtom],
})

