const PrivateInfoModal_240229 = ({ showModal, closeModal }) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
      };
      
      const cellStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
      };


    const closeHandle = () => {
        closeModal();
      }


    return (
      <>
        {showModal ? 
            <div class="modal">
            <div class="modal-flex">
              <div class="modal-flex-content" style={{ width: '800px' }}>

              <div class="dp-flex">
                  <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
                </div>
                <div class="space-l"></div>
                <b class="ft-l">개인정보 처리방침</b><br/><br/>
                (주)아워케어(이하 “회사”)는 「개인정보 보호법」 등 국내 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br/>

                회사는 개인정보 처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br/>

                회사는 개인정보 처리방침을 개정하는 경우 아워케어 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br/><br/>

                <b>제1조 개인정보의 처리목적, 수집 항목, 보유 및 이용기간</b><br/>
                ① 회사는 회원가입, 상담, 서비스 제공 등을 위해 다음과 같은 개인정보를 필수 또는 선택 항목으로 수집하고 있습니다.<br/>
                -	처리목적 : 회원가입, 관리, 본인인증, 건강검진예약, 신규서비스 개발 및 맞춤 서비스 제공, 서비스 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공<br/>
                -	수집항목 : <br/>
                1) 회원정보 : ID(기업회원은 사번 등), 이름, ID 소유주와의 관계(기업회원의 가족 등 대상자인 경우), 생년월일, 성별, 비밀번호, 주소, 휴대폰번호, 이메일, 추천코드, 고객사코드 등<br/>
                2) 이용정보 : 사이트 이용 기록, 쿠키, CI(Connecting Information)<br/>
                3) 검진정보 : 검진 예약, 확인, 변경, 취소 등<br/>
                4) 결과정보 : 종합검진 결과, 일반검진 결과, 병원/약국 이용내역, 처방약 내역 등<br/>
                -	보유 및 이용기간 : <br/>
                o 개인회원 : 회원탈퇴 시 지체없이 파기<br/>
                o 고객사회원 : 퇴사 또는 고객사와의 계약 종료 시 개인회원의 자격유지<br/>
                1) 퇴사의 경우 개인회원 전환 후 회원탈퇴 시 1년간 보관 후 파기<br/>
                2) 고객사와의 계약종료의 경우 개인회원 전환 후 회원탈퇴 시1년간 보관 후 파기<br/>
                <br/>
                ※ 고객사회원의 경우 퇴직 시 개인회원으로 전환 후 탈퇴 가능<br/>
                ② 선택정보를 입력하지 않을 경우에도 서비스 이용제한은 없습니다.<br/>
                ③ 서비스의 이용 과정이나 사업처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.<br/>
                - IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록<br/>
                ④ 회사는 만 14세 이상의 이용자에 한해서 서비스(회원가입 등)를 제공하는 것을 원칙으로 합니다.<br/>
                ⑤ 아워케어는 재화 또는 서비스를 제공하는 과정에서 공개되는 정보에 정보주체의 민감정보가 포함됨으로써 사생활 침해의 위험성이 있다고 판단하는 때에는 재화 또는 서비스를 제공 전에 민감정보의 공개 가능성 및 비공개를 선택하는 방법을 개인정보처리방침을 통해 알리겠습니다.<br/>
                ⑥ 회사는 회원이 회원자격을 유지하고 있는 동안 수집된 회원의 개인정보를 보유•이용할 수 있습니다.<br/>
                ⑦ 회원이 탈퇴하거나 자격을 상실할 경우에는 회원의 별도 요청이 없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, 회원의 탈퇴 또는 자격상실에도 불구하고 관계법령에 의해 보존하는 경우는 다음과 같습니다<br/><br/>
                <table style={tableStyle}>
                    <tr>
                        <th style={cellStyle}>관계법령</th>
                        <th style={cellStyle}>개인정보</th>
                        <th style={cellStyle}>보존기간</th>
                    </tr>
                    <tr>
                        <td style={cellStyle} rowSpan={4}>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        <td style={cellStyle}>계약 또는 청약철회 등에 관한 기록</td>
                        <td style={cellStyle}>5년</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>대금결제 및 재화 등의 공급에 관한 기록</td>
                        <td style={cellStyle}>5년</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                        <td style={cellStyle}>3년</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>표시*광고에 관한 기록</td>
                        <td style={cellStyle}>6개월</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>국세기본법</td>
                        <td style={cellStyle}>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                        <td style={cellStyle}>5년</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>통신기밀보호법</td>
                        <td style={cellStyle}>이용자의 인터넷 로그 기록</td>
                        <td style={cellStyle}>3개월</td>
                    </tr>
                    <tr>
                        <td style={cellStyle}>위치정보의 보호 및 이용 등에 관한 법률</td>
                        <td style={cellStyle}>위치정보취급대장</td>
                        <td style={cellStyle}>6개월</td>
                    </tr>
                </table>
                <br/>
                <b>제2조 개인정보 수집방법</b><br/>
                ① 회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
                1) 홈페이지(회원가입, 게시판, 제휴), 모바일, 서면양식, 전화/팩스를 통한 회원가입, <br/>
                2) 제휴사로부터의 제공<br/>
                3) 생성정보 수집 툴을 통한 수집<br/><br/>
                <b>제3조 동의의 거부권 및 거부 시의 불이익 고지</b><br/>
                ① 회원은 위와 같은 본인의 개인정보 수집∙이용에 관한 동의를 거부할 권리가 있습니다.<br/>
                ② 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 수집∙이용에 관한 동의를 거부하는 경우에는 회사의 서비스 등의 이용이 불가(본인 여부 및 계약 체결 의사 불명 이유로)하고, 마케팅 활동 및 홍보를 위한 개인정보 수집∙이용 및/또는 선택적 수집∙이용에 관한 동의를 거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나 사은품∙판촉물 제공, 제휴서비스 이용, 할인 혜택 적용 및 포인트 적립 불가 등의 불이익이 있을 수 있습니다.<br/>
                제4조 개인정보의 파기 절차 및 방법<br/>
                ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 개인정보를 파기합니다.<br/>
                ② 이용자로부터 동의받은 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 다른 회원의 개인정보와 분리하여 보관합니다.<br/>
                ③ 개인정보 파기 절차 및 방법은 다음과 같습니다.<br/>
                1) 파기절차<br/>
                회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br/>
                2) 파기방법<br/>
                회사는 전자적 파일 형태로 기록•저장된 개인정보는 기록을 복원할 수 없는 기술적 방법을 이용하여 파기하며, 종이 문서에 기록된 개인정보는 분쇄기로 분쇄하여 파기합니다.<br/><br/>
                <b>제5조 이용자 및 법정대리인의 권리와 그 행사방법</b><br/>
                ① 이용자는 회사에 대해 언제든지 개인정보 열람•정정•삭제•처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
                ② 이용자는 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를 요청할 수 있습니다.<br/>
                ③ 이용자의 개인정보 조회 및 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' )을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를, 각 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보 보호책임자 및 담당자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/>
                ④ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는, 회사는 당해 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
                ⑤ 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 본 방침 “제1조 개인정보의 처리목적, 수집 항목, 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/>
                ⑥ 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br/>
                ⑦ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.<br/>
                ⑧ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                ⑨ 회사는 이용자 권리에 따른 열람의 요구, 정정•삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/>
                ⑩ 이용자는 「개인정보 보호법」 등 기타 정보보호 및 개인정보 관련 법률을 준수하여야 합니다.<br/><br/>
                <b>제6조 개인정보 열람∙정정∙처리정지∙삭제 청구</b><br/>
                ① 이용자는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 담당자에게 할 수 있습니다. 회사는 이용자의 개인정보 열람∙정정∙처리정지∙삭제 청구가 신속하게 처리되도록 노력하겠습니다.<br/>
                -	담당자 : 아워케어 개인정보책임자<br/>
                -	전자우편 : email@ourcare.co.kr<br/>
                -	전화번호 : 1588-XXXX<br/>
                <br/>
                <b>제7조 개인정보의 제3자 제공</b><br/>
                ① 회사는 이용자의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.<br/>
                ② 회사는 아래와 같이 고객의 개인정보를 제3자에게 제공하고 있습니다.<br/>
                -	제공받는자 : 고객이 지정하는 희망 검진예약 기관, 회사와 계약된 관리자(상조, 보험 등)<br/>
                -	제공받는 목적 : 건강검진 예약, 서비스 이용, 검진 전 약품 배송<br/>
                -	제공하는 개인정보 항목 : 이름, 생년월일, 성별, 휴대폰번호, 이메일, 주소, 예약일, 검진과목, 검진요청사항, 복용약, 질환 및 수술이력<br/>
                ③ 본인은 위와 같은 본인의 개인정보 제공에 관한 동의를 거부할 권리가 있습니다. 다만, 계약 체결 등을 위해 필요한 최소한의 개인정보 제공에 관한 동의를 거부하는 경우 회사의 서비스 이용 등이 불가능하거나 업무가 지연되는 등의 불이익이 있을 수 있습니다.<br/>
                ※ 본 제공 동의 이외에도 회사는 고객이 별도로 동의한 경우, 고객이 동의한 바에 따라 제3자에게 개인정보를 제공할 수 있으며, 연체, 부도, 대지급 또는 허위 기타 부정한 방법에 의한 신용질서 문란행위 등 신용도를 판단할 수 있는 정보는 「신용정보의 이용 및 보호에 관한 법률 시행령」 제28조 제1항에 따라 동의 없이 신용정보집중기관, 신용조회회사에게 제공됩니다.<br/>
                <br/>
                <b>제8조 개인정보처리의 위탁</b><br/>
                ① 회사는 더 나은 서비스를 제공하기 위하여 이용자의 개인정보 처리를 외부전문기관에 위탁할 수 있으며, 위탁하는 업무의 내용과 수탁자를 언제든지 쉽게 확인할 수 있도록 회사의 개인정보 처리방침에 지속적으로 게재하여 공개합니다.<br/>
                ② 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br/>
                -	㈜비큐러스정보기술 / 아워케어 홈페이지 유지보수 / 위탁계약 종료 시<br/>
                ③ 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는 지를 감독하고 있습니다.<br/>
                ④ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br/><br/>
                <b>제9조 개인정보의 안전성 확보조치</b><br/>
                회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br/>
                1) 기술적 보호조치<br/>
                • 개인정보처리시스템 등의 접근권한 관리 : 침입차단시스템을 이용하여 외부로부터의 무단접근을 통제하고 있습니다. 또한 개인정보처리시스템에 대한 접근권한 관리를 통하여 개인정보에 대한 접근을 통제하며, 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 5년간 보관하고 있습니다.<br/>
                • 암호화 조치 : 고객의 개인정보는 전송 시 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 등)를 채택하고 있고, 저장 시에는 안전한 암호 알고리즘을 적용하여 관리하고 있습니다.<br/>
                • 접속기록의 보관 및 위∙변조 방지 : 개인정보처리시스템에 접속한 기록을 최소 2년 이상 보관, 관리하고 있으며, 접속 기록이 위∙변조 및 도난, 분실되지 않도록 관리하고 있습니다.<br/>
                2) 관리적 보호조치<br/>
                • 회사는 개인정보 취급 직원을 최소화하고 있으며 각 직원 별 고객정보 접근 권한을 달리하고 수시 보안교육을 통하여 본 방침의 준수를 강조하고 있습니다.<br/>
                • 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.<br/>
                • 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.<br/>
                • 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 위조•변조, 훼손이 유발될 경우 즉각 정보주체에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.<br/>
                3) 물리적 조치<br/>
                • 사무실 접근통제<br/>
                <br/>
                <b>제10조 개인정보 자동 수집 장치의 사용 및 거부에 관한 사항</b><br/>
                ① 회사는 고객의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'를 운용합니다.<br/>
                1) 쿠키의 정의 및 저장 방법 : 쿠키(Cookie)는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다. 쿠키의 경우 고객의 유•무선 단말기는 식별하지만 고객 개개인을 식별하지는 않습니다.<br/>
                2) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br/>
                3) 거부 설정 방법 : 다음의 방법을 통한 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.<br/>
                • Internet Explorer의 경우 : 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 고급<br/>
                • Chrome의 경우 : 웹 브라우저 상단의 맟춤설정 및 제어 > 설정 > 개인정보 및 보안 > 쿠키 및 기타 사이트 데이터<br/>
                4) 쿠키는 브라우저의 종료 시나 로그아웃 시 만료됩니다.<br/>
                5) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br/>
                ② IP Address 정보 수집<br/>
                1) IP Address는 인터넷 망 사업자가 인터넷에 접속하는 이용자의 PC 등 기기에 부여하는 온라인 주소정보를 나타냅니다.<br/>
                ③ 기기정보 수집<br/>
                1) 기기의 구동을 위해 사용되는 S/W를 통해 확인가능한 정보를 일컫습니다. OS(Windows, MAC OS 등), 접속한 브라우저 종류(인터넷 익스플로러, 크롬 등) 등을 수집합니다.<br/>
                2) 모바일 APP의 경우 모바일 플랫폼(안드로이드, IOS 등), 디바이스 ID, 푸시 수신 여부 등 관련 정보를 수집합니다.<br/>
                <br/>
                <b>제11조 권익침해 구제방법</b>
                ① 이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br/>
                ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)<br/>
                • 소관업무 : 개인정보 침해사실 신고, 상담 신청<br/>
                • 홈페이지 : privacy.kisa.or.kr<br/>
                • 전화 : (국번없이) 118<br/>
                • 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층<br/>
                <br/>
                ▶ 개인정보 분쟁조정위원회<br/>
                • 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정<br/>
                • 홈페이지 : www.kopico.go.kr<br/>
                • 전화 : (국번없이) 1833-6972<br/>
                • 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층<br/>
                <br/>
                ▶ 대검찰청<br/>
                • 홈페이지 : www.spo.go.kr<br/>
                • 전화 : (국번없이) 1301<br/>
                <br/>
                ▶ 경찰청<br/>
                • 홈페이지 : ecrm.police.go.kr<br/>
                • 전화 : (국번없이) 182<br/><br/>
                <b>제18조 개인정보 처리방침 변경에 관한 사항</b><br/>
                ① 본 「개인정보 처리방침」은 2024년 02월 29일에 최초 제정되었으며, 정부 및 회사의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 경우에는 개정 최소 7일 전부터 서비스의 ‘공지사항’을 통해 고지하고, 본 정책은 시행일자에 시행됩니다.<br/>
                • 공지일자: 2024년 02월 29일<br/>
                • 시행일자: 2024년 02월 29일<br/><br/>
                <div class="dp-flex">
                <button class="flex1 btn page-btn btn-primary" onClick={() => closeHandle()}>확인</button>
                </div>
                <div class="space-l"></div>
                </div>
                </div>
                </div>
        : <></>}
      </>
    );
  };
  
  export default PrivateInfoModal_240229