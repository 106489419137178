/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 비밀번호 찾기 모달창
 */
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import api from '../api/api'
import Swal from 'sweetalert2';
import { passwordValid } from '../utils/common'
import { useRecoilState } from 'recoil';
import { customerCode, salesId } from '../data/Atom';

function HC_6030({ showModal, closeModal}) {
  
  const navigate = useNavigate();

  const data = {}

  const[id,setId] = useState('');
  const[name,setName] = useState('');
  const[birthDay,setBirthDay] = useState('');
  const[certiType, setCertiType] = useState('A');
  const [phoneSuccess, setPhoneSuccess] = useState(false)
  const [custoemrCodeValue] = useRecoilState(customerCode);
  const [salesIdValue] = useRecoilState(salesId);
  const [modPassword, setModPassword] = useState('');
  const [modPasswordConfirm, setModPasswordConfirm] = useState('');

  const [userData, setUserData] = useState({
    userPhone: '',
    resultCode: '',
    userName: '',
    email: ''
  });


  
  const idHandle = (e) => {
    setId(e.target.value);
  }
  
  const nameHandle = (e) => {
    setName(e.target.value);
  }
  
  const birthDayHandle = (e) => {
    setBirthDay(e.target.value);
  }
  
  

  const closeHandle = () => {
    setCertiType('A')
    closeModal();
  }
  
  function sendMail(data) {
    api.post('/guest/mail/newPassword', data).then(
      response => {
        Swal.fire({
          title: '성공',
          text: '비밀번호를 이메일로 전송했습니다.\n메일을 확인해주세요.',
          icon: 'success',
          confirmButtonText: '확인',
        }).then(response => {
          closeHandle();
        })
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
        Swal.fire({
          title: '실패',
          text: '서버와의 통신이 정상적이지 않습니다. 다시 시도해 주세요.',
          icon: 'error',
          confirmButtonText: '확인',
        })
      }
    )
    
  }

  function findPassword() {
    if(validation()) {
      const data = {
        customerCode: custoemrCodeValue,
        memberId: id,
        name: name,
        birth: birthDay
      }
      api.post('/guest/member/verifyMember', data).then(
        response => {
          if(response.data) {
            sendMail(data);
          }
          else {
            Swal.fire({
              title: '실패',
              text: '유효하지 않은 회원입니다.',
              icon: 'error',
              confirmButtonText: '확인',
            })
          }
        }
      ).catch(
        error => {
          console.log("ERROR : " + JSON.stringify(error))
          Swal.fire({
            title: '실패',
            text: '서버와의 통신이 정상적이지 않습니다. 다시 시도해 주세요.',
            icon: 'error',
            confirmButtonText: '확인',
          })
        }
      )
    }
  }


  const changePwHandle = (e) => {
    setModPassword(e.target.value);
  }

  const confimrPwHandle = (e) => {
    setModPasswordConfirm(e.target.value);
  }

  function phoneValidation() {
  if(!passwordValid(modPassword)) {
      Swal.fire({
        title: '실패',
        text: '비밀번호는 숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (modPassword === '') {
      Swal.fire({
        title: '오류',
        text: '새 비밀번호를 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else if (modPasswordConfirm === '') {
      Swal.fire({
        title: '오류',
        text: '새 비밀번호확인 부분을 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else if (modPassword !== modPasswordConfirm) {
      Swal.fire({
        title: '오류',
        text: '변경할 비밀번호가 일치하지 않습니다',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else {
      return true;
    }
  }

  function modfiy() {
    if (phoneValidation()) {
      const url = '/guest/member/newPassword';
      const reqBody = {
        customerCode: custoemrCodeValue,
        memberId: userData.email,
        newPw: modPassword
      }
      api.patch(
        url,
        reqBody
      ).then(response => {
        if (response.data) {
          Swal.fire({
              title: '변경완료',
              text: '정상적으로 변경 되었습니다.',
              icon: 'success',
              confirmButtonText: '확인',
            }).then((response)=> {
              if(response.isConfirmed) {
                closeHandle();
              }
            })
        } 
        // 응답 처리
      }).catch(error => {
        Swal.fire({
          title: '변경실패',
          text: '비밀번호변경간 오류가 발생했습니다. 다시 시도해 주세요.',
          icon: 'error',
          confirmButtonText: '확인',
        });
      });
    }
  }


  function validation() {
    if (id === '') {
      Swal.fire({
        title: '실패',
        text: '이메일 주소를 입력해주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (name === '') {
      Swal.fire({
        title: '실패',
        text: '성명을 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }  else if (birthDay === '') {
      Swal.fire({
        title: '실패',
        text: '생년월일을 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }  else if (!/^\d{8}$/.test(birthDay)) {
      Swal.fire({
        title: '실패',
        text: '생년월일은 8자리의 숫자로 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else {
      return true;
    }
  }





  useEffect(() => {
    // 인증결과 콜백함수 정의
    const script = document.createElement('script');

  // 변경: 콜백 함수를 즉시 실행하도록 함
  script.text = `
    function result(result) {
      try {
          result = JSON.parse(result);
          document.querySelector('#result').value = JSON.stringify(result, null, 4);

          // onChange 이벤트를 수동으로 트리거
          const event = new Event('input', { bubbles: true });
          document.querySelector('#result').dispatchEvent(event);
          document.querySelector('#result').dispatchEvent(new Event('change'));
      } catch (error) {
          document.querySelector('#result').value = result;
      }
    }`;
    document.body.appendChild(script);

    document.body.appendChild(script);
    }, []);

  const mokPopup = () => {
    window.MOBILEOK.process(process.env.REACT_APP_BASE_URL +"/certi/mok/mok_std_request_find_password", "WB", "result");
    };

    function mobileCerificationSuccess(e) {
        const jsonString = e.target.value;
        const parsedData = JSON.parse(jsonString);
        if(parsedData.result === 'fail') {
          Swal.fire({
            title: '알림',
            html: '가입되지 않은 회원입니다.<br>회원가입을 진행해주세요.',
            icon: 'info',
            confirmButtonText: '확인',
            showCancelButton: true, // 취소 버튼 표시
            cancelButtonText: '취소'
          }).then((response) => {
            if(response.isConfirmed) {
            navigate('/HC_6001_certi')
            }}
          )
          return null;
        }

        setUserData({
          userName: parsedData.userName,
          userPhone: parsedData.userPhone,
          userBirthday: parsedData.userBirthday,
          email: parsedData.email
        });
        setPhoneSuccess(true)      
    }
    useEffect(() => {
      if(phoneSuccess) {
          Swal.fire({
              title: '성공',
              text: '인증이 완료되었습니다.',
              icon: 'success',
              confirmButtonText: '확인',
            }).then((response) => {
              data.certiType = 'phone'
              data.name = userData.userName
              data.phone = userData.userPhone
              data.birth = userData.userBirthday
              data.email = userData.email
              if (response.isConfirmed) {
                setCertiType('P')
              }
          })
      }
  },[userData]) 


    function emailSelect() {
      setCertiType('E')
    }

  return (
    <React.Fragment>
      {
        showModal && (
          <div id="HC-1004" class="modal">
          <div class="modal-flex" >
           <div class="modal-flex-content" style={{ width: '500px' }}>
              <div class="dp-flex">
                <div class="mr-auto"><h5>비밀번호 찾기</h5></div>
                <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
              </div>
                <p>안녕하세요, 고객님<br />'비밀번호 찾기'를 위해 본인인증을 진행해 주세요.</p>
                <div class="space-l"></div>
                {certiType === 'P' && <PhoneCerti
                data = {userData}
                changePwHandle = {changePwHandle}
                confimrPwHandle = {confimrPwHandle}
                modPassword = {modPassword}
                modPasswordConfirm = {modPasswordConfirm}
                modfiy= {modfiy}
                  />}
                {certiType === 'E' && <EmailCerti
                    id={id}
                    name={name}
                    birthDay={birthDay}
                    idHandle={idHandle}
                    nameHandle={nameHandle}
                    birthDayHandle={birthDayHandle}
                    findPassword={findPassword} // 이 부분을 추가
                  />}
                {certiType === 'A' &&
                <div class="certification">
                <div class="bdr-box">
                  <div>
                    <input type='hidden' id="result" onInput={(e) => mobileCerificationSuccess(e)} />
                    </div>
                    <span class="ft-blue ft-m">
                        <p class="m-b-m"><img src="/images/certi_hp.png"/></p>
                        {/* <button onClick={mokPopup}>본인확인 시작(팝업)</button> */}
                        <b>휴대폰 본인인증</b>
                    </span>
                    <div class="space-s"></div>
                    <span class="ft-s ft-gray">휴대전화 본인인증은 본인명의로 발급된 휴대전화로만 인증이 가능합니다.</span>
                  <div class="space-l"></div>
                  <button class="flex1 btn-formbt"  onClick={mokPopup}>인증하기</button>
                </div>
                <div class="bdr-box">
                    <span class="ft-blue ft-m">
                        <p class="m-b-m"><img src="/images/certi_email.png"/></p>
                        <b>이메일 본인인증</b>
                    </span>
                    <div class="space-s"></div>
                    <span class="ft-s ft-gray">이메일 본인인증은 본인 소유의 이메일로만 인증이 가능합니다.</span>
                    <div class="space-l"></div>
                    <button class="flex1 btn-formbt" onClick={emailSelect}>인증하기</button>
                </div>
              </div>
                }
            </div>
          </div>
        </div>
        )
      }

    </React.Fragment>
  )
}



function EmailCerti({ id, name, birthDay, idHandle, nameHandle, birthDayHandle, findPassword }) {
  return (
    <>
      <table width="450px" class="tbl-basic">
        <colgroup>
          <col width="25%" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>아이디(이메일)</th>
            <td><input type="text" class="fm-def" name="" id="" placeholder="이메일주소" style={{ width: '70%' }}
              value={id} onChange={idHandle}
            />
            </td>
          </tr>
          <br />
          <tr>
            <th>성명</th>
            <td><input type="text" class="fm-def" name="" id="" placeholder="이름" style={{ width: '70%' }}
              value={name} onChange={nameHandle}
            />
            </td>
          </tr>
          <br />
          <tr>
            <th>생년월일</th>
            <td><input type="text" class="fm-def" name="" id="" placeholder="생년월일(예 : 19970101)" style={{ width: '70%' }}
              value={birthDay} onChange={birthDayHandle}
            />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="dp-flex">
        <button class="flex1 btn page-btn btn-primary" onClick={() => findPassword()}>비밀번호 찾기</button>
      </div>
    </>
  );
}


function PhoneCerti({ data,changePwHandle, confimrPwHandle, modPassword, modPasswordConfirm, modfiy}) {
  return (
    <>
      <section>
        <div class="form-wp">
          <div class="input-wp">
            <p>아이디 </p>
            <div class="dpview">{data.email}  </div>
          </div>
          <div class="input-wp">
            <p>새 비밀번호</p>
            <input class="w-100" type="password" value={modPassword} onChange={(e) => changePwHandle(e)} />
          </div>
          <div class="input-wp">
            <p>새 비밀번호확인</p>
            <input class="w-100" type="password" value={modPasswordConfirm} onChange={(e) => confimrPwHandle(e)} />
          </div>
        </div>
        <div class="dp-flex">
          <button class="btn page-btn flex2 btn-primary" onClick={() => modfiy()}>변경</button>
        </div>
      </section>
    </>
  );
}

export default HC_6030;
