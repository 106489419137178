/**
 * 회원가입 
 * HC-6002
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { emailValidation} from '../utils/common';
import { useRecoilValue } from 'recoil';
import { customerCode } from '../data/Atom'
import api from '../api/api'
import Swal from 'sweetalert2';


function HC_6001_certi_email({ showEmailModal, closeModal}) {
    const [email, setEmail] = useState('')
    const [certificateCode, setCertificateCode] = useState('')
    const [send, setSend] = useState(false);
    const [repeat, setRepeat] = useState(1);
    const [remainingTime, setRemainingTime] = useState(1800);
    const [resendTime, setResendTime] = useState(0);

    const customerCodeValue = useRecoilValue(customerCode);
    const navigate = useNavigate();


    const data = {};

    useEffect(() => {
        if (remainingTime > 0) {
          const timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
            if(resendTime != 0) {
                setResendTime((prevTime) => prevTime - 1);

            }
          }, 1000);
    
          // 컴포넌트 언마운트 시 타이머 해제
          return () => clearInterval(timer);
        }
      }, [remainingTime]); // remainingTime이 변경될 때마다 useEffect 실행


    function count(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }  

    function resend(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `재발송 (${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds})`
    }

    const closeHandle = () => {
      closeModal();
    }
    
    function sendMail() {
        const req = {
            customerCode : customerCodeValue,
            email : email
        }
        if(resendTime == 0) {
            
 
        if (emailValidation(email)) {
            api.post('/member/emailCertificate', req
            ).then(
                response => {
                  setSend(true)
                  setRemainingTime(600)
                  setResendTime(30 * repeat)
                  setRepeat(repeat + 1)
                  Swal.fire({
                    title: '성공',
                    text: '이메일을 전송했습니다.\n메일을 확인해주세요.',
                    icon: 'success',
                    confirmButtonText: '확인',
                  })
                }
                ).catch(
                    error => {
                        if(error.response.data == '이미 존재하는 아이디 입니다.') {
                            Swal.fire({
                                title: '실패',
                                text: '이미 존재하는 아이디 입니다.',
                                icon: 'error',
                                confirmButtonText: '확인',
                              })      
                        }else {
                            console.log("ERROR : " + JSON.stringify(error))
                            Swal.fire({
                              title: '실패',
                              text: '서버와의 통신이 정상적이지 않습니다. 다시 시도해 주세요.',
                              icon: 'error',
                              confirmButtonText: '확인',
                            })
                        }

                    }
                )
        } else {
            Swal.fire({
                title: '실패',
                text: '올바른 형식의 이메일을 입해해주세요',
                icon: 'error',
                confirmButtonText: '확인',
              })
        }
    } else {
        const minutes = Math.floor(resendTime / 60);
        const remainingSeconds = resendTime % 60;

        Swal.fire({
            title: '실패',
            text: `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} 이후 재발송 가능합니다.`,
            icon: 'error',
            confirmButtonText: '확인',
          })
    }
    }


    function certificate () {
        const req = {
            customerCode : customerCodeValue,
            email : email,
            certificateCode : certificateCode
        }


        api.post('/member/emailCertificateVerify', req
        ).then(
            response => {
              data.certificationCode = response.data
              Swal.fire({
                title: '성공',
                text: '인증이 완료되었습니다.',
                icon: 'success',
                confirmButtonText: '확인',
              }).then((check) => {
                data.email = email;
                data.certiType = 'email'
                if (check.isConfirmed) {
                    navigate('/HC_6002', { state: data });
                }
            })
            }
            ).catch(
                error => {
                    if(error.response.data == '인증코드가 일치하지 않습니다.') {
                        Swal.fire({
                            title: '실패',
                            text: '인증코드가 일치하지 않습니다.',
                            icon: 'error',
                            confirmButtonText: '확인',
                          })      
                    }else {
                        console.log("ERROR : " + JSON.stringify(error))
                        Swal.fire({
                          title: '실패',
                          text: '서버와의 통신이 정상적이지 않습니다. 다시 시도해 주세요.',
                          icon: 'error',
                          confirmButtonText: '확인',
                        })
                    }

                }
            )


    }
  
    
  const emailHandle = (e) => {
    setEmail(e.target.value);
  }
  const certificateCodeHandle = (e) => {
    setCertificateCode(e.target.value)
  }

  
    return (
      <React.Fragment>
        {
          showEmailModal && (
            <div class="modal">
            <div class="modal-flex">
            <div class="modal-flex-content" style={{width:650}}>
                <div class="dp-flex">
                     <div class="mr-auto"><div class="ft-l">이메일 본인인증</div></div>
                     <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
                </div>
                <div class="space-l"></div>
                <ol class="box-border">
                    <li>1. ‘회원가입’을 위해 이메일 주소 입력 후 인증메일 발송을 눌러주세요.</li>
                    <li>2. 인증메일 본문에 있는 6자리 인증번호를 10분안에 입력 후 인증완료를 눌러주세요.</li>
                    <li>3. 인증메일을 못받으셨다면 재발송 버튼을 눌러주세요.</li>
                </ol>
                <div class="space-l"></div>
                
                <section>
                    <div class="form-wp">
                        <div class="input-wp">
                            <p>이메일 주소</p>
                            <div class="dp-flex m-b-s">
                                <input class="flex2 m-r-s" type="text" placeholder="이메일 형식에 맞게 입력해주세요."
                                    value={email} onChange={(e) => emailHandle(e)}/>
                                <button class="flex1 btn-formbt m-r-s" onClick={() => sendMail()}>{send ? resend(resendTime) : '인증메일 발송'}</button>
                                {/* <button class="flex1 btn-formbt">재발송</button> */}
                            </div>
                        </div>
                        <div class="input-wp">
                            <p>인증번호</p>
                            <div class="dp-flex m-b-s">
                                <input class="flex1 m-r-s" type="text" placeholder="인증번호 6자리를 입력해 주세요."
                                value={certificateCode} onChange={(e) => certificateCodeHandle(e)}/>
                                <span class="flex1 ft-s ft-red">
                                   {send ? count(remainingTime) : ''}
                                    </span>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="dp-flex">
                     <button class="flex1 btn page-btn btn-primary" onClick={() => certificate()}>인증완료</button> 
                </div>
            </div>
       </div>
       </div>
          )
        }
  
      </React.Fragment>
    )
  }

export default HC_6001_certi_email; 
