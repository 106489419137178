/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6017
 *
 *
 */
import React from 'react'

function HC_6018({ showModal, closeModal, data }) {
  const closeHandle = () => {
    closeModal();
  }
  // Modal처리필요
  return (<React.Fragment>
    {
      showModal && (
        <div id="HC-6018" class="modal" >
          <div class="modal-flex">
            <div class="modal-flex-content" style={{ width: '600px' }}>
              <div class="dp-flex">
                <div class="mr-auto"><div class="ft-l">동의서 상세조회</div></div>
                <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
              </div>
              <div class="space-l"></div>
              <span class="ft-red ft-s">[{data.mandatory === 'M' ? '필수' : '선택'}]</span> {data.name}
              <div class="space-s"></div>
              <div class="fm-def" style={{ width: '100%', height: '300px' }} dangerouslySetInnerHTML={{ __html: data !== null ? data.content : '' }} />
              <div class="space-l"></div>
              <div class="dp-flex">
                <button class="flex1 btn page-btn btn-primary" onClick={() => closeHandle()}>확인</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  </React.Fragment>)
}

export default HC_6018; 
