/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * 검진기본항목목록조회
 * HC-6009
 *
 */
import React, { useEffect, useState } from 'react'
import { amountDisplay, convertBase64ToURL } from '../utils/common';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import HC_6013 from './HC_6013';

function HC_6010() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기
    const [displayData, setDisplayData] = useState({});
    //const [selRow, setSelRow] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});


    function detailData(row) {
        const url = '/item/' + data.hospitalCode + '/' + data.packageCode + '/' + row.packageDivCode + '/' + row.itemCode;
        api.get(url).then(
            response => {
                setModalData(response.data);
                setShowModal(true);
                //alert(JSON.stringify(response.data))
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getData() {
        if (data !== null && data !== undefined) {
            api.get('/cu/basicItem/' + data.hospitalCode + '/' + data.packageCode).then(
                response => {
                    setDisplayData(response.data);
                }
            ).catch(
                error => {
                    console.log("ERROR : " + JSON.stringify(error))
                }
            )
        } else {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        }
    }

    function selected(row) {
        detailData(row);
        // if (row.itemCode === selRow.itemCode) {
        //     setSelRow({});
        // } else {
        //     detailData(row);
        //     setSelRow(row);
        // }
    }

    function nextPage() {
        const basicItem = displayData.basicItemList.map((item) => {
            return {
                packageDivCode : item.packageDivCode,
                itemCode : item.itemCode,
                select : 'Y'
            }
    });
            data.basicItem = basicItem;
            naviagte('/HC_6011', { state: data })
        // if (selRow.itemCode === null || selRow.itemCode === undefined) {
        //     Swal.fire({
        //         title: '알림',
        //         text: '검진패키지가 선택되지 않았습니다.',
        //         icon: 'info',
        //         confirmButtonText: '확인',
        //     })
        // } else {
        //     data.basicItem = selRow;
        //     naviagte('/HC_5011', { state: data })
        // }
    }

    function prePage(){
        naviagte('/HC_6009', { state: data })
    }


    const closeModal = () => {
        setShowModal(false);
        setModalData({});
    }


    useEffect(() => {
        getData()
    }, [])

    return (
       <React.Fragment>
            <HC_6013 showModal={showModal} closeModal={closeModal} data={modalData} />
            <div class="box-green align-center">
                <h5 class="ellipsis">{displayData.hospitalName}</h5>
            </div>
            <div class="space-m"></div>
            <section>
                <div class="checkup-subject">
                    <b class="ft-blue ft-l">{displayData.name}</b><br></br>
                    <b class= "ft-blue">각 항목을 탭하시면 상세내용을 확인하실 수 있습니다.</b><br />
                    <b class= "ft-red fl-m">(고객우대가) </b><span>{amountDisplay(displayData.amount + 20000)} 원</span>
                    <div class="space-l"></div>
                    <div class="overflow-auto-y">
                        {displayData.benefitDesc} <br />
                    </div>
                </div>
            </section>
            <section>
                <div class="list-title">
                    <b class="ft-l">기본항목 <span class="ft-orange">({
                        displayData && displayData.basicItemList && displayData.basicItemList.length
                    })</span></b>
                </div>
                <ul class="list-wrap">
                    {
                        displayData && displayData.basicItemList && displayData.basicItemList.map((row) => (
                            <li //style={row.itemCode === selRow.itemCode ? { backgroundColor: '#0160DA', color: 'white' } : null}
                                onClick={() => selected(row)}
                            >
                                <div class="ellipsis">
                                    <a class="ellipsis">{row.name}</a>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </section>
            <section>
                <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()} >이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()} >다음</button>
                </div>
            </section>
            {/* 오른쪽 사이드 메뉴 */}
                        <div id="float-sidenav" class="sidebanner">
                <a>
                    <div class="link-icon" onClick={() => prePage()}><i class="icon-arrow-left-line-bold"></i></div>
                    <p>이전</p>
                </a>
                <a>
                    <div class="link-icon" onClick={() => nextPage()}><i class="icon-arrow-right-line-bold"></i></div>
                    <p>다음</p>
                </a>
            </div>
            {/* 오른쪽 사이드 메뉴  */}
        </React.Fragment>
    )
}

export default HC_6010; 
