/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6014
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import moment from 'moment';
import { dateDisplay, getDayOfWeek } from '../utils/common'
import { useRecoilState } from 'recoil';
import { delayedItem } from '../data/Atom';

function HC_6014() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기
    const [currentYear, setCurrentYear] = useState('');
    const [currentMonth, setCurrentMonth] = useState('');
    const [delayItems, setDelayItems] = useState([]);
    const [delayItemValue, setDelayItem] = useRecoilState(delayedItem);
    // const [firstHopeDay, setFirstHopeDay] = useState('');
    // const [secondHopeDay, setSecondHopeDay] = useState('');
    const [possibleDay, setPossibleDay] = useState({});
    // TODO 변경필요
    const [selectDay, setSelectDay] = useState('');
    const [selectDay2, setSelectDay2] = useState('');
    //const dayNames = ['일', '월', '화', '수', '목', '금', '토'];
    function getPossibleDay() {
        if (currentYear !== '' && currentMonth !== '') {
            api.post('/cu/possible/' + data.hospitalCode + '/' + currentYear + '/' + currentMonth,data).then(
                response => {
                    setPossibleDay(response.data);
                    setDelayItems(response.data.delayItems);
                    data.delayItems = response.data.delayItems

                    setDelayItem(response.data.delayItems)
                }
            ).catch(
                error => {
                    console.log("ERROR : " + JSON.stringify(error))
                }
            )
        }

    }

    // function getDayOfWeek(day) {
    //     if (day !== '') {
    //         const dateObject = moment(day, 'YYYYMMDD').toDate(); // "yyyymmdd" 형태의 문자열을 Date 객체로 변환
    //         const dayOfWeek = dateObject.getDay(); // 요일을 숫자로 가져옴 (0부터 6: 일요일부터 토요일)
    //         return dayNames[dayOfWeek];
    //     } else {
    //         return '';
    //     }
    // }


    // 요일을 숫자에서 문자열로 매핑 또는 로케일에 따라 해당 요일의 이름 가져오기



    const getPreviousMonthFirstDay = () => {
        const previousMonth = currentMonth - 1 >= 1 ? currentMonth - 1 : 12;
        const previousMonthYear = previousMonth === 12 ? currentYear - 1 : currentYear;
        setCurrentYear(previousMonthYear);
        setCurrentMonth(previousMonth);
    };

    const getNextMonthFirstDay = () => {
        const nextMonth = currentMonth + 1 <= 12 ? currentMonth + 1 : 1;
        const nextMonthYear = nextMonth === 1 ? currentYear + 1 : currentYear;
        setCurrentYear(nextMonthYear);
        setCurrentMonth(nextMonth);
    };

    function getData() {
        if (data !== null && data !== undefined) {
            const currentDate = new Date();
            setCurrentYear(currentDate.getFullYear());
            setCurrentMonth(currentDate.getMonth() + 1);
        } else {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        }
    }

    function selectDayHandle(index, data) {
        if (index !== 0 && data.possible) {
            if (data.yearsDay === selectDay) {
                setSelectDay('');
            } else if(data.yearsDay === selectDay2) {
                setSelectDay2('');
            }
            else if(selectDay == '') {
                setSelectDay(data.yearsDay);
            }
            else if(selectDay2 == '') {
                setSelectDay2(data.yearsDay);
            }
            else {
                setSelectDay2(data.yearsDay);
            }
        }
    }

    function prePage() {
        naviagte('/HC_6012', { state: data })
    }

    function nextPage() {
        if (validation()) {
            data.firstHopeDay = selectDay;
            data.secondHopeDay = selectDay2;
            naviagte('/HC_6015', { state: data })
        }
    }


    function validation() {
        if (selectDay === '') {
            Swal.fire({
                title: '알림',
                text: '1차 희망일자를 선택해 주세요',
                icon: 'info',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } 
        else if (selectDay2 === '') {
            Swal.fire({
                title: '알림',
                text: '2차 희망일자를 선택해 주세요',
                icon: 'info',
                confirmButtonText: '확인',
            }).then(response => {
                return false;
            })
        } else {
            return true;
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getPossibleDay()
    }, [currentMonth])

    return (
        <React.Fragment>
            <div class="pg-title">
                <span>검진희망일 선택</span>
            </div>
            <section>
                <div class="dp-flex">
                    <a onClick={() => getPreviousMonthFirstDay()}><i class="icon-arrow-prev"></i></a>
                    <div class="m-l-m m-r-m"><b>{possibleDay.baseYear}년 {possibleDay.baseMonth}월</b></div>
                    <a onClick={() => getNextMonthFirstDay()}><i class="icon-arrow-next"></i></a>
                </div>
                <div class="space-s"></div>
                <div class="schedule-month w-100" >
                    <div class="head-wrap">
                        <div class="head holiday">일</div>
                        <div class="head">월</div>
                        <div class="head">화</div>
                        <div class="head">수</div>
                        <div class="head">목</div>
                        <div class="head">금</div>
                        <div class="head">토</div>
                    </div>
                    <div class="body-wrap">
                        {possibleDay.days && possibleDay.days.length === 35 && Array(5).fill().map((_, index) => (
                            <React.Fragment>
                                <div class="week">
                                    {Array(7).fill().map((_, toIndx) => (
                                        <React.Fragment>
                                            <div
                                                onClick={() => selectDayHandle(toIndx, (possibleDay.days[(index * 7) + toIndx]))}
                                                // className={[
                                                //     'day',
                                                //     possibleDay.days[(index * 7) + toIndx].yearsDay === possibleDay.today ? 'selected no-hover' : '',
                                                //     possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'changed' : '',
                                                //     possibleDay.days[(index * 7) + toIndx].possible === false && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'notable' : '',
                                                //     toIndx === 1 ? 'holiday' : ''
                                                // ].join(' ')}
                                                className={[
                                                    'day',
                                                    possibleDay.days[(index * 7) + toIndx].yearsDay === possibleDay.today ? 'notable' : 'selected no-hover',
                                                    possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'changed' : '',
                                                    possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay2 && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'changed' : '',
                                                    possibleDay.days[(index * 7) + toIndx].possible === false && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'notable' : '',
                                                    toIndx === 1 ? 'holiday' : ''
                                                ].join(' ')}
                                            >
                                                {/* <div onClick={() => selectDayHandle(toIndx, (possibleDay.days[(index * 7) + toIndx]))}
                                                class={`day ${possibleDay.days[(index * 7) + toIndx].yearsDay === possibleDay.today ? 'selected' : ''}
                                              ${possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'changed' : ''}
                                              ${possibleDay.days[(index * 7) + toIndx].possible === false && possibleDay.days[(index * 7) + toIndx].yearsDay !== possibleDay.today ? 'notable' : ''}
                                              ${toIndx === 1 ? 'holiday' : ''}`}> */}
                                                <h6 class="date" >{possibleDay.days[(index * 7) + toIndx].day}</h6>
                                                {possibleDay.today && possibleDay.days[(index * 7) + toIndx].yearsDay === possibleDay.today &&
                                                    <div class="item-wrap">
                                                        <div class="item">
                                                            <span class="dp-block">오늘</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay &&
                                                    <div class="item-wrap">
                                                        <div class="item">
                                                            <span class="dp-block">1차 희망일</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    possibleDay.days[(index * 7) + toIndx].yearsDay === selectDay2 &&
                                                    <div class="item-wrap">
                                                        <div class="item">
                                                            <span class="dp-block">2차 희망일</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </React.Fragment>
                        ))}
                        {/* <div class="week">
                            <div class="day holiday">
                                <h6 class="date">19</h6>
                            </div>
                            <div class="day selected">
                                <h6 class="date">18</h6>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="dp-block">오늘</span>
                                    </div>
                                </div>
                            </div>
                            <div class="day changed">
                                <h6 class="date">21</h6>
                                <div class="item-wrap">
                                    <div class="item">
                                        <span class="dp-block">희망일</span>
                                    </div>
                                </div>
                            </div>
                            <div class="day notable">
                                <h6 class="date">22</h6>
                            </div>
                            <div class="day notable">
                                <h6 class="date">23</h6>
                            </div>
                            <div class="day">
                                <h6 class="date">24</h6>
                            </div>
                            <div class="day">
                                <h6 class="date">25</h6>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div class="space-s"></div>
                
                <div className="align-right">
                    <div className="notable-selected ft-s" style={{ display: 'inline-block', marginRight: '10px' }}>
                        <span></span>예약가능
                    </div>
                    <div className="notable-info ft-s" style={{ display: 'inline-block' }}>
                        <span></span>예약불가
                    </div>
                </div>
                {/* <div class="align-right">
                    <div class="notable-selected ft-s">
                        <span></span>
                        예약가능
                    </div>
                    <div class="notable-info ft-s">
                        <span></span>
                        예약불가
                    </div>
                </div> */}
                <div class="space-l"></div>
                <div class="box-purple p-all-l mx-auto align-center">
                    <span class="m-r-l">1차 희망일</span>
                    <span class="ft-l ft-purple"><b>{selectDay !== '' ? dateDisplay(selectDay) + '(' + getDayOfWeek(selectDay) + ')' : ''}</b></span>
                </div>
                <div class="space-l"></div>
                <div class="box-purple p-all-l mx-auto align-center">
                    <span class="m-r-l">2차 희망일</span>
                    <span class="ft-l ft-purple"><b>{selectDay2 !== '' ? dateDisplay(selectDay2) + '(' + getDayOfWeek(selectDay2) + ')' : ''}</b></span>
                </div>
            </section>
            <section>
                <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HC_6014; 
