import { Route, Routes, Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName, salesId, isLogout } from '../../data/Atom';

import Swal from 'sweetalert2';
import api from '../../api/api'


const TimeExpired = () => {
    const [timer, setTimer] = useState(null)

    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName)
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode)
    const [salesIdValue, setSalesId] = useRecoilState(salesId)
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout)
    const navigate = useNavigate();

    function logout() {
        
        var sessionStorageData = sessionStorage.getItem('sessionStorage');
        var sessionDataObject = JSON.parse(sessionStorageData);

        const customerCodeValue = sessionDataObject.customerCode
        const salesIdValue = sessionDataObject.salesId
        const url = '/token/logout/SESSION';
        // const body = {
            // logoutType : 'SESSION',
        // }

        api.post(url).then(
            response => {
                Swal.fire({
                    title: '알림',
                    text: '장시간 미사용으로 로그아웃 되었습니다.',
                    icon: 'info',
                    confirmButtonText: '확인',
                }).then(
                    res => {
                        setUserName('')
                        setUserId('')
                        setUserRole('')
                        setIsLogout('')
                        //setCustomerCode('')
                        // setCustomerName('')
                        localStorage.removeItem('access');
                        // localStorage.removeItem('refresh');

                        if(salesIdValue) {
                            navigate('/'+customerCodeValue+'/'+salesIdValue);
                        }
                        else {
                            navigate('/'+customerCodeValue);
                        }
                    }
                    
                )
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    useEffect(() => {
        // 30분 후 이벤트 실행
        const wait = () => {
            if(userIdValue === '') {
            } else {
                logout()
            }
        }
        // 컴포넌트가 마운트 될 떄마다 타이머 설정
        // const timerId = setTimeout(wait, 1800 * 1000)
        const timerId = setTimeout(wait, 1800 * 1000)
        setTimer(timerId)

        return () => {
            clearTimeout(timerId)
        }
    },[window.location.pathname])
}

export default TimeExpired;