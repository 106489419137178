/**
 * 회원가입 
 * HC-6002
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { customerCode } from '../data/Atom';
import api from '../api/api'
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import HC_6001_certi_email from './HC_6001_certi_email.jsx';
import { phoneDisplay } from '../utils/common.jsx';

function HC_6001_certi() {

  const navigate = useNavigate();
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [phoneSuccess, setPhoneSuccess] = useState(false)
  const customerCodeValue = useRecoilValue(customerCode);
  const data = {}

  const [userData, setUserData] = useState({
    userPhone: '',
    resultCode: '',
    userGender: '',
    userName: '',
    resultMsg: '',
    userBirthday: '',
    certificationCode: ''
  });

  useEffect(() => {
    // 인증결과 콜백함수 정의
    const script = document.createElement('script');

  // 변경: 콜백 함수를 즉시 실행하도록 함
  script.text = `

    function result(result) {
      
      try {
          result = JSON.parse(result);
          document.querySelector('#result').value = JSON.stringify(result, null, 4);

          // onChange 이벤트를 수동으로 트리거
          const event = new Event('input', { bubbles: true });
          document.querySelector('#result').dispatchEvent(event);
          document.querySelector('#result').dispatchEvent(new Event('change'));
      } catch (error) {
          document.querySelector('#result').value = result;

          Swal.fire({
            title: '알림',
            text: '이미 회원가입된 번호입니다.',
            icon: 'info',
            confirmButtonText: '확인',
          })
      }
    }`;
    document.body.appendChild(script);

    document.body.appendChild(script);
    }, []);

  function emailCertification() {
    setShowEmailModal(true)
  }

  const mokPopup = () => {
    window.MOBILEOK.process(process.env.REACT_APP_BASE_URL +"/certi/mok/mok_std_request/"+customerCodeValue, "WB", "result");
    };

    const mobileCerificationSuccess = (e) => {
      const jsonString = e.target.value;
      const parsedData = JSON.parse(jsonString);
  
      if (parsedData.result === 'fail2') {
        Swal.fire({
          title: '알림',
          html: '이미 회원가입된 번호입니다.',
          icon: 'info',
          confirmButtonText: '확인',
          showCancelButton: true, // 취소 버튼 표시
          cancelButtonText: '취소'
        });
      }
  
      if (parsedData.result === 'fail1') { 
        var ment;
        if(parsedData.customerType == 'G') {
          var ment = '혹시 <a style="color:cornflowerblue; font-weight: bold;" href = "https://ourcare.co.kr/'+parsedData.customerCode+'">'+parsedData.companyName+'</a>에서 가입하셨나요?'
        }
        if(parsedData.customerType == 'C') {
          var ment = '혹시 <a style="color:cornflowerblue; font-weight: bold;" href = "https://ourcare.co.kr/'+parsedData.customerCode+'/' + parsedData.salesId+'">'+parsedData.companyName+'</a>에서 가입하셨나요?'
        }
        
        Swal.fire({
          title: '알림',
          html: '이미 회원가입된 번호입니다.<br>' + ment,
          icon: 'info',
          confirmButtonText: '확인',
          showCancelButton: true, // 취소 버튼 표시
          cancelButtonText: '취소'
        }).then((result) => {
          if (result.isConfirmed) {
            if(parsedData.customerType == 'G') {
              window.location.href = `/${parsedData.customerCode}`;
            }
            if(parsedData.customerType == 'C') {
              window.location.href = `/${parsedData.customerCode}/${parsedData.salesId}`;
            }        
          }
        });
      }
  
      if (parsedData.result === 'success') {
        setUserData({
          userName: parsedData.userName,
          userPhone: parsedData.userPhone,
          userBirthday: parsedData.userBirthday,
          userGender: parsedData.userGender,
          certificationCode: parsedData.certiCode
        });
      }
  
      setPhoneSuccess(true);
    };

  const handleLinkClick = (customerCode, salesId) => {
    window.location.href = `/${customerCode}/${salesId}`;
  };

    useEffect(() => {
        if(phoneSuccess) {
            Swal.fire({
                title: '성공',
                text: '인증이 완료되었습니다.',
                icon: 'success',
                confirmButtonText: '확인',
              }).then((response) => {
                data.certiType = 'phone'
                data.name = userData.userName
                data.phone = userData.userPhone
                data.birth = userData.userBirthday
                data.sex = userData.userGender == '1' ? 'M' : 'F'
                data.certificationCode = userData.certificationCode
                if (response.isConfirmed) {
                  navigate('/HC_6002', { state: data });
                }
            })
        }
    },[userData]) 

  const closeModal = () => {
    setShowEmailModal(false);
  }

  return (
    <React.Fragment>
         <HC_6001_certi_email showEmailModal={showEmailModal} closeModal={closeModal}/>
       <div id="container">
            <div class="pg-title">
                <span>본인확인 인증</span>
            </div>
            <section>
                <p>안녕하세요, 고객님<br />'회원가입' 을 위해 본인인증을 진행해 주세요.</p>
                <div class="space-l"></div>
                <div class="certification">
                    <div class="bdr-box">
                        <div>
                        <input type='hidden' id="result" onInput={(e) => mobileCerificationSuccess(e)} />
                        </div>
                        <span class="ft-blue ft-m">
                            <p class="m-b-m"><img src="/images/certi_hp.png"/></p>
                            {/* <button onClick={mokPopup}>본인확인 시작(팝업)</button> */}
                            <b>휴대폰 본인인증</b>
                        </span>
                        <div class="space-s"></div>
                        <span class="ft-s ft-gray">휴대전화 본인인증은 본인명의로 발급된 휴대전화로만 인증이 가능합니다.</span>
                        <div class="space-l"></div>
                        <button class="flex1 btn-formbt"  onClick={mokPopup}>인증하기</button>
                    </div>
                    {/* <div class="bdr-box">
                        <span class="ft-blue ft-m">
                            <p class="m-b-m"><img src="/images/certi_email.png"/></p>
                            <b>이메일 본인인증</b>
                        </span>
                        <div class="space-s"></div>
                        <span class="ft-s ft-gray">이메일 본인인증은 본인 소유의 이메일로만 인증이 가능합니다.</span>
                        <div class="space-l"></div>
                        <button class="flex1 btn-formbt" onClick={() => emailCertification()}>인증하기</button>
                    </div> */}
                </div>
            </section>
        </div>
    </React.Fragment>
  )
}

export default HC_6001_certi; 
