/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6024
 *
 *
 */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {dateDisplay} from '../utils/common'

function HC_6024() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  return (<React.Fragment>
    <h5><a onClick={() => naviagte(-1)}><i class="icon-arrow-left2"></i></a></h5>
    <div class="space-l"></div>
    <section>
      <div class="checkup-subject">
        <b class="ft-blue ft-l">{data.name}</b>
        <div class="space-s"></div>
        <div class="ft-gray">{dateDisplay(data.regDate)}</div>
      </div>
      <div class="space-l"></div>
      <div>
      <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
    </section>
    <section>
      <div class="dp-flex">
        <button class="btn page-btn flex1 btn-primary" onClick={() => naviagte(-1)}>확인</button>
      </div>
    </section>
  </React.Fragment>)
}

export default HC_6024; 
