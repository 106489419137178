/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6023
 *
 *
 */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import { dateDisplay } from '../utils/common';

function HC_6023() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  const div = data.div;
  const listData = data.list;
  function goNoticeDetail(divCode, serialNo) {
    api.get('/notice/detail/' + divCode + '/' + serialNo).then(
      response => {
        naviagte('/HC_6024', { state: response.data })
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  return (<React.Fragment>
    <section>
      <div class="dp-flex list-title">
        <div class="mr-auto">
          <b class="ft-l">{div === 'A' ? '공지사항' : 'FAQ'}</b>
        </div>
      </div>
      <ul class="list-wrap-noti">
        {listData.map((row) => (
          <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
            <div class="ellipsis">
              <a class="ellipsis">{row.name}</a>
            </div>
            <span class="date">{dateDisplay(row.regDate)}</span>
          </li>
        ))}
      </ul>
    </section>
    <section>
      <div class="dp-flex">
        <button class="btn page-btn flex1" onClick={() => naviagte(-1)}>닫기</button>
      </div>
    </section>
  </React.Fragment>)
}

export default HC_6023; 
