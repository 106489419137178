/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * 
 * HC-6027
 *
 *
 */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../api/api'
import { customerCode, userId } from '../data/Atom';
import { useRecoilValue } from 'recoil';
import { dateDisplay } from '../utils/common';

const HC_6027 = () => {
  const naviagte = useNavigate();
  const customerCodeValue = useRecoilValue(customerCode);
  const userIdValue = useRecoilValue(userId);
  const [faq, setFaq] = useState([]);
  function getList() {
    api.get('/cu/qst/simple').then(
      response => {
        setFaq(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  function goFaqDetail(custCode, serialNo) {
    api.get('/qst/detail/'+custCode+'/' + userIdValue + '/' + serialNo).then(
      response => {
        naviagte('/HC_6029', { state: response.data })
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  useEffect(() => {
    getList()
  }, [])

  ///member/simple
  return (<React.Fragment>
    <section>
      <div class="dp-flex list-title">
        <div class="mr-auto">
          <b class="ft-l">1:1 문의</b>
        </div>
      </div>
      <ul class="list-wrap-noti">
        {faq.map((row) => (
          <li onClick={() => goFaqDetail(row.customerCode, row.serialNo)}>
            <div class="ellipsis">
              {row.answerDate !== null && <span className="completed">(답변완료)</span>}
              <a class="ellipsis">{row.title}</a>
            </div>
            <span class="date">{dateDisplay(row.regDate)}</span>
          </li>
        ))}
      </ul>
    </section>
    <section>
      <div class="dp-flex">
        <button class="btn page-btn flex1 m-r-s" onClick={() => naviagte('/HC_6006')}>닫기</button>
        <button class="btn page-btn btn-primary flex2" onClick={() => naviagte('/HC_6028')}>1:1 문의등록</button>
      </div>
    </section>
  </React.Fragment>)
}

export default HC_6027; 
