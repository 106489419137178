/* eslint-disable react-hooks/exhaustive-deps */
/**
 * 정보동의
 * HC-6003
 *
 *
 */
import React, { useEffect, useState } from 'react'
import api from '../api/api'
import { useLocation, useNavigate } from 'react-router-dom';
import { customerCode } from '../data/Atom';
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';

function HC_6003() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  //const customerCodeValue = useRecoilValue(customerCode);
  const customerCodeValue = data.customerCode;

  const [serviceInfo, setServiceInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [marketingInfo, setMarketingInfo] = useState({});
  const [allCheck, setAllCheck] = useState(false);
  const [serviceCheck, setServiceCheck] = useState(false);
  const [personalCheck, setPersonalCheck] = useState(false);
  const [marketingCheck, setMarketingCheck] = useState(false);

  function dataCheck() {
    if (data === null || data === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    }
  }
  
  function getServiceInfo() {
    api.get('/guest/agrDoc/' + customerCodeValue + '/00').then(
      response => {
        setServiceInfo(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }


  function getPersonalInfo() {
    api.get('/guest/agrDoc/' + customerCodeValue + '/10').then(
      response => {
        setPersonalInfo(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  function getMarketingInfo() {
    api.get('/guest/agrDoc/' + customerCodeValue + '/20').then(
      response => {
        setMarketingInfo(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  function checkChange(target) {
    if (target === '00') {
      if(serviceCheck == true) {
        setAllCheck(false)
      }
      setServiceCheck(!serviceCheck);
    }
    else if (target === '10') {
      if(personalCheck == true) {
        setAllCheck(false)
      }
      setPersonalCheck(!personalCheck);
    }
    else {
      if(marketingCheck == true) {
        setAllCheck(false)
      }
      setMarketingCheck(!marketingCheck);
    }
  }

  function validation() {
    if (serviceInfo.mandatory === 'M' && serviceInfo.exposure === 'Y' && !serviceCheck) {
      Swal.fire({
        title: '실패',
        text: '필수 항목 미 동의시 회원가입이 불가능합니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }
    else if (personalInfo.mandatory === 'M' && personalInfo.exposure === 'Y' && !personalCheck) {
      Swal.fire({
        title: '실패',
        text: '필수 항목 미 동의시 회원가입이 불가능합니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (marketingCheck.mandatory === 'M' && marketingInfo.exposure === 'Y' && !marketingCheck) {
      Swal.fire({
        title: '실패',
        text: '필수 항목 미 동의시 회원가입이 불가능합니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else {
      return true;
    }
  }

  function prePage() {
    naviagte(-1, { state: data })
  }

  function reg() {
    if (validation()) {
      const aggList = [
        {
          divCode: '00',
          agree: serviceCheck ? 'Y' : 'N'
        },
        {
          divCode: '10',
          agree: personalCheck ? 'Y' : 'N'
        },
        {
          divCode: '20',
          agree: marketingCheck ? 'Y' : 'N'
        },
      ]
      data.agreement = aggList;
      //alert('data='+JSON.stringify(data))
      api.post('/guest/member', data).then(
        response => {
          naviagte('/HC_6004', { state: response.data });
        }
      ).catch(
        error => {
          console.log("ERROR : " + JSON.stringify(error))
          Swal.fire({
            title: '실패',
            text: error.response.data,
            icon: 'error',
            confirmButtonText: '확인',
          })
        }
      )
    }
  }

  function allCheckedHandle() {
    if(allCheck == true) {
      setAllCheck(false);
      setServiceCheck(false);
      setPersonalCheck(false);
      setMarketingCheck(false);
    }
    else if(serviceCheck == false || personalCheck == false || marketingCheck == false) {
      setAllCheck(true);
      setServiceCheck(true);
      setPersonalCheck(true);
      setMarketingCheck(true);
    }
  }

  useEffect(() => {
    if(serviceCheck == true && personalCheck == true && marketingCheck == true) {
      setAllCheck(true);
    }
  },[serviceCheck, personalCheck, marketingCheck])

  useEffect(() => {
    getServiceInfo()
    getPersonalInfo()
    getMarketingInfo()
  }, [])

  useEffect(() => {
    dataCheck()
  }, [])
  return (
    <React.Fragment>
      <div class="pg-title">
        <span>정보동의</span>
      </div>
      <span class="input-chk">
                    <input type="checkbox" id='allCheck' checked={allCheck} name="" onClick={() => allCheckedHandle()} />
                    <label for="chk" htmlFor='allCheck'>전체동의 </label>
                </span>
      <div class="space-l"></div>
      <hr/>
      {serviceInfo.exposure && serviceInfo.exposure === 'Y' && (
        <section>
          <span class="input-chk">
            <input type="checkbox" id="ser" name="" checked={serviceCheck} onChange={() => checkChange('00')} />
            <label for="ser" ><span class="ft-red">[{serviceInfo.mandatory === 'M' ? '필수' : '선택'}]</span> {serviceInfo.name}</label>
          </span>
          <div class="space-s"></div>
          <div class="fm-def" style={{ width: '100%', height: '150px', 'overflow-y': 'auto' }} dangerouslySetInnerHTML={{ __html: serviceInfo.content }} />
        </section>
      )}

      {personalInfo.exposure && personalInfo.exposure === 'Y' && (
        <section>
          <span class="input-chk">
            <input type="checkbox" id="per" name="" checked={personalCheck} onChange={() => checkChange('10')} />
            <label for="per" ><span class="ft-red">[{personalInfo.mandatory === 'M' ? '필수' : '선택'}]</span> {personalInfo.name}</label>
          </span>
          <div class="space-s"></div>
          <div class="fm-def" style={{ width: '100%', height: '150px', 'overflow-y': 'auto' }} dangerouslySetInnerHTML={{ __html: personalInfo.content }} />
        </section>
      )}
      {
        marketingInfo.exposure && marketingInfo.exposure === 'Y' && (
          <section>
            <span class="input-chk">
              <input type="checkbox" id="mar" name="" checked={marketingCheck} onChange={() => checkChange('20')} />
              <label for="mar"><span class="ft-red">[{marketingInfo.mandatory === 'M' ? '필수' : '선택'}]</span> {marketingInfo.name}</label>
            </span>
            <div class="space-s"></div>
            <div class="fm-def" style={{ width: '100%', height: '150px', 'overflow-y': 'auto' }} dangerouslySetInnerHTML={{ __html: marketingInfo.content }} />
          </section>
        )
      }
      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
          <button class="btn page-btn flex2 btn-primary" onClick={() => reg()}>회원가입신청</button>
        </div>
      </section>
    </React.Fragment>
  )
}

export default HC_6003; 
