/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6001
 *
 *
 */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage.jsx';
import UseWindowDimensions from '../components/common/UseWindowDimensions.jsx';
import AOS from "aos";
import "aos/dist/aos.css"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PrivateInfoModal from '../components/common/privateInfo/PrivateInfoModal_240229'


<link rel="stylesheet" type="text/css" href="/css/style_customer.css" />

    function HC_6000() {

      
      const [showModal, setShowModal] = useState(false);


      const { height, width } = UseWindowDimensions();
      const [packageGroup, setPackageGroup] = useState("A");
      const [customerGroup, setCustomerGroup] = useState("A");
      useEffect(() => {
        if(width < 1150) {
          setPackageGroup("A");
        }
        else if(width < 1870) {
          setPackageGroup("B");
        }
        else {
          setPackageGroup('C');
        }

        /////////
        if(width < 1450) {
          setCustomerGroup("A")
        }else {
          setCustomerGroup("B")
        }

      },[width])


    const navigate = useNavigate();

    const [hospitals] = useState([
        {
          imgSrc: '/images/hospitals/hanaro_kangpook.jpg',
          name: '하나로의료재단-강남',
          address: '서울 강남구 테헤란로 326 역삼아이타워 7 ~ 11층'
        },
        {
          imgSrc: '/images/hospitals/hanaro_kangpook.jpg',
          name: '하나로의료재단-종로',
          address: '서울 종로구 종로 33 그랑서울 1타워 5층'
        },
        {
          imgSrc: '/images/hospitals/GC_kangnam.png',
          name: '녹십자아이메드-강남',
          address: '서울 서초구 서초동 1498-5 마제스타시티 타워1 4층~5층'
        },
        {
          imgSrc: '/images/hospitals/GC_kangpook.png',
          name: '녹십자아이메드-강북',
          address: '서울 중구 을지로 170 을지트윈타워 동관 9~10층'
        },
        {
          imgSrc: '/images/hospitals/mirae.png',
          name: '미래의료재단',
          address: '서울 강남구 테헤란로 513 k타워 B1,9,10층'
        },
        {
          imgSrc: '/images/hospitals/kimpo_uri.png',
          name: '김포우리병원',
          address: '경기도 김포시 감암로 11 김포우리병원'
        },
        {
          imgSrc: '/images/hospitals/jumdan_naun.jpg',
          name: '검단나은병원',
          address: '인천 서구 검단로 508 3~4층'
        },
        {
          imgSrc: '/images/hospitals/daejon_cmi.png',
          name: '대전 중앙의료재단',
          address: '대전 서구 한밭대로 797 (둔산동) 캐피탈타워 B1'
        },
        {
          imgSrc: '/images/hospitals/nationalFitnessCenter.jpg',
          name: '국민체력센터',
          address: '서울 송파구 방이동 88-2 국민체력센터'
        },
        {
          imgSrc: '/images/hospitals/kmi_kwangju.jpg',
          name: 'KMI 한국의학연구소 - 광주',
          address: '광주 서구 상무중앙로 58 차평동, 타임스타워빌딩 8 ~ 10층'
        },
        {
          imgSrc: '/images/hospitals/kmi_kangnam.jpg',
          name: 'KMI 한국의학연구소 - 강남',
          address: '서울 강남구 테헤란로 411 삼성동, 성담빌딩 2~8층, 10층'
        },
        {
            imgSrc: '/images/hospitals/kmi_kwanghwamoon.jpg',
            name: 'KMI 한국의학연구소 - 광화문',
            address: '서울 종로구 세종대로23길 54 당주동, 세종빌딩 1~5층'
        },
        {
            imgSrc: '/images/hospitals/kmi_daegu.jpg',
            name: 'KMI 한국의학연구소 - 대구',
            address: '대구 중구 중앙대로66길 20 남산동, 탑마트 B1 ~ 1층'
        },
        {
            imgSrc: '/images/hospitals/kmi_busan.jpg',
            name: 'KMI 한국의학연구소 - 부산',
            address: '부산 동구 조방로 14 범일동, 동일타워 5층'
        },
        {
            imgSrc: '/images/hospitals/kmi_suwon.jpg',
            name: 'KMI 한국의학연구소 - 수원',
            address: '경기 수원시 권선구 동수원로 232 권선동, 1층 수원검진센터'
        },
        {
            imgSrc: '/images/hospitals/kmi_yeouido.jpg',
            name: 'KMI 한국의학연구소 - 여의도',
            address: '서울 영등포구 국제금융로2길 24 여의도동, BNK금융타워 13, 14, 15, 17, 18층'
        },
        {
            imgSrc: '/images/hospitals/kmi_jeju.jpg',
            name: 'KMI 한국의학연구소 - 제주',
            address: '제주특별자치도 서귀포시 솔오름로 61 동흥동, 제주헬스케어타운 의료서비스센터 1~2층'
        },
        {
            imgSrc: '/images/hospitals/mirae_busan.png',
            name: '부산미래IFC',
            address: '부산 남구 전포대로 133 IFC몰 3층~4층'
        }
      ]);

      let scrollTimeout = null;

      const settings = {
        dots: false, // 개수 표시 점
        infinite: true, // 무한 캐러셀
        speed: 5000, // 다음 컨텐츠 까지의 속도
        slidesToShow: 6, // 화면에 보이는 컨텐츠 수
        slidesToScroll: 3, // 스크롤 시 넘어가는 컨텐츠 수
        centerMode: true, // 현재 컨텐츠 가운데 정렬
        centerPadding: '0px', // 중앙 컨텐츠 padding 값
        autoplay: true, // 자동 캐러셀
        autoplaySpeed: 0, // 자동 캐러셀 속도
        draggable: false, // 드래그      
        fade: false, // 사라졌다 나타나는 효과
        arrows: false, // 좌,우 버튼
        vertical: false, // 세로 캐러셀
        initialSlide: 1, // 첫 컨텐츠 번호
        pauseOnFocus: true, // focus시 정지
        pauseOnHover: true, // hover시 정지
        responsive: [ // 반응형 옵션 
          {
            breakpoint: 100, // (숫자)px 이하일 경우
            settings: {
            slidesToShow: 3,
            arrows:true,
            }
          }
        ]
      };


      useEffect(() => {
        AOS.init({
          once: false, // Animation should happen each time an element becomes visible
          duration: 1000, // Animation duration
        });

        const handleResize = () => {
          AOS.refresh();
        };

        window.addEventListener('resize', handleResize);

        // Call refresh initially to ensure animations are set up correctly
        AOS.refresh();

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


  useEffect(() => {
    AOS.init({
      once: false,
      duration: 1000,
    });

    const handleResize = () => {
      AOS.refresh();
    };

    window.addEventListener('resize', handleResize);

    // Call refresh initially to ensure animations are set up correctly
    setTimeout(() => {
      AOS.refresh();
    }, 1000); // Adjust the delay if necessary

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  ///////////////////////////////////////////////////
  let lastScroll = 0;
  useEffect(() => {
    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      
      scrollTimeout = setTimeout(() => {
        const sections = document.querySelectorAll('.scroll-section');
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        let isDownScroll = scrollY > lastScroll;

        // 60px 이하로 수행시 스크롤 이벤트 수행X (window.scrollTo 이벤트 실행시 scrollY와 lasScroll에서 5px 이내의 차이 존재)
        if(Math.abs(lastScroll-scrollY) < 60) {
          return;
        }

        // 다운스크롤
        if(isDownScroll) {
          sections.forEach((section, index) => {
            const sectionTop = section.offsetTop;
            if (scrollY + 100< sectionTop + windowHeight  && scrollY >= sectionTop) {
              const nextSection = sections[index + 1];
              if (nextSection) {
                // if(sections[index].offsetTop == scrollY || scrollY < 150) {
                  if(sections[index].offsetTop == scrollY) {
                  return;
                }
                lastScroll = nextSection.offsetTop;
                window.scrollTo({
                  top: nextSection.offsetTop,
                  behavior: 'smooth',
                  scrollBehavior: 'auto'
                });
              }
            }
          });
        }

      // 업스크롤
        else {
          for (let index = sections.length - 1; index >= 0; index--) {
            const section = sections[index];
            const sectionTop = section.offsetTop;
            if (scrollY + 100 < sectionTop + windowHeight && scrollY <= sectionTop) {
              const prevSection = sections[index - 1];
              if (prevSection) {
                // if (section.offsetTop === scrollY || scrollY < 150) {
                  if (section.offsetTop === scrollY) {
                  return;
                }
                lastScroll = prevSection.offsetTop;
                window.scrollTo({
                  top: prevSection.offsetTop,
                  behavior: 'smooth',
                  scrollBehavior: 'auto'
                });
              }
            }
          }
        }
      }, 1000);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [])

  
  function privateInfo(row) {
      setShowModal(true);
  }
  const closeModal = () => {
      setShowModal(false);
  }



  ///////////////////////////////////////////////////


    return (
<>
  <meta charSet="UTF-8" />
  <title>아워케어</title>
  <meta
    name="viewport"
    content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0"
  />
  <meta name="format-detection" content="telephone=no" />
  <link rel="stylesheet" type="text/css" href="/css/style_customer.css"/>
  <div id="wrapper">
    <header id="header" className="header-main">
      <div className="dp-flex header-inner">
        <h5 className="logo">
          <a href="">
            <img src="/images/ourcare_logo.png" style={{width:'125px'}}/>
          </a>
        </h5>
        <div>
          <a onClick={() => { navigate('/OURCARE') }} className="m-r-l">검진기관 둘러보기</a>
          {/* <a >무료상담</a> */}
        </div>
      </div> 
    </header>
    <PrivateInfoModal showModal={showModal} closeModal={closeModal} />
    <div id="container" className="container-main">
      <section className="main-second scroll-section">
        <div data-aos-duration={1000} data-aos="fade-up" data-aos-easing="ease" data-aos-delay={400}>
          <p className="main-txt fadeTop">
            대형 단체와 수 많은 보험설계사가 선택한
          </p>
          <div className="main-title-02">
            국내 최고의 <br />
            개인 종합검진 할인 웹!
          </div>
        </div>
        <div data-aos="zoom-in" data-aos-delay={800}>
          <div className="main-title-02-line fadeTop tr-d02">
            <img src="/images/main-logo.png" />
          </div>
        </div>
      </section>
      <section className="main-first scroll-section">
        <div data-aos="zoom-in">
          {/* <img src="/images/main-100.png" /> */}
        </div>
        <div data-aos-duration={1000} data-aos="fade-up" data-aos-easing="ease" data-aos-delay={400}>
          <div className="main-title-01">
            <b>건강 100세</b> 실현!
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration={1000} data-aos-delay={800}>
          <p className="main-txt">
            아워케어는 <br />
            고가의 검진비용으로 망설이던 <br />
            종합검진을 파격적으로 할인 제공함으로써 <br />
            국민 모두가 질병의 조기발견과 치료를 통해 <br />
            ‘건강 100세!’ 를 실현하는데 <br /> 최선을 다하고 있습니다.
          </p>
        </div>
      </section>
      <section className="main-third scroll-section">
                
                <div className="main-ic-wrap">
                    <div data-aos-duration="1000"  data-aos="zoom-in" data-aos-easing="ease"> 
                        <div className="ic-01">
                            <i className="icon-check m-r-l"></i> 개인종합검진 최고의 가성비!
                        </div>
                    </div>
                    <div data-aos-duration="1000"  data-aos="zoom-in" data-aos-easing="ease"data-aos-delay="800"> 
                        <div className="ic-02">
                            <i className="icon-check m-r-l"></i> 편리한 예약 시스템!
                        </div>
                    </div>
                    <div data-aos-duration="1000"  data-aos="zoom-in" data-aos-easing="ease"data-aos-delay="1600"> 
                        <div className="ic-03">
                            <i className="icon-check m-r-l"></i> 우수한 검진기관 전국 네트워크!
                        </div>
                    </div>
               
            </div>
        </section>
      {/* <section className="main-third">
        <div className="main-ic-wrap">
          <div data-aos-duration={1000} data-aos="fade-up" data-aos-easing="ease">
            <div className="ic-01">
              <img src="/images/main-ic-01.png" />
              <p>개인종합검진 최고의 가성비!</p>
            </div>
          </div>
          <div data-aos-duration={1000} vdata-aos="fade-up" data-aos-easing="ease" data-aos-delay={400}>
            <div className="ic-02">
              <img src="/images/main-ic-02.png" />
              <p>편리한 예약 시스템!</p>
            </div>
          </div>
          <div data-aos-duration={1000} data-aos="fade-up" data-aos-easing="ease" data-aos-delay={800}>
            <div className="ic-03">
              <img src="/images/main-ic-03.png" />
              <p>우수한 검진기관 전국 네트워크!</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="main-fourth">
        <h5>종합검진의 필요성</h5>
        <div className="space-l" />
        <p>
          국가 공단검진은 기본적은 신체계측, 간단한 혈액검사(간기능, 신장기능,
          빈혈 등)와 흉부촬영 검사, 그리고 연령에 따라 5대 암검진을 시행합니다.<br />
          <br />
          종합검진은 공단검진 항목을 포함하여 내시경검사, CT검사, 초음파검사,
          MRI검사,혈액종합검사 등 고가의 장비검사 및 다양한 검사항목으로 좀 더
          많은 질환을 발견하는데 꼭 필요한 검사입니다.<br />
          아워케어는 엄선된 검진기관에서 최고의 우대혜택을 드리는 여러분의 건강지킴이입니다.
        </p>
      </section>
      <section className="divide-bar" />
      <section className="main-fourth">
        <h5>
          <span className="ft-blue">아워케어</span> 종합검진 상품 안내
        </h5>
        <div className="space-l" />
        <ul className="main-ul">
          <li>
            <span className="bullet bullet-gray" />
            검진기관별로 검진항목에 약간의 차이가 있을 수 있습니다.
          </li>
          <li>
            <span className="bullet bullet-gray" />
            검진비용은 할인이 적용된 금액입니다.
          </li>
        </ul>
        <div className="space-l" />
        <div className="box-gray-common box-package ">
          <span className="ft-l">
            종합검진 <b>공통항목</b>
          </span>
          <div className="space-l" />
          <div className="main-common">
            <ul>
              <li>
                <span className="bullet bullet-gray" />
                상복부초음파 검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                위, 십이지장 검사(위내시경 또는 위장조영 촬영)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                기본진료
              </li>
              <li>
                <span className="bullet bullet-gray" />
                체격측정
              </li>
              <li>
                <span className="bullet bullet-gray" />
                청력검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                안과검사(시력, 안압, 안저)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                흉부X-선 촬영
              </li>
              <li>
                <span className="bullet bullet-gray" />
                심전도검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                유방X선촬영(女)
                {/* <span className="ft-red">(女)</span> */}
              </li>
              <li>
                <span className="bullet bullet-gray" />
                자궁암 세포진검사(女)
                {/* <span className="ft-red">(女)</span> */}
              </li>
              <li>
                <span className="bullet bullet-gray" />
                체성분 검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                간기능 검사(11종)
              </li>
            </ul>
            <ul>
              <li>
                <span className="bullet bullet-gray" />
                갑상선 및 부갑상선 기능검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                당뇨검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                암표지자 검사 (간암, 대장암, 위암, 췌장암, 소화기계암, 전립선암, 난소암)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                심장질환 검사(4종)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                신장검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                빈혈검사(3종)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                췌장검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                통풍검사
              </li>
              <li>
                <span className="bullet bullet-gray" />
                면역검사(5종)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                소변검사(10종)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                일반혈액검사(16종)
              </li>
              <li>
                <span className="bullet bullet-gray" />
                치과검사
              </li>
            </ul>
          </div>
        </div>
        <div className="space-l" />
        <div className="box-package">
          <span className="ft-l">
            종합검진 <b>유형별 상품안내</b>
          </span>
          <div className="space-m" />
          <span className="ft-s">
            [ 검진기관별 약간의 차이가 있을 수 있습니다. ]<br/>
            검진상품의 상세항목은 <b>"아워케어 바로가기"</b>에서 확인하실 수 있습니다.
          </span>
          <div className="space-l" />
          {/* 상품 나열 */}
          <div className="package-wrap">
            <div className="package package-blue">
              <div className="dp-flex">
                <div className="mr-auto title">
                  <h6>A형</h6>
                </div>
                <div className="ml-auto price2">
                  특별우대가 <span>27만원</span>
                </div>
              </div>
              <div className="infor">
                <ul>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      종합검진 공통항목
                      <div className='space-s'/>
                      + 특수초음파 검사
                      <span className="ft-gray">
                        (갑상선,전립선,자궁,경동맥 초음파)
                      </span>
                      , 동맥경화검사, 골밀도검사 중 <span className="ft-red">선택 1개</span> 항목
                    </span>
                  </li>
                  <li>
                    <span className="flex1" style={{margin:"0 0 0 12px"}}>
                      + 특수정밀검사
                      <span className="ft-gray">
                        (CT검사, 심장초음파, 유방초음파, 유전자검사 등)</span> 21개 중 <span className="ft-red"> 선택 1개 </span>항목
                    </span>
                  </li>
                </ul>
                <div className="space-m" />
                <div className="package-bdr package-bdr-blue">A형 선택기준</div>
                <div className="space-m" />
                <ol type={1} className="infor-blue ">
                  <li>기본적인 종합검진. 건강한 20~30대</li>
                  <li>
                    질병의 조기발견과 건강상태 측정을 위한 필수항목으로 구성
                  </li>
                </ol>
              </div>
            </div>
            <div className="package package-purple">
              <div className="dp-flex">
                <div className="mr-auto title">
                  <h6>B형</h6>
                </div>
                <div className="ml-auto price2">
                  특별우대가 <span>32만원</span>
                </div>
              </div>
              <div className="infor">
                <ul>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      종합검진 공통항목
                      <div className='space-s'/>
                      + 특수초음파 검사
                      <span className="ft-gray">
                        (갑상선,전립선,자궁,경동맥 초음파)
                      </span>
                      , 동맥경화검사, 골밀도검사 중<span className="ft-red"> 선택 3개</span> 항목
                    </span>
                  </li>
                  <li>
                    <span className="flex1" style={{margin:"0 0 0 12px"}}>
                      + 특수정밀검사
                      <span className="ft-gray">(CT검사, 심장초음파, 유방초음파, 유전자검사 등)</span> 21개 중 <span className="ft-red">선택 1개 </span>항목
                    </span>
                  </li>
                </ul>
                <div className="space-m" />
                <div className="package-bdr package-bdr-purple">
                  B형 선택기준
                </div>
                <div className="space-m" />
                <ol type={1} className="infor-purple ">
                  <li>가장 보편적인 종합검진</li>
                  <li>
                    가족력 등은 없으나 건강관리를 위해 지속적인 종합검진을 원하는 40~60대
                  </li>
                  <li>
                    최근 체중 변화나 체력 저하 등 건강상의 이상 징후가 있는 경우
                  </li>
                </ol>
              </div>
            </div>
            {packageGroup === 'A' ? <div className='space-m'></div> : <></>}
            <div className="package package-red">
              <div className="dp-flex">
                <div className="mr-auto title">
                  <h6>C형</h6>
                </div>
                <div className="ml-auto price2">
                  특별우대가 <span>37만원</span>
                </div>
              </div>
              <div className="infor">
                <ul>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      종합검진 공통항목
                      <div className='space-s'/>
                       + 특수초음파 검사
                      <span className="ft-gray">
                        (갑상선,전립선,자궁,경동맥 초음파)
                      </span>
                       , 동맥경화검사, 골밀도검사 중<span className="ft-red"> 선택 3개</span> 항목
                    </span>
                  </li>
                  <li>
                    <span className="flex1" style={{margin:"0 0 0 12px"}}>
                      + 특수정밀검사
                      <span className="ft-gray">(CT검사, 심장초음파, 유방초음파, 유전자검사 등)</span> 21개 중 <span className="ft-red">선택 2개 </span>항목
                    </span>
                  </li>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      암표지자 검사 췌장암, 유방암 추가
                    </span>
                  </li>
                </ul>
                <div className="space-m" />
                <div className="package-bdr package-bdr-red">C형 선택기준</div>
                <div className="space-m" />
                <ol type={1} className="infor-red ">
                  <li>40대 이상으로 좀 더 심층적인 건강검진이 필요한 경우</li>
                  <li>
                    고혈압, 당뇨, 비만, 이상지질혈증 등 성인병이 있는 경우
                  </li>
                  <li>최근 특정부위에 평소와 다른 징후가 있는 경우</li>
                </ol>
              </div>
            </div>
            {packageGroup === 'B' ? <div className='space-m'></div> : <></>}
            <div className="package package-yellow">
              <div className="dp-flex">
                <div className="mr-auto title">
                  <h6>D형</h6>
                </div>
                <div className="ml-auto price2">
                  특별우대가 <span>42만원</span>
                </div>
              </div>
              <div className="infor">
                <ul>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      종합검진 공통항목
                      <div className='space-s'/>
                       + 특수초음파 검사
                      <span className="ft-gray">
                        (갑상선,전립선,자궁,경동맥 초음파)
                      </span>
                      , 동맥경화검사, 골밀도검사 중 <span className="ft-red">선택 4개</span> 항목
                    </span>
                  </li>
                  <li>
                    <span className="flex1" style={{margin:"0 0 0 12px"}}>
                      + 특수정밀검사
                      <span className="ft-gray">(CT검사, 심장초음파, 유방초음파, 유전자검사 등) </span> 21개 중 <span className="ft-red">선택 3개 </span>항목
                    </span>
                  </li>
                </ul>
                <div className="space-m" />
                <div className="package-bdr package-bdr-yellow">
                  D형 선택기준
                </div>
                <div className="space-m" />
                <ol type={1} className="infor-yellow ">
                  <li>종합검진을 한번도 받지 않은 50~60대</li>
                  <li>
                    뇌혈관, 심장, 암 등 중증질환의 가족력이 있는 모든 연령대
                  </li>
                  <li>
                    50세 이상으로 최근에 두통, 변비, 설사, 흉통 등의 증상이 있는 경우
                  </li>
                </ol>
              </div>
            </div>
            {packageGroup === 'A' ? <div className='space-m'></div> : <></>}
            <div className="package package-orange">
              <div className="dp-flex">
                <div className="mr-auto title">
                  <h6>E형</h6>
                </div>
                <div className="ml-auto price2">
                  특별우대가 <span>72만원</span>
                </div>
              </div>
              <div className="infor">
                <ul>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      종합검진 공통항목
                      <div className='space-s'/>
                       + 특수초음파 검사
                      <span className="ft-gray">
                        (갑상선,전립선,자궁,경동맥 초음파)
                      </span>
                      , 동맥경화검사, 골밀도검사 중
                      <span className="ft-red"> 선택 5개</span> 항목
                    </span>
                  </li>
                  <li>
                    <span className="flex1" style={{margin:"0 0 0 12px"}}>
                      + 특수정밀검사
                      <span className="ft-gray">(CT검사, 심장초음파, 유방초음파, 유전자검사 등) </span>21개 중 <span className="ft-red">선택 4개 </span>항목
                    </span>
                  </li>
                  <li>
                    <span className="dot" />
                    <span className="flex1">
                      MRI검사 : 뇌, 경추, 요추 중 <span className="ft-red">선택 1개 </span>항목
                    </span>
                  </li>
                </ul>
                <div className="space-m" />
                <div className="package-bdr package-bdr-orange">
                  E형 선택기준
                </div>
                <div className="space-m" />
                <ol type={1} className="infor-orange ">
                  <li>경제적으로 여유가 있는 50~60대</li>
                  <li>
                    인체 모든 장기의 질환유무를 완벽하게 확인하고 싶은 경우
                  </li>
                  <li>
                    CT, MRI 등 고가 장비를 이용한 주요 암 및 중증질환 진단을 위한 매우 정밀한 종합검진
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/*// 상품 나열 */}
        </div>
      </section>
      <section className="main-fifth">
        <div className="box-gray-common">
          <span className="ft-l">
            <b>검진기관</b>
          </span>
          <div className="space-l" />
          <div className="company-wrap">
            <Slider {...settings}>
                {hospitals.map((hospital, index) => (
                <div key={index} className="company-lst">
                    <img src={hospital.imgSrc} alt={hospital.name} />
                    <div className="company-name">{hospital.name}</div>
                    <div className="ellipsis line-clamp-1 ft-gray ft-s" style={{paddingRight:'40px'}}>{hospital.address}</div>
                </div>
                ))}
            </Slider>


          </div>
        </div>
      </section>
      <section className="main-sixth">
        <div className="partner-wrap">
          <ul>
            <li>
              <img src="images/customer/KB손해보험.jpg" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/한화생명.png" style={{width:'180px'}}/>
            </li>
            {/* <li>
              <img src="images/customer/굿리치.jpg" style={{width:'180px'}}/>
            </li> */}
            <li>
              <img src="images/customer/라마다2.png" style={{width:'180px'}}/>
            </li>
            {customerGroup === 'A' ? <div className='space-m'></div> : <></>}
            <li>
              <img src="images/customer/프리드라이프.png" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/매니스로고.jpg" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/메트라이프.png" style={{width:'180px'}}/>
            </li>
          </ul>
          
          <ul>
            <li>
              <img src="images/customer/비큐러스.png" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/샘플도이치.png" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/스포짐.jpg" style={{width:'180px'}}/>
            </li>
            {customerGroup === 'A' ? <div className='space-m'></div> : <></>}
            <li>
              <img src="images/customer/애터미.jpg" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/엠금융서비스.png" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/인카금융.jpg" style={{width:'180px'}}/>
            </li>
          </ul>

          
          <ul>
            <li>
              <img src="images/customer/지에이코리아.jpg" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/충남대학교.png" style={{width:'180px'}}/>
            </li>
            <li>
              <img src="images/customer/knk배경제거.png" style={{width:'180px'}}/>
            </li>
            {customerGroup === 'A' ? <div className='space-m'></div> : <></>}

          </ul>

        </div>
      </section>
      <section className="main-footer">
        <a>
          <b><span onClick={privateInfo}>개인정보취급방침</span></b>
        </a>
        <div className="space-l" />
        <ul>
          <li>
            <span className="ft-gray">대표</span>정재금
          </li>
          <li>
            <span className="ft-gray">사업자등록번호</span>467-81-02952
          </li>
          <li>
            <span className="ft-gray">대표전화</span> 1551-0787
          </li>
          <li>
            <span className="ft-gray">주소</span> 서울특별시 마포구 와우산로
            176, 2층 2513호 (동교동) [04057]
          </li>
        </ul>
        <div className="space-l" />
        <div className="ft-gray">
          (주)아워케어 ⓒ 아워케어, All rights reserved.
        </div>
      </section>
      <a a onClick={() => { navigate('/OURCARE') }}  className="direct">
                아워케어 바로가기 <i className="icon-arrow-right-line-bold"></i>
            </a>
    </div>
  </div>
  {/* <div id="modal-counsel" className="modal" style={{ display: "none" }}>
    <div className="modal-flex">
      <div className="modal-flex-inner">
        <div className="modal-header">
          <h6>무료상담하기</h6>
        </div>
        <div className="modal-content"> */}
          {/*설명 : 모달의 너비와 높이는 modal-size 태그에서 조절합니다.*/}
          {/* <div
            className="modal-size optional-modal-padding"
            style={{ width: 450 }}
          >
            <div className="form-wp">
              <div className="input-wp">
                <select className="w-100">
                  <option value="">문의유형을 선택해 주세요</option>
                  <option value="">게시판 상담</option>
                </select>
              </div>
              <div className="input-wp">
                <input
                  className="w-100"
                  type="password"
                  placeholder="이름을 입력해 주세요."
                />
              </div>
              <div className="input-wp">
                <div className="dp-flex">
                  <input
                    className="flexx1 m-r-s"
                    type="password"
                    placeholder="이메일아이디"
                  />
                  <select className="flex1">
                    <option value="">직접입력</option>
                    <option value="">네이버</option>
                  </select>
                </div>
                <span className="align-right ft-gray ft-xs">
                  문의에 대한 답변을 받을 주소를 정확하게 기입해주세요
                </span>
              </div>
              <div className="input-wp">
                <div className="dp-flex">
                  <div className="flex1  m-r-s locknum">155432</div>
                  <input className="flex1" type="" placeholder="숫자입력" />
                </div>
                <span className="align-right ft-gray ft-xs">
                  스팸방지문자입니다. 왼쪽에 보이는 숫자를 입력해주세요.
                </span>
              </div>
              <div className="input-wp">
                <input
                  className="w-100"
                  type=""
                  placeholder="상담제목을 입력해 주세요."
                />
              </div>
              <div className="input-wp">
                <textarea
                  className="w-100"
                  type=""
                  style={{ width: "100%", height: 80 }}
                  placeholder="상담내용을 입력해 주세요."
                  defaultValue={""}
                />
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" defaultValue="" /> 개인정보 수집/이용
                  동의 <span className="ft-red">[필수]</span>
                </label>
              </div>
              <div className="space-s" />
              <div className="box-gray ft-xs">
                1.수집항목 : 이름, 이메일, IP, 질문내용
                <br />
                2.수집, 이용 목적 : 아워케어 상담을 위한 정보 제공
                <br />
                3.보유, 이용기간 및 파기 :
                <span className="ft-red">
                  문의 등록 1개월 후 지체 없이 파기
                </span>
              </div>
              <div className="space-s" />
              <div className="dp-flex">
                <button
                  className="btn page-btn flex1 m-r-s"
                  onclick="document.getElementById('modal-counsel').style.display='none'"
                >
                  취소
                </button>
                <button
                  className="btn page-btn flex2 btn-primary"
                  onclick="document.getElementById('modal-counsel').style.display='none'"
                >
                  문의하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>*/}
</>

        )
}

export default HC_6000; 
