import Swal from 'sweetalert2';
import moment from 'moment';


const dayNames = ['일', '월', '화', '수', '목', '금', '토'];

export function formatNumber(num) {
    return String(num).padStart(2, '0');
};

export function dateDisplay(date) {
    if (date === null || date === '' || date == undefined) {
        return date;
    }
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export function amountDisplay(amonut) {
    if (amonut === null) {
        return amonut;
    }

    return amonut.toLocaleString();
}

export function numberChecker(value) {
    if (value === '') {
        return true;
    }
    const regex = /^\d*$/;
    if (regex.test(value)) {
        if (parseInt(value, 10) > 0) {
            return true;
        } else {
            Swal.fire({
                title: '오류',
                text: '0보다 작을 수는 없습니다.',
                icon: 'warning',
                confirmButtonText: '확인',
            }).then(res => {
                return false;
            }
            );
        }
    }
    Swal.fire({
        title: '오류',
        text: '숫자만 입력해 주세요.',
        icon: 'warning',
        confirmButtonText: '확인',
    }).then(res => {
        return false;
    }
    );
}

export function phoneChecker(data) {
    const regex = /^[0-9]{0,13}$/;
    if (regex.test(data)) {
        return true;
    }
    Swal.fire({
        title: '오류',
        text: '유효한 전화번호를 입력해 주세요.',
        icon: 'warning',
        confirmButtonText: '확인',
    }).then(res => {
        return false;
    }
    );
}

export function phoneDisplay(data) {
    if (data.length === 10) {
        return data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    else if (data.length === 11) {
        return data.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
        return data;
    }
}

export function convertBase64ToURL(base64String) {
    const regex = /^data:image\/(.+);base64,(.*)$/;
    const matches = base64String.match(regex);
    if (!matches) {
        throw new Error('Invalid base64 string');
    }
    const format = matches[1];
    const data = matches[2];
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: `image/${format}` });
    const url = URL.createObjectURL(blob);
    return url;
}

export function emailValidation(value) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
    return regExp.test(value);
}

export function getDayOfWeek(day) {
    if (day !== '') {
        const dateObject = moment(day, 'YYYYMMDD').toDate(); // "yyyymmdd" 형태의 문자열을 Date 객체로 변환
        const dayOfWeek = dateObject.getDay(); // 요일을 숫자로 가져옴 (0부터 6: 일요일부터 토요일)
        return dayNames[dayOfWeek];
    } else {
        return '';
    }
}

export function phoneValidation(value) {
    // 대한민국 휴대전화 형식 정규식
    const regExp = /^01[016789]-?[0-9]{3,4}-?[0-9]{4}$/;
    return regExp.test(value);
}

export function passwordConfirmValidation(password, passwordConfirm) {
    return password === passwordConfirm;
}

export function birthValidation(bYear, bMonth, bDay) {
    const year = parseInt(bYear, 10);
    const month = parseInt(bMonth, 10);
    const day = parseInt(bDay, 10);
    var res = true;

    // 변환된 값이 NaN인지 확인
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
        res = false;
    }

    // 년도 범위 검사 (예: 1900년 ~ 현재년도)
    const currentYear = new Date().getFullYear();
    if (year < 1900 || year > currentYear) {
        res = false;
    }

    // 월 범위 검사
    if (month < 1 || month > 12) {
        res = false;
    }

    // 윤년 판별
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

    // 일 범위 검사
    if (day < 1 || day > 31) {
        res = false;
    }

    if (month === 2) {
        if (isLeapYear && day > 29) {
            res = false;
        } else if (!isLeapYear && day > 28) {
            res = false;
        }
    }

    if ([4, 6, 9, 11].includes(month) && day > 30) {
        res = false;
    }

    return res;
}


export function passwordValid(password) {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
    if (!passwordRegex.test(password)) {
        return false;
    }
    return true
}

