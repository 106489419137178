/**
 * 
 * HC-6008
 *
 *
 */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userId, customerCode, salesId } from '../data/Atom';
import Swal from 'sweetalert2';
import api from '../api/api'
import { func } from 'prop-types';

function HC_6008() {
  const naviagte = useNavigate();
  const userIdValue = useRecoilValue(userId);
  const salesIdValue = useRecoilValue(salesId);
  const customerCodeValue = useRecoilValue(customerCode);
  const [mainAddress, setMainAddress] = useState([]);
  const [subAddress, setSubAddress] = useState([]);
  const [subActiveAddress, setSubActiveAddress] = useState([]);
  const [hospitalName, setHospitalName] = useState('');
  const [mainSel, setMainSel] = useState('');
  const [mainSelName, setMainSelName] = useState('');
  const [subSel, setSubSel] = useState('');
  const [params, setParams] = useState({
    size: 100,
    page: 1
  })

  const [loginYn,setLoginYn] = useState();

  const [selRow, setSelRow] = useState({});
  const [hospitalDataValue, setHospitalDataValue] = useState({
    "totalPages": 1,
    "totalElements": 0,
    "currentPage": 1,
    "currentElements": 0,
    "pageSize": 10,
    "data": []
  })


  function getMainAddress() {
    api.get('/guest/code/div/CD000022/code').then(
      response => {
        setMainAddress(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  function getSubAddress() {
    api.get('/guest/code/div/CD000023/code').then(
      response => {
        setSubAddress(response.data);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }

  function getData() {
    const url = '/guest/hospital'
    api.get(url, {
      params: params
    }).then(
      res => {
        setHospitalDataValue(res.data);
      }
    ).catch(
      err => {
        console.log("ERROR : " + JSON.stringify(err))
      }
    )
  }

  function search() {
    const updatedParams = {
      ...params,
      address: subSel !== '' ? subSel : mainSelName !== '' ? mainSelName : undefined, // 필드 삭제
      search : hospitalName
    };
    selected({});
    setParams(updatedParams);
  }

  function selected(row) {
    setSelRow(row);
  }


  function nextPage(row) {
    // if (selRow.code === null || selRow.code === undefined) {
    //   Swal.fire({
    //     title: '알림',
    //     text: '검진기관이 선택되지 않았습니다.',
    //     icon: 'info',
    //     confirmButtonText: '확인',
    //   })
    // } else
    //  {
      const data = {
        hospitalCode: row.code,
        hospitalName: row.name
      }
      

      if (userIdValue === '') {
        naviagte('/HC_6009_PREVIEW', { state: data })
      } else {
        naviagte('/HC_6009', { state: data });
      }
    // }
  }

  function previewPage(row) {
    const data = {
        hospitalCode: row.code,
        hospitalName: row.name
    }
    naviagte('/HC_6009_PREVIEW', { state: data })
}

  useEffect(() => {
    if (userIdValue === '') {
      setLoginYn(false)
    } else {
      setLoginYn(true)
    }
  },[])

  useEffect(() => {
    getMainAddress()
  }, [])

  useEffect(() => {
    getSubAddress()
  }, [])

  useEffect(() => {
    getData()
  }, [params])


  const hospHandle = (e) => {
    setHospitalName(e.target.value);
  }



  const mainSelHandle = (e) => {
    if (e.target.value !== '') {
      const foundElement = mainAddress.find(item => item.code === e.target.value);
      setMainSel(e.target.value);
      if (foundElement !== undefined && foundElement !== null) {
        setMainSelName(foundElement.name);
      }

      if (Array.isArray(subAddress)) {
        const filteredList = subAddress.filter((item) => item.code.startsWith(e.target.value));
        setSubActiveAddress(filteredList);
        setSubSel('');
        // 필터링된 결과를 사용하는 코드 작성
      }
    } else {
      setMainSel('');
      setMainSelName('');
      setSubActiveAddress([]);
      setSubSel('');
    }
  }

  const subSelHandle = (e) => {
    setSubSel(e.target.value);
  }

  return (
    <React.Fragment>
      <div class="pg-title">
        <span>검진기관검색</span>
      </div>
      <section>
        <div class="form-wp">
          <div class="input-wp">
            <p>검색할 주소를 선택하세요.</p>
            <div class="dp-flex">
              <select class="flex1 m-r-s" value={mainSel} onChange={(e) => { mainSelHandle(e) }}>
                <option value="">광역시/도</option>
                {mainAddress.map((row) => (
                  <option value={row.code}>{row.name}</option>
                ))}
              </select>
              <select class="flex1" value={subSel} onChange={(e) => { subSelHandle(e) }}>
                <option value="">시/군/구</option>
                {subActiveAddress.map((row) => (
                  <option value={row.name}>{row.name}</option>
                ))}
              </select>
            </div>
            <br/>
            <div class="input-wp">
            <input class="w-100" type="text" placeholder="검진기관명을 입력하세요." onChange={(e) => hospHandle(e)}/>
            </div>

            <div class="space-s"></div>
            <button class="btn-formbt-mobile w-100" onClick={() => search()}>검색</button>
          </div>
          <div class="input-wp">
            <p>검진기관을 선택해 주세요.</p>
            <ul class="list-group">
              {hospitalDataValue.data.map((row) => (
                <li onClick={() => nextPage(row)} style={row.code === selRow.code ? { backgroundColor: '#0160DA', color: 'white' } : null} >
                  <div class="dp-flex">
                                        <div class="mr-auto">
                                            <b>{row.name}</b>
                                            {row.fastYn === 'Y' ? <b className="ft-red"> (빠른예약)</b> : ""}
                                        </div>
                                        {/* <div class="ml-auto">
                                            <a onClick={() => { previewPage(row) }}><i className="icon-arrow-next"></i></a>
                                        </div> */}
                                    </div>
                  {/* <b>{row.name}</b> */}
                
                <p class="ft-gray ft-s">{row.address + ' ' + row.detailAddress}</p></li>
              ))}
            </ul>
          </div>
          <div>
          <p class="ft-black ft-s"> * 병원입점문의 : ifh012@hanmail.net</p>
          </div>
        </div>
      </section>
      <section>
      </section>
      <section>
      <div class="dp-flex">
            {/* <button class="btn page-btn flex1 m-r-s" onClick={() => naviagte(userIdValue === '' ? -1 : "/HC_6006")}>취소</button> */}
            <button class="btn page-btn flex1 m-r-s" onClick={() => {
            if(userIdValue === '') {
              if(salesIdValue === '') {
                naviagte('/'+customerCodeValue)
              } else {
                naviagte('/'+customerCodeValue + '/' + salesIdValue)
              }
              
            } else {
              naviagte("/HC_6006")
            }
           }}>취소</button>
          <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>
            {userIdValue === '' ?<p>둘러보기</p>:<p>예약하기</p>}</button>
        </div>
      </section>
          {/* 오른쪽 사이드 메뉴 */}
          <div id="float-sidenav" class="sidebanner">
                <a>
                    <div class="link-icon" onClick={() => nextPage()}><i class="icon-approval"></i></div>
                    {userIdValue === ''? <p>둘러보기</p> : <p>예약하기</p>}
                </a>
                <a>
                    <div class="link-icon" onClick={() => {
                    if(userIdValue === '') {
                      if(salesIdValue === '') {
                        naviagte('/'+customerCodeValue)
                      } else {
                        naviagte('/'+customerCodeValue + '/' + salesIdValue)
                      }
                      
                    } else {
                      naviagte("/HC_5006")
                    }
                  }}>
                    <i class="icon-refresh"></i></div>
                    <p>취소하기</p>
                </a>
             </div>
             {/* 오른쪽 사이드 메뉴 */}
    </React.Fragment>
  )
}

export default HC_6008; 
