/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react'
import Header from './Header';
import { useRecoilState } from 'recoil';
import { Outlet, useNavigate, Link, useLocation } from "react-router-dom";

import { userName, userId, userRole, customerCode, customerName, salesId, isLogout } from '../../data/Atom';
import Swal from 'sweetalert2';
import api from '../../api/api'
import TokenCheckAndRenewal from './TokenChecker';
import TimeExpired from './TimeExpired';
import PrivateInfoModal from './privateInfo/PrivateInfoModal_240229'
function WebLayout() {
    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName)
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode)
    const [salesIdValue, setSalesId] = useRecoilState(salesId)
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout)
    
    const [showModal, setShowModal] = useState(false);
    const naviagte = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[window.location.pathname]) 


    const logout = (e) => {
        const url = '/token/logout/LOGOUT';
        // const body = {
            // logoutType : 'LOGOUT',
        // }
        api.post(url).then(
            response => {
                Swal.fire({
                    title: '알림',
                    text: '로그아웃 되었습니다.',
                    icon: 'info',
                    confirmButtonText: '확인',
                }).then(
                    res => {
                        setUserName('')
                        setUserId('')
                        setUserRole('')
                        setIsLogout('')
                        //setCustomerCode('')
                        // setCustomerName('')
                        localStorage.removeItem('access');
                        // localStorage.removeItem('refresh');
                    }
                )
        
                if(salesIdValue) {
                    naviagte('/'+customerCodeValue + '/'+salesIdValue)
                }
                else {
                    naviagte('/'+customerCodeValue)
                }
                // naviagte(-1);
                //naviagte('/'+customerCodeValue)

            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
                if(salesIdValue) {
                    naviagte('/'+customerCodeValue + '/'+salesIdValue)
                }
                else {
                    naviagte('/'+customerCodeValue)
                }
                
            }
        )
    }
    

    function privateInfo(row) {
        setShowModal(true);
    }
    const closeModal = () => {
        setShowModal(false);
    }

    
    return (
        <React.Fragment>
            <TokenCheckAndRenewal />
            {/* <TimeExpired /> */}
            <Header />
            <PrivateInfoModal showModal={showModal} closeModal={closeModal} />
            <div id="wrapper">
                <header id="header">
                    <div className="dp-flex header-inner">
                        {/* <h5 class="logo"><Link to={userIdValue === '' ? '/'+customerCodeValue : '/HC_6006'}>{customerNameValue}</Link></h5> */}
                        <h5 className="logo">
                        {(() => {
                            if (userIdValue === '') {
                                if(salesIdValue === '') {
                                    return <Link to={`/${customerCodeValue}`}>{customerNameValue}</Link>;
                                } else {
                                    return <Link to={`/${customerCodeValue}/${salesIdValue}`}>{customerNameValue}</Link>;
                                }
                            } else {
                            return <Link to="/HC_6006">{customerNameValue}</Link>;
                            }
                        })()}
                        </h5>
                        {/* <div>
                            <Link to="/HC_6005" className="m-r-l" >로그인</Link> <Link to="/HC_6002">회원가입</Link>
                        </div> */}
                        {userIdValue === '' ? (
                            <div>
                                <Link to="/HC_6005" className="m-r-l">로그인</Link> <Link to="/HC_6001_certi">회원가입</Link>
                            </div>
                        ) : (
                            <div className="login-info">
                                <ul>
                                    <li className="login-user">
                                        <a ><b>{userNameValue}({userIdValue})</b></a>
                                        <div className="user-info-lyr">
                                            <ul>
                                                {/* <li key="a"><a onClick={() => naviagte('HC_6006')}>마이페이지</a></li> */}
                                                <li key="b"><a onClick={() => naviagte('HC_6022')}>검진예약현황</a></li>
                                                <li key="c"><a onClick={() => naviagte('HC_6007')}>비밀번호변경</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><a onClick={(e) => logout(e)}><i className="icon-logout"></i></a></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </header>
                <div id="container">
                    <Outlet />
                </div>
            </div>
            <div id="footer">
                    <div class="footer-inner"><span class="footer-span"><b>(주)아워케어 (업무대행)</b> <br />  대표 : 정재금  | 사업자등록번호 : 467-81-02952 | 1551-0787<br />
                        [04057] 서울특별시 마포구 와우산로 176, 2층2513호(동교동) </span><span onClick={privateInfo}>개인정보처리방침</span></div>
            </div>
        </React.Fragment>
    );
}

export default WebLayout;