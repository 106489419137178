/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * 검진선택항목목록조회 
 * HC-6010
 *
 *
 */
import React, { useEffect, useState, useRef } from 'react'
import { Navigate,useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import HC_6013 from './HC_6013';
import { useRecoilState } from 'recoil';
import { customerCode, isLogout} from '../data/Atom';

function HC_6011() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기
    const [displayData, setDisplayData] = useState({});
    const [selectValue, setSelectValue] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode)
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout)
    const nextSectionRef= useRef(null)
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);


    function detailData(row) {
        const url = '/item/' + data.hospitalCode + '/' + data.packageCode + '/' + row.packageDivCode + '/' + row.itemCode;
        api.get(url).then(
            response => {
                var modalData = response.data;
                modalData.packageName = displayData.name;
                //response.data.packageName = displayData.name;
                setModalData(modalData);
                setShowModal(true);
                //alert(JSON.stringify(response.data))
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getData() {
        //TODO 
        if (data !== null && data !== undefined) {
            api.get('/cu/selectItem/' + data.hospitalCode + '/' + data.packageCode).then(
                response => {
                    const initSelect = response.data.selectItemList.map((item) => {
                        return {
                            selectGroupCode: item.selectGroupCode,
                            selectCount: item.selectCount,
                            selected: item.itemRes.map((_data) => {
                                return false;
                            })
                        }
                    })
                    setSelectValue(initSelect);
                    setDisplayData(response.data);
                }
            ).catch(
                error => {
                    console.log("ERROR : " + JSON.stringify(error))
                }
            )
        } else {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        }
    }

    function nextPage() {
        if (validation()) {
            const selectItem = displayData.selectItemList.map((item) => {
                const selectGroupCode = item.selectGroupCode;
                const selectGroup = selectValue.find((group) => group.selectGroupCode === selectGroupCode);
                if (selectGroup !== null && selectGroup !== undefined) {
                    const selectedItems = [];
                    item.itemRes.forEach((_item, i) => {
                        selectedItems.push({
                            selectGroupCodeName: item.selectGroupCodeName,
                            itemCode: item.itemRes[i].itemCode,
                            name: item.itemRes[i].name,
                            packageDivCode: item.itemRes[i].packageDivCode,
                            select: selectGroup.selected[i] ? 'Y' : 'N'
                        });
                    });
                    return selectedItems;
                }
                return [];
            }).flat();


            let flag = true;
            let CTcnt = 0;

            if(data.hospitalCode == 'HC000013' || data.hospitalCode == 'HC000014' )  {
                selectItem.map((item) => { 
                    if(item.name.endsWith('CT') && item.select == 'Y') {
                        CTcnt++;
                    }
                })}
                if(CTcnt> 2) {
                    Swal.fire({
                        title: '알림',
                        text: 'CT는 최대 2개까지 선택 가능합니다.',
                        icon: 'warning',
                        confirmButtonText: '확인',
                    }).then((response) => {
                        return;
                    });
                    flag = false
            }
            data.CTcnt = CTcnt;
            
            if (flag) {
                let showColonoscopyWarning = false;
        
                selectItem.map((item) => {
                    if (item.name.includes('대장') && item.name.includes('내시경') && item.select == 'Y') {
                        showColonoscopyWarning = true;
                    }
                });
        
                if (showColonoscopyWarning) {
                    Swal.fire({
                        title: '알림',
                        html: '* 대장내시경은 만 70세 미만인 경우 검진 가능합니다.<br><span style="color:red">*용종제거 시 별도 비용 발생</span>',
                        icon: 'info',
                        confirmButtonText: '확인',
                    }).then((response) => {
                        if (response.isConfirmed) {
                            data.selectItem = selectItem;
                            naviagte('/HC_6012', { state: data });
                        }
                    });
                } else {
                    // No colonoscopy warning, navigate directly
                    data.selectItem = selectItem;
                    naviagte('/HC_6012', { state: data });
                }
            }
        
            return;
        } else {
            Swal.fire({
                title: '알림',
                text: '항목 선택이 완료되지 않았습니다.',
                icon: 'info',
                confirmButtonText: '확인',
            }).then((response) => {
                return;
            });
        }
    }

    function prePage() {
        naviagte('/HC_6010', { state: data })
    }

    function validation() {
        const res = selectValue.filter((item) => {
            return item.selectCount !== checkCurrentCount(item.selectGroupCode);
        }).length;
        return res === 0;
    }

    function setSelectHandle(selectGroupCode,index, i) {
        setSelectValue((prevSelectValue) => {
            const updatedSelectValue = prevSelectValue.map((item) => {
                if (item.selectGroupCode === selectGroupCode) {
                    if (!item.selected[i]) {
                        const maxCount = item.selectCount;
                        const currentCount = checkCurrentCount(selectGroupCode);
                        if (currentCount < maxCount) {
                            item.selected[i] = true;
                            if(currentCount == maxCount-1) {
                                  // 다음 섹션으로 스크롤
                                  if (currentCount === maxCount - 1) {
                                    // Calculate the position to scroll to
                                    var screenHeight;
                                    if(displayData.selectItemList.length > currentSectionIndex) {
                                      screenHeight  = scrollHeight + nextSectionRef.current.clientHeight;
                                      window.scrollTo({
                                        top: screenHeight,
                                        behavior: 'smooth',
                                    });
                                    setScrollHeight(scrollHeight + nextSectionRef.current.clientHeight)
                                    setCurrentSectionIndex(index + 1);
                                    }
                                    if(displayData.selectItemList.length > currentSectionIndex) {
                                        setCurrentSectionIndex(index + 1);
                                    }
                                }
                            }
                        } else {
                            Swal.fire({
                                title: '알림',
                                text: '선택할 수 있는 최대 개수를 초과하였습니다.',
                                icon: 'info',
                                confirmButtonText: '확인',
                            }).then((response) => {
                                return;
                            });
                        }
                    } else {
                        item.selected[i] = false;
                    }
                }
                return item;
            });
            return [...updatedSelectValue];
        });
    }

    function checkCurrentCount(selectGroupCode) {
        const obj = selectValue.find(item => item.selectGroupCode === selectGroupCode);
        if (obj !== null && obj !== undefined) {
            return obj.selected.filter(item => item === true).length;
        }
        return 0;
    }

    function checked(selectGroupCode, i) {
        const obj = selectValue.find(item => item.selectGroupCode === selectGroupCode);
        if (obj !== null && obj !== undefined) {
            return obj.selected[i];
        }
        return false;
    }

    const closeModal = () => {
        setShowModal(false);
        setModalData({});
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <React.Fragment>
            <HC_6013 showModal={showModal} closeModal={closeModal} data={modalData} />
            <div class="box-green align-center">
            <h5 class="ellipsis">{displayData.hospitalName}</h5>
            </div>
            <div class="space-l"></div>
            <div class="checkup-subject">
                <b class="ft-blue ft-l">{displayData.name}</b><br></br>
                <b class="ft-blue">각 항목을 탭하시면 상세내용을 확인하실 수 있습니다.</b>
            </div>
            <div class="space-l"></div>
            
            {
                displayData && displayData.selectItemList &&
                displayData.selectItemList.map((row, index) => (
                    <section key={index} ref={index === currentSectionIndex ? nextSectionRef : null}>
                        <div class="list-title">
                            <b class="ft-l">{row.selectGroupCodeName} <span class="ft-orange">({checkCurrentCount(row.selectGroupCode)}/{row.selectCount})</span></b>
                        </div>
                        <ul class="list-wrap">
                            {row.itemRes.map((item, i) => (
                                <li>
                                    <div class="ellipsis">
                                        <input type="checkbox" checked={checked(row.selectGroupCode, i)} onChange={() => setSelectHandle(row.selectGroupCode, index, i)} />
                                        &nbsp;&nbsp;<a class="ellipsis" onClick={() => detailData(item)}>{item.name}</a>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>
                ))
            }
            <section>
                <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
                </div>
            </section>
            {/* 오른쪽 사이드 메뉴 */}
            <div id="float-sidenav" class="sidebanner">
                <a>
                    <div class="link-icon" onClick={() => prePage()}><i class="icon-arrow-left-line-bold"></i></div>
                    <p>이전</p>
                </a>
                <a>
                    <div class="link-icon" onClick={() => nextPage()}><i class="icon-arrow-right-line-bold"></i></div>
                    <p>다음</p>
                </a>
            </div>
            {/* 오른쪽 사이드 메뉴  */}
        </React.Fragment>
    )
 
}

export default HC_6011; 
