import React, { createContext, useState, useContext } from 'react';

// Context 생성
const CacheContext = createContext();


// Context의 Provider 컴포넌트 설정
const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  // 값을 캐시하는 함수
  const cacheValue = (key, value) => {
    setCache((prevCache) => ({ ...prevCache, [key]: value }));
  };

  // 캐시된 값을 삭제하는 함수
  const deleteCachedValue = (key) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[key];
      return newCache;
    });
  };

  return (
    <CacheContext.Provider value={{ cache, cacheValue, deleteCachedValue }}>
      {children}
    </CacheContext.Provider>
  );
};
const useCache = () => {
    return useContext(CacheContext);
  };
export {CacheProvider, useCache};