/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6013
 *
 *
 */
import React, { } from 'react'

function HC_6013({ showModal, closeModal, data }) {
  const closeHandle = () => {
    closeModal();
  }
  return (
    <React.Fragment>
      {
        showModal && (
          <div id="HC-6013" class="modal">
            <div class="modal-flex">
              <div class="modal-flex-content" style={{ width: '600px' }}>
                <div class="dp-flex">
                  <div class="mr-auto"><div class="ft-l">검진항목 상세조회</div></div>
                  <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
                </div>
                <div class="space-l"></div>
                <div class="box-blue align-center">
                  <div class="ellipsis"><b>{data.packageName}</b></div>
                </div>
                <div class="space-l"></div>
                <div class="form-wp">
                  <div class="input-wp">
                    <p>검진항목명</p>
                    <div class="dpview">{data.name}  </div>
                  </div>
                  <div class="input-wp">
                    <p>검진구분명</p>
                    <div class="dpview">{data.divName}  </div>
                  </div>
                  <div class="input-wp">
                    <p>관련질환</p>
                    <textarea readOnly class="fm-def" style={{ width: '100%', height: '100px' }} value={data.dssDesc === null || data.dssDesc === undefined ? data.diseaseDesc : data.dssDesc}></textarea>
                  </div>
                  <div class="input-wp">
                    <p>성별</p>
                    <div class="radio" readOnly>
                      <label>
                        <input type="radio" name="option" id="" value="" checked={data.sex === 'MF'} /> 공통
                      </label>
                      <label>
                        <input type="radio" name="option" id="" value="" checked={data.sex === 'M'} /> 남자
                      </label>
                      <label>
                        <input type="radio" name="option" id="" value="" checked={data.sex === 'F'} /> 여자
                      </label>
                    </div>
                  </div>
                  <div class="input-wp">
                    <p>비고</p>
                    <textarea readOnly class="fm-def" style={{ width: '100%', height: '100px' }} value={data.desc}></textarea>
                  </div>
                </div>


                <div class="dp-flex">
                  <button class="flex1 btn page-btn btn-primary" onClick={() => closeHandle()}>확인</button>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </React.Fragment>
  )
}

export default HC_6013; 
