/* eslint-disable array-callback-return */
/**
 * 
 * HC-6017
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../api/api'
import HC_6018 from './HC_6018';
import { bool } from 'prop-types';

function HC_6017() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기
    data.type = 'NOMAL';
    const [displayData, setDisplayData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [allCheckd, setAllChecked] = useState(false);


    function getData() {
        if (data !== null && data !== undefined) {
            api.get('/cu/hcAgr/' + data.hospitalCode).then(
                response => {
                    response.data.map((data) => {
                        data.check = 'N';
                    })
                    setDisplayData(response.data);
                }
            ).catch(
                error => {
                    console.log("ERROR : " + JSON.stringify(error))
                    Swal.fire({
                        title: '에러',
                        text: '서버 에러가 발생했습니다. 다시 시도해 주세요',
                        icon: 'error',
                        confirmButtonText: '확인',
                    })
                }
            )
        } else {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        }
    }

    function getModalData(row) {
        api.get('/cu/hcAgr/detail/' + data.hospitalCode + '/' + row.divCode).then(
            response => {
                setModalData(response.data);
                setShowModal(true);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
                Swal.fire({
                    title: '에러',
                    text: '서버 에러가 발생했습니다. 다시 시도해 주세요.',
                    icon: 'error',
                    confirmButtonText: '확인',
                })
            }
        )
    }

    const showModalHandle = (row, e) => {
        e.preventDefault();
        getModalData(row);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const allCheckedHandle = () => {
        setDisplayData((prev) => {
            const updateValue = prev.map(
                (item) => {
                    item.check = allCheckd ? 'N' : 'Y'
                    return item;
                }
            );
            return [...updateValue];
        });
        setAllChecked(!allCheckd);
    }
    const checkedHandle = (target) => {
        setDisplayData((prev) => {
            let falseStatus = false;
            const updateValue = prev.map(
                (item) => {
                    if (item.divCode === target.divCode) {
                        if (item.check === 'N') {
                            //showModalHandle(target);
                            item.check = 'Y';
                        } else {
                            item.check = 'N';
                        }
                        //item.check = item.check === 'Y' ? 'N' : 'Y'
                    }

                    if (!falseStatus && item.check === 'N') {
                        falseStatus = true;
                    }
                    return item;
                }
            );
            setAllChecked(!falseStatus);
            return [...updateValue];
        });
        
        
    }

    function validation() {
        const hasMatchingData = displayData.some(item => {
            return item.mandatory === 'M' && item.check === 'N';
        });
        if (hasMatchingData) {
            Swal.fire({
                title: '알림',
                text: '필수 항목들은 모두 동의가 필요합니다.',
                icon: 'info',
                confirmButtonText: '확인',
            }).then((res) => {
                return false;
            })
        } else {
            return true;
        } 
    }

    function prePage() {
        naviagte('/HC_6016', { state: data })
    }


    function nextPage() {
        if (validation()) {
            data.hcAgr = displayData;
            naviagte('/HC_6019', { state: data })
        }
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <React.Fragment>
            <HC_6018 showModal={showModal} closeModal={closeModal} data={modalData} />
            <div class="pg-title">
                <span>검진예약약관동의</span>
            </div>
            <div class="space-m"></div>
            <span>검진 예약 서비스 이용을 위해 필수 약관에 동의해 주세요.</span>
            <div class="space-l"></div>
            <section>
                <span class="input-chk">
                    <input type="checkbox" id='allCheck' checked={allCheckd} name="" onClick={() => allCheckedHandle()} />
                    <label for="chk" htmlFor='allCheck'>전체동의 </label>
                </span>
                <div class="space-l"></div>
                <ul class="list-group">
                    {displayData && displayData.map((row) => (
                        <li>
                            <span className="input-chk">
                                <input
                                    type="checkbox"
                                    id={row.divCode}
                                    name=""
                                    checked={row.check === 'Y'}
                                    onChange={() => checkedHandle(row)}
                                />
                                <label htmlFor={row.divCode}>
                                    <div onClick={(e) => {showModalHandle(row, e)}} >
                                        <span className="ft-red ft-s">[{row.mandatory === 'M' ? '필수' : '선택'}]</span> {row.name}
                                    </div>
                                </label>
                            </span>
                            {/* <span class="input-chk">
                                <input type="checkbox" id="{row.divCode}" name=""
                                    checked={row.check === 'Y'} onClick={() => { checkedHandle(row) }} />
                                <label for="chk-2" onClick={() => showModalHandle(row)}><span class="ft-red ft-s">[{row.mandatory === 'M' ? '필수' : '선택'}]</span> {row.name} </label>
                            </span> */}
                        </li>
                    ))}
                </ul>
            </section>

            <section>
                <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => prePage()}>이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
                </div>
            </section>
        </React.Fragment>
    )
}

export default HC_6017; 
