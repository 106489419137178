/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6001
 *
 *
 */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api/api'
import { dateDisplay } from '../utils/common'
import { amountDisplay, convertBase64ToURL } from '../utils/common';
import jwtDecode from "jwt-decode";
import { useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName,salesId } from '../data/Atom';



function HC_6001({ customer,salesIdParam }) {

    const naviagte = useNavigate();

    const [customerCodeValue, setCustomerCode] = useState()
    const [salesIdValue, setSalesId] = useState()

    const [countValue, setCountValue] = useState(0);
    const [customerNotice, setCustomerNotice] = useState({});
    const [noticeValue, setNoticeValue] = useState([]);
    const [faqValue, setFaqValue] = useState([]);
    const [bgColor, setBgColor] = useState(null);

    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [code, setCode] = useRecoilState(customerCode);
    const [salesIdRecoilValue, setsalesIdRecoil] = useRecoilState(salesId);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName);

    const [image, setImage] = useState(null);
    const [subImage, setSubImage] = useState(null);
    const [logoTitle, setLogoTitle] = useState(null);
    const [logoContent, setLogoContent] = useState(null);
    const isInitialRender = useRef(true);
    const isInitialRender2 = useRef(true);
    const [isRender, setIsRender] = useState(false);

    // function checkAuth() {
    //     const token = localStorage.getItem('access');
    //     if (token !== null) {
    //         try {
    //             const decode = jwtDecode(token);
    //             setUserName(decode.name);
    //             setUserId(decode.sub.split("|")[1]);
    //             setUserRole(decode.auth.split("_")[1]);
    //             return true;
    //         } catch (error) {
    //             return false;
    //         }
    //     } else {
    //         return false;
    //     }
    // }


    function getCustomerNotice() {
        // if(isInitialRender2.current) {
        //     isInitialRender2.current = false
        //     return
        // }
        // api.get('/salesNotice/detail/' + customerCodeValue + '/' + salesIdParam).then(
        //     response => {
        //         if(response.data=="") {
        //             setCustomerCode('OURCARE')
        //         }
        //         setCustomerNotice(response.data);
        //     }
        // ).catch(
        //     error => {
        //         console.log("ERROR : " + JSON.stringify(error))
        //     }
        // )
    }

    function getNotice() {
        api.get('/guest/notice/A/simple').then(
            response => {
                setNoticeValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getCustomerInfo() {
        if(isInitialRender.current) {
          isInitialRender.current = false
          return
        }
      //   api.get('/customer/customerInfo/'+customerCodeValue).then(
        if(salesIdParam == null) {
            api.get('/guest/salesNotice/logo/'+customerCodeValue).then(
                response => {
                    if(response.data.customerCode == 'OURCARE') {
                        setCustomerCode(response.data.customerCode)
                    }

                    setCustomerName(response.data.customerName)
                    setLogoTitle(response.data.logoTitle)
                    setLogoContent(response.data.logoContent)
                    setCustomerNotice(response.data);
                    if (response.data.thumbNail !== undefined && response.data.thumbNail !== null) {
                        setImage(convertBase64ToURL(response.data.thumbNail.file));
                    }
                    if (response.data.subThumbNail !== undefined && response.data.subThumbNail !== null) {
                        setSubImage(convertBase64ToURL(response.data.subThumbNail.file));
                    }
                    setBgColor(response.data.bgColor)
                    setIsRender(true)
                }
              ).catch(
                error => {
                  console.log("ERROR : " + JSON.stringify(error))
                }
              )
        }
        else {
            api.get('/guest/salesNotice/logo/'+customerCodeValue + '/' + salesIdParam).then(
                response => {
                    if(response.data.customerCode == 'OURCARE') {
                        setCustomerCode(response.data.customerCode)
                    }
                    
                    setCustomerName(response.data.customerName)
                    setLogoTitle(response.data.logoTitle)
                    setLogoContent(response.data.logoContent)
                    setCustomerNotice(response.data);
                    if (response.data.thumbNail !== undefined && response.data.thumbNail !== null) {
                        setImage(convertBase64ToURL(response.data.thumbNail.file));
                    }
                    if (response.data.subThumbNail !== undefined && response.data.subThumbNail !== null) {
                        setSubImage(convertBase64ToURL(response.data.subThumbNail.file));
                    }
                    setBgColor(response.data.bgColor)
                    setIsRender(true)
                }
              ).catch(
                error => {
                  console.log("ERROR : " + JSON.stringify(error))
                }
              )
        }
    }
    
    function getFaq() {
        api.get('/guest/notice/B/simple').then(
            response => {
                setFaqValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function goNoticeDetail(divCode, serialNo) {
        api.get('/guest/notice/detail/' + divCode + '/' + serialNo).then(
            response => {
                naviagte('/HC_6024', { state: response.data })
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function goNoticeList(div) {
        const sendData = {
            div: div,
            list: div === 'A' ? noticeValue : faqValue
        }
        naviagte('/HC_6023', { state: sendData })
    }

    // useEffect(() => {
    //     if (checkAuth()) {
    //         //naviagte('/HC_5006')
    //     }
    // }, [])


    useEffect(() => {
        getNotice()
    }, [])

    useEffect(() => {
        getFaq()
    }, [])


    useEffect(() => {
        setCustomerCode(customer?customer.toUpperCase():'OURCARE')
        setsalesIdRecoil(salesIdParam?salesIdParam:'')
        // setCustomerCode(customer.toUpperCase())
    },[])

    useEffect(() => {
        setCode(customerCodeValue);
        getCustomerNotice();
        getCustomerInfo();
    },[customerCodeValue]);



    return (

        <React.Fragment>
        <section>
            {isRender ? (
            <div style={{ background: bgColor }}>
                {subImage ? (
                    <div><img src={subImage} alt="대체이미지" style={{ width: '100%', height: 'auto', maxHeight: '100px' }} /></div>
                ) : (
                    <div className="checkup-company-type2 ft-black">
                        {image && <div className="company-logo"><img src={image} alt="로고자리" style={{ width: '100%', height: 'auto', maxHeight: '100px' }} /></div>}
                        <div className="motor">{logoTitle}</div>
                        <div className="introduce">{logoContent}</div>
                    </div>
                )}
            </div>
        ) : null}
        </section>
        <section>
            <div class="checkup-subject">
                <b class="ft-blue ft-l">{customerNotice.name}</b>
                <div class="space-l"></div>
                    <div class="overflow-auto-y" style={{ height: '110px' }}>
                    <div dangerouslySetInnerHTML={{ __html: customerNotice.content }} />
                </div>
            </div>
        </section>

        <section>
            <div className="banner-hospital"  onClick={() => { naviagte('/HC_6008') }}>
                검진기관둘러보기
                <img src="/images/img_hospital.png" alt="" />
            </div>
        </section>
        {/* <section>
            <div class="pg-title-s">
                    <span>검진기관 둘러보기</span>
                </div>
                <div class="search-form" onClick={() => { naviagte('/HC_6008') }}>
                <input type="text" readOnly/><i class="icon-search"></i>
                </div>
            </section>         */}
        {/* <section>
            <div class="pg-title">
            <span><b class="ft-l"onClick={() => { naviagte('/HC_6008') }}>검진기관 둘러보기</b></span>
            </div>
            <div class="dp-flex flex-j-between">
                <div class="search-form" onClick={() => { naviagte('/HC_6008') }}>
                    <input type="text" /><a ><i class="icon-search"></i></a>
                </div>
                <img src="/images/img_hospital.png" alt="" />
            </div>
        </section> */}
        <section>
            <div class="dp-flex list-title">
                <div class="mr-auto">
                    <b class="ft-l">공지사항</b>
                </div>
                <div class="ml-auto">
                    <a onClick={() => { goNoticeList('A') }} ><i class="icon-arrow-next"></i></a>
                </div>
            </div>
            <ul class="list-wrap-noti">
                {noticeValue.map((row) => (
                    <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                        <div class="ellipsis">
                            <a class="ellipsis">{row.name}</a>
                        </div>
                        <span class="date">{dateDisplay(row.regDate)}</span>
                    </li>
                ))}
            </ul>
        </section>
        <section>
            <div class="dp-flex list-title">
                <div class="mr-auto">
                    <b class="ft-l">자주하시는 질문</b>
                </div>
                <div class="ml-auto">
                    <a onClick={() => { goNoticeList('B') }}><i class="icon-arrow-next"></i></a>
                </div>
            </div>
            <ul class="list-wrap-noti">
                {faqValue.map((row) => (
                    <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                        <div class="ellipsis">
                            <a class="ellipsis">{row.name}</a>
                        </div>
                        <span class="date">{dateDisplay(row.regDate)}</span>
                    </li>
                ))}
            </ul>
        </section>
    </React.Fragment>
        )
}

export default HC_6001; 
