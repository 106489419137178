import React from 'react';
import { Helmet } from 'react-helmet';

function Header() {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <title>아워케어</title>
            <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0" />
            <meta name="format-detection" content="telephone=no" />
            <link rel="stylesheet" type="text/css" href="/css/style_customer.css" />
        </Helmet>
    );
}

export default Header;