/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import DaumPostcode from 'react-daum-postcode';

const postCodeStyle = {
    position: "absolute",
    top: "25%",
    left: "25%",
    width: "500px",
    height: "400px",
    zIndex: 100,
    //border: '1px solid black'
};

const postCodeStyle2 = {
    border: '1px solid black'
};

const DaumAddress = ({ showModal, setAddress }) => {
    const onComplete = (data) => {
        const setData = {
            'zipCode': data.zonecode,
            'address': data.address
        }
        setAddress(setData)
    }

    const close = () => {
        const setData = {
            'zipCode': '',
            'address': ''
        }
        setAddress(setData)
    }



    return (
        <React.Fragment>
            {showModal && (
                <div className="popup-wrapper" style={postCodeStyle}>
                    <a onClick={() => close()}><i class="icon-close"></i></a>
                    <DaumPostcode
                        style={postCodeStyle2}
                        autoClose={true}
                        onComplete={onComplete}
                    ></DaumPostcode>
                </div>
            )}
        </React.Fragment>
    )
}


export default DaumAddress;