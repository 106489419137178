/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-5013
 *
 *
 */
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import { useRecoilValue } from 'recoil';
import { userId } from '../data/Atom';


function HC_6009_Sample({ showModal, closeModal, row, data }) {
  const naviagte = useNavigate();
  const userIdValue = useRecoilValue(userId);

  const [packageSample,setPackageSample] = useState(null);
  const [packageDetail,setPackageDetail] = useState(null);
  const [showData,setShowData] = useState(null);
  const [activeBasicIndex, setActiveBasicIndex] = useState(null);
  const [activeOptionalSections, setActiveOptionalSections] = useState([]);


  const closeHandle = () => {
    setPackageSample();
    setPackageDetail();
    closeModal();
  }

  function nextPage() {
    if (row.packageCode === null || row.packageCode === undefined) {
      Swal.fire({
          title: '알림',
          text: '검진패키지가 선택되지 않았습니다.',
          icon: 'info',
          confirmButtonText: '확인',
      })
  } else {
      data.packageCode = row.packageCode;
      data.packageName = row.name;
      data.packageAmount = row.amount;
      if (userIdValue === '') {
          naviagte('/HC_6005', { state: data })
        }else {
          Swal.fire({
              title: '알림',
              icon: 'info',
              text: '현재 모든 검진기관에 수검자가 많아\n검진이 지연되고 있습니다.\n고객님의 양해를 바랍니다.',
              confirmButtonText: '확인',
            }).then(
              response => {
                  if (response.isConfirmed) {
                      naviagte('/HC_6010', { state: data });
                  }
              }
          )
        }
  }
  }

  

  function getData(row) {
    const url = '/guest/item/simple/' + row.hospitalCode + '/' + row.packageCode;
    api.get(url).then(
        response => {
          setPackageSample(response.data)
        }
    ).catch(
        error => {
            console.log("ERROR : " + JSON.stringify(error))
        }
    )
  }

  function getDetailData(row) {
    const url = '/guest/item/detail/' + row.hospitalCode + '/' + row.packageCode;
    api.get(url).then(
        response => {
          setPackageDetail(response.data)
        }
    ).catch(
        error => {
            console.log("ERROR : " + JSON.stringify(error))
        }
    )
  }

  function showDetail(section, index, data) {
    // divName을 비교하여 일치하는 데이터만 필터링
    const filteredData = packageDetail.filter(
      (item) =>
        item.divName === data.divName &&
        item.selectGroupCode === data.selectGroupCode
    );

    // 필터된 데이터 설정
    if (section === 'basic') {
      setActiveBasicIndex(index);
      // 선택된 항목의 인덱스 초기화
      setActiveOptionalSections([]);
    } else if (section.startsWith('optional')) {
      const sectionIndex = parseInt(section.slice('optional'.length), 10);
      const newActiveOptionalSections = Array(packageSample.length).fill(null);
      newActiveOptionalSections[sectionIndex] = index;
      setActiveOptionalSections(newActiveOptionalSections);
      // 선택된 항목의 인덱스 초기화
      setActiveBasicIndex(null);
    }
    setShowData(filteredData);
  }

  // 새로운 이벤트 핸들러 추가
  function handleTabMouseEnter(section, index, data) {
    const activeIndex =
      section === 'basic'  ? activeBasicIndex : section.startsWith('optional')
        ? activeOptionalSections[parseInt(section.slice('optional'.length), 10)]
        : null;

    if (activeIndex !== index) {
      showDetail(section, index, data);
    }
  }

  useEffect(() => {
    getDetailData(row)
    getData(row)
    initData();
  }, [row])

  useEffect(() => {
  },[showData])

  function initData() {
    setActiveBasicIndex(null);
    setActiveOptionalSections([]);
  }

  return (
    <React.Fragment>
      {
        showModal && (
          <div id="HC-5013" class="modal">
            <div class="modal-flex">
              <div class="modal-flex-content" style={{ width: '600px' }}>

              <div class="dp-flex">
                  <div class="mr-auto"><div class="ft-l">검진항목 상세조회</div></div>
                  <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
                </div>
                <div class="space-l"></div>
                <div class="box-blue align-center">
                  <div class="ellipsis"><b>{row.name}</b></div>
                </div>
                <span class="ft-xs">각 항목을 탭하시면 상세내용을 확인하실 수 있습니다.</span>
                {/* <div class="checkup-subject">
                  <span class= "ft-blue">각 항목을 탭하시면 상세내용을 확인하실 수 있습니다.</span><br />
                </div> */}
                <div class="space-l"></div>


                <div class="form-wp">

              {packageSample ? (
                packageSample.map((a, i) => (
                  <div class="input-wp" key={i}>
            {i === 0 ? (
              <section>
                <b>기본항목</b><br/>
                <span class="ft-s">검진필수항목</span>
                <ul class="check-list-wrap">
                  {a.map((d, index) => (
                    <div>
                      <li className={ activeBasicIndex === index ? 'on box-gray' : '' }>
                        <div className={ activeBasicIndex === index ? 'check-list-on' : 'check-list' }
                          onClick={() => handleTabMouseEnter('basic', index, d)}>
                          {d.divName}
                        </div>
                        {activeBasicIndex === index &&
                          showData.length > 0 && (
                            <div className="check-list-detail-on">
                              <ul>
                              {showData.map((data, dataIndex) => (
                                <li key={dataIndex}>{data.name}</li>
                              ))}
                              </ul>
                            </div>
                          )}
                      </li>
                    </div>
                  ))}
                </ul>
              </section>
            ) : (
              <section>
                <b>선택항목{i} - 택 {a[0].selectCount}</b><br/>
                <span class="ft-s">기본항목 외 정밀검사</span>
                <ul class="check-list-wrap">
                  {a.map((d, index) => (
                    <div>
                      <li key={index} className={activeOptionalSections[i] === index ? 'on box-gray' : ''}>
                        <div
                          className={activeOptionalSections[i] === index ? 'check-list-on' : 'check-list'}
                          onClick={() =>handleTabMouseEnter(`optional${i}`, index, d)}
                        >
                          {d.divName}
                        </div>
                        {activeOptionalSections[i] === index &&
                          showData.length > 0 && (
                            <div className="check-list-detail-on">
                              <ul>
                              {showData.map((data, dataIndex) => (
                                <li key={dataIndex}>{data.name}</li>
                              ))}
                              </ul>
                            </div>
                          )}
                      </li>
                    </div>
                  ))}
                </ul>
              </section>
            )}
             {/* 오른쪽 사이드 메뉴 */}
             <div id="float-sidenav" class="sidebanner">
                <a>
                    <div class="link-icon" onClick={() => nextPage()}><i class="icon-approval"></i></div>
                    <p>예약하기</p>
                </a>
                {}
                <a>
                    <div class="link-icon"  onClick={() => closeHandle()}><i class="icon-refresh"></i></div>
                    <p>이전</p>
                </a>
            </div>
            {/* 오른쪽 사이드 메뉴 */}
          </div>
        ))
      ) : (
        <p>잠시만 기다려주세요.</p>
      )}
      </div>
              <div class="dp-flex">
                <button class="flex1 btn page-btn btn-primary" onClick={() => closeHandle()}>확인</button>
              </div>
              </div>
            </div>
          </div>
        )
      }

    </React.Fragment>
  )
}

export default HC_6009_Sample; 
