/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 로그인
 * HC-6005
 *
 *
 */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from "axios";
import jwtDecode from "jwt-decode";
import { emailValidation } from '../utils/common'
import { customerCode, userName, userId, userRole, customerName, isLogout } from '../data/Atom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import HC_6030 from './HC_6030.jsx';

function HC_6005() {
  const navigate = useNavigate();
  const location = useLocation();
  const preData = location.state; // 전달된 데이터 가져오기 (hospitalCode 혹은 companyName)
  const customerCodeValue = useRecoilValue(customerCode);
  const customerNameValue = useRecoilValue(customerName);
  const [isLogoutValue,setIsLogout] = useRecoilState(isLogout);
  // const customerCodeValue = preData;
  const [memberId, setMemberId] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false)
  const setUserName = useSetRecoilState(userName);
  const setUserId = useSetRecoilState(userId);
  const setUserRole = useSetRecoilState(userRole);


  useEffect(() => {
    var sessionStorageData = sessionStorage.getItem('sessionStorage');
    var sessionDataObject = JSON.parse(sessionStorageData);
    if(sessionDataObject.userId != '' && sessionDataObject.userId != undefined){
      navigate('/HC_6006')
    }
  },[])



  function loginHandle() {
    if (memberId === '') {
      Swal.fire({
        title: '실패',
        text: 'ID를 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      })
    } else if (!emailValidation(memberId)) {
      Swal.fire({
        title: '실패',
        text: '이메일 형식의 아이디를 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }
    else if (password === '') {
      Swal.fire({
        title: '실패',
        text: '비밀번호를 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      })
    } else {
      const reqBody = {
        "customerCode": customerCodeValue,
        "div": 'CU',
        "memberId": memberId,
        "password": password
      }
      axios({
        method: "post", // [요청 타입]
        url: process.env.REACT_APP_BASE_URL + '/guest/token/login', // [요청 주소]
        data: reqBody, // [요청 데이터]
        withCredentials: true,
        headers: {
          // 'Content-Type': 'multipart/form-data'
        }, // [요청 헤더]
        //timeout: 5000 // [타임 아웃 시간]
        //responseType: "json" // [응답 데이터 : stream , json]
      })
        .then(function (response) {
          const data = response.data;
          if(data.result == "fail") {
              var ment;
              if(data.customerType == 'G') {
                var ment = '혹시 <a style="color:cornflowerblue; font-weight: bold;" href = "https://ourcare.co.kr/'+data.customerCode+'">'+data.companyName+'</a>에서 가입하셨나요?'
              }
              if(data.customerType == 'C') {
                var ment = '혹시 <a style="color:cornflowerblue; font-weight: bold;" href = "https://ourcare.co.kr/'+data.customerCode+'/' + data.salesId+'">'+data.companyName+'</a>에서 가입하셨나요?'
              }

              Swal.fire({
                title: '알림',
                html: '로그인 실패했습니다.<br>' + ment,
                icon: 'info',
                confirmButtonText: '확인',
                showCancelButton: true, // 취소 버튼 표시
                cancelButtonText: '취소'
              }).then((result) => {
                if (result.isConfirmed) {
                  if(data.customerType == 'G') {
                    window.location.href = `/${data.customerCode}`;
                  }
                  if(data.customerType == 'C') {
                    window.location.href = `/${data.customerCode}/${data.salesId}`;
                  }
                }
              });
          }
          else {
            const accessToken = data.accessToken;
            localStorage.setItem('access', accessToken);
            
            const decode = jwtDecode(accessToken);
            // setUserName(decode.name);
            setUserName(data.name);
            setUserId(decode.sub.split("|")[1]);
            setUserRole(decode.auth.split("_")[1]);
            setIsLogout(false);
  
            if (preData === undefined || preData === null ) {
              navigate("/HC_6006");
            } else {
              navigate('/HC_6010', { state: preData });
            }
          }
        })
        .catch(function (error) {
          Swal.fire({
            title: '로그인실패',
            html: '로그인실패 하였습니다. ID와 PW를 확인해주세요.',
            icon: 'error',
            confirmButtonText: '확인',
          });
          console.log("ERROR : " + JSON.stringify(error));
        });
    }
  }

  function findPassword() {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false);
}

  const userNameHandle = (e) => {
    setMemberId(e.target.value);
  }

  const passwordHandle = (e) => {
    setPassword(e.target.value);
  }

  return (
    <React.Fragment>
      <HC_6030 showModal={showModal} closeModal={closeModal}/>
      <div class="pg-title">
        <span>로그인</span>
      </div>
      <section>

        <div class="form-wp">
          <div class="input-wp">
            <p>아이디(이메일)</p>
            <input class="w-100" type="text" placeholder="아이디(이메일)을 입력하세요." value={memberId} onChange={(e) => userNameHandle(e)} />
          </div>
          <div class="input-wp">
            <p>비밀번호</p>
            <input class="w-100" type="password" placeholder="비밀번호를 입력하세요" value={password} onChange={(e) => passwordHandle(e)} />
          </div>
          {/* <div class="align-right"><a onClick={() => navigate('/HC_6002', {state: customerCodeValue})} class="m-r-l">회원가입</a> */}
          <div class="align-right"><a onClick={() => navigate('/HC_6001_certi')} class="m-r-l">회원가입</a>
            <a onClick={() => findPassword()}>비밀번호찾기</a></div>
        </div>
      </section>
      <section>
        <button class="btn page-btn btn-primary w-100" onClick={() => loginHandle()}>로그인</button>
      </section>
    </React.Fragment>
  )
}

export default HC_6005; 
