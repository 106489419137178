import React from 'react';
const ErrorPage = () => {
  const notfound = {
    position: "relative",
    height: "100vh"
  }

  const notfound2 = {
    position: "absolute",
    left: "50%",
    top: "50%",
    WebkitTransform: "translate(-50%, -50%)",
    MsTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
    // marginLeft: "50%"
  }

  const notfound404 = {
    position: "absolute",
    left: "0",
    top: "0",
    display: "inline-block",
    width: "140px",
    height: "140px",
    backgroundImage: "url(/icon/error.png)",
    backgroundSize: "cover",
  }

  const notfound404Before = {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    WebkitTransform: "scale(2.4)",
    MsTransform: "scale(2.4)",
    transform: "scale(2.4)",
    borderRadius: "50%",
    backgroundColor: "#f2f5f8",
    zIndex: "-1",
  }


  return (
    <>
      <div style={notfound}>
        <div style={notfound2}>
          <div style={notfound404}>
            <div style={notfound404Before}></div>
          </div>
          <div style={{marginLeft:'200px'}}>
          <h1>404</h1>
          <h2>페이지를 찾을 수 없습니다.</h2>
          <p style={{color:'#9FA0A0'}}>동일한 현상 반복시 헬프데스크로 문의 바랍니다.<br/>
          ☎ 1551-0787 (운영시간 : 평일 09:00 ~ 18:00)
          </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage; 
