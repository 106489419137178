import axios from 'axios';
import Swal from 'sweetalert2';
const api = axios.create(
  {
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  }
);

// 인터셉터 설정
api.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV === 'development') {
      // 개발 환경에서는 proxy를 사용
      config.proxy = process.env.REACT_APP_PROXY_URL;
    }
    // auth key를 header에 추가
    const authToken = localStorage.getItem('access');
    config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // 응답이 정상적으로 수신된 경우
    return response;
  },
  (error) => {
    // 응답이 없는 경우 처리
    if (!error.response) {
      Swal.fire({
        title: '네트워크에러',
        text: '서버응답이 없습니다.',
        icon: 'error',
        confirmButtonText: '확인',
      });
      console.log('API 서버 응답이 없습니다.');
      // 여기에 원하는 동작을 추가하세요 (예: 에러 처리, 재시도 등)
    }
    return Promise.reject(error);
  }
);

export default api;