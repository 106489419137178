/**
 * 
 * HC-6020
 *
 *
 */
import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useRecoilValue } from 'recoil';
import { userName, userId } from '../data/Atom';
import { useCache } from "../components/common/CacheProvider";
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk";
import { amountDisplay } from '../utils/common'

//const clientKey = "test_ck_4Gv6LjeKD8aGmAZgNowVwYxAdXy1"



function HC_6020() {
  const clientKey = process.env.REACT_APP_CLIENT_KEY;
  const selector = "#payment-widget";
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const { cacheValue } = useCache();
  const [price, setPrice] = useState(0)
  const [orderId, setOrderId] = useState('');
  const [orderName, setOrderName] = useState('');
  const userNameValue = useRecoilValue(userName);
  const userIdValue = useRecoilValue(userId);

  function dataCheck() {
    if (data === null || data === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    } else {
      if(data.type == 'NOMAL') {
        const orderId = data.hospitalCode + '_' + data.reserveNo;
        setOrderId(orderId)
        setOrderName(data.hospitalName + ' ' + data.packageName + ' 예약 수수료')
        cacheValue(orderId, data);
        setPrice(data.serviceAmount);
      }
      if(data.type == 'TOSS_FAIL') {
        setOrderId(data.orderId);
        setOrderName(data.hospitalName + ' ' + data.packageName + ' 예약 수수료')
        setPrice(data.serviceAmount);
      }
    }
  }

  async function pay() {
    try {
      const paymentWidget = paymentWidgetRef.current;
      const paymentAgreement = paymentWidget.renderAgreement('#agreement')
      const agg = paymentAgreement.getAgreementStatus().agreedRequiredTerms

      if (agg) {
        paymentWidget.requestPayment({
          orderId: orderId,
          orderName: orderName,
          paymentType : "CARD",
          customerName: userNameValue,
          customerEmail: userIdValue,
          customerMobilePhone: data.resvInfo.phone,
          successUrl: `${window.location.origin}/HC_6021`,
          failUrl: `${window.location.origin}/HC_Fail`,
        });
      } else {
        Swal.fire({
          title: '에러',
          text: '필수 약관에 동의해주세요',
          icon: 'error',
          confirmButtonText: '확인',
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function init() {
    const paymentWidget = await loadPaymentWidget(clientKey, ANONYMOUS);
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(selector, { value: price });
    paymentWidget.renderAgreement("#agreement");
    paymentWidgetRef.current = paymentWidget;
    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }

  useEffect(() => {
    init();
  }, [price])

  useEffect(() => {
    dataCheck()
  }, []);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    paymentMethodsWidget.updateAmount(price, paymentMethodsWidget.UPDATE_REASON.COUPON);
  }, [price]);

  return (
    <React.Fragment>
      <div>

        <div class="box-green align-center">
          <h1>서비스비용 결제</h1>
          <h5 class="ellipsis"> {amountDisplay(price)} 원</h5>
        </div>
        <div id="payment-widget" />
        <div id="agreement" />
        <div>
          <section>
            <div class="dp-flex">
              <button class="btn page-btn flex1 flex1 m-r-s" onClick={() => naviagte('/HC_6019', { state: data })}>이전</button>
              <button class="btn page-btn flex2 btn-primary" onClick={() => pay()}>결제하기</button>
            </div>
          </section>
        </div>
      </div >
    </React.Fragment>
  )
}

export default HC_6020; 
