/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6029
 *
 *
 */
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const HC_6029 = () => {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  return (<React.Fragment>
    <h5><a onClick={() => naviagte('/HC_6027')}><i class="icon-arrow-left2"></i></a> </h5>
    <div class="space-l"></div>
    <section>
      <div class="form-wp">
        <div class="input-wp">
          <p>문의제목</p>
          <div class="dpview">{data !== null ? data.title : ''} </div>
        </div>
        <div class="input-wp">
          <p>문의유형</p>
          <div class="dpview">{data !== null ? data.divCode === 'A' ? '시스템문의' : data.divCode === 'B' ? '검진문의' : '' : ''} </div>
        </div>
        <div class="input-wp">
          <p>문의내용</p>
          <textarea readOnly class="fm-def" style={{ width: '100%', height: '100px' }} value={data !== null ? data.content : ''} ></textarea>
        </div>
        <div class="input-wp">
          <p>답변내용</p>
          <textarea readOnly class="fm-def" style={{ width: '100%', height: '100px' }} value={data !== null ?data.answerContent : ''}></textarea>
        </div>
      </div>
    </section>
    <section>
      <div class="dp-flex">
        <button class="btn page-btn flex1 btn-primary" onClick={() => naviagte('/HC_6027')}>확인</button>
      </div>
    </section>
  </React.Fragment>)
}

export default HC_6029; 
