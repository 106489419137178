/**
 * 회원가입 
 * HC-6002
 *
 *
 */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import { formatNumber, emailValidation, passwordConfirmValidation, phoneValidation, birthValidation, passwordValid } from '../utils/common'
import { customerCode, salesId } from '../data/Atom';
import { useRecoilValue } from 'recoil';

function HC_6002() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기
  const customerCodeValue = useRecoilValue(customerCode);
  const salesIdValue = useRecoilValue(salesId);
  // const customerCodeValue = data;
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [sex, setSex] = useState('');
  const [phone, setPhone] = useState('');
  const [salesMemberId, setSalesMemberId] = useState('');
  const [salesCustomerCode, setSalesCustomerCode] = useState('');
  const [bYear, setBYear] = useState('');
  const [bMonth, setBMonth] = useState('');
  const [bDay, setBDay] = useState('');
  const [dupCheck, setDupCheck] = useState(false);
  const [certiType, setCertiType] = useState('');
  const [certificationCode, setCertificationCode] = useState('');

  useEffect(() => {
    if(data != null) {
      if(data.certiType == 'email') {
        setCertiType('email')
        setCertificationCode(data.certificationCode)
        setDupCheck(true)
        setId(data.email)
          const idInput = document.querySelector('.flex2');
          if (idInput) {
            idInput.setAttribute('readonly', true);
          }
      }
      else if(data.certiType == 'phone') {
        setCertiType('phone')
        setName(data.name)
        setPhone(data.phone)
        setSex(data.sex)
        setBYear(data.birth.slice(0, 4))
        setBMonth(data.birth.slice(4, 6))
        setBDay(data.birth.slice(6, 8))
        setCertificationCode(data.certificationCode)
         // Set readOnly for Name and Phone fields
         //flex1" placeholder="일" name="bDay"
      const nameInput = document.querySelector('.input-wp input[name="name"]');
      const phoneInput = document.querySelector('.input-wp input[name="phone"]');

      if (nameInput) {
        nameInput.setAttribute('readonly', true);
      }
      if (phoneInput) {
        phoneInput.setAttribute('readonly', true);
      }
      
      const bYearInput = document.querySelector('.input-wp input[name="bYear"]');
      const bMonthInput = document.querySelector('.input-wp select[name="bMonth"]');
      const bDayInput = document.querySelector('.input-wp input[name="bDay"]');

      if (bYearInput) {
        bYearInput.setAttribute('readonly', true);
      }
      if (bMonthInput) {
        bMonthInput.setAttribute('disabled', true); // Use 'disabled' instead of 'readonly' for select elements
      }
      if (bDayInput) {
        bDayInput.setAttribute('readonly', true);
      }

      // Set selected option for sex
      const maleRadio = document.querySelector('input[value="M"]');
      const femaleRadio = document.querySelector('input[value="F"]');
      if (data.sex === 'M' && maleRadio) {
        maleRadio.setAttribute('checked', true);
      } else if (data.sex === 'F' && femaleRadio) {
        femaleRadio.setAttribute('checked', true);
      }


      }
    } else {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
    }).then(response => {
        naviagte(-1);
    })
    }
  },[])

  function getCustomerInfo() {
    // api.get('/customer/customerInfo/'+customerCodeValue +'/' + salesIdValue).then(
      api.get('/guest/customer/customerInfo/'+customerCodeValue).then(
      response => {
        setSalesCustomerCode(response.data.salesCustomerCode)
        if(response.data.salesId != null && response.data.salesId != '') {
          setSalesMemberId(response.data.salesId)
        }else {
          setSalesMemberId(salesIdValue)
        }
        
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
      }
    )
  }


  // function dataCheck() {
  //   if (data !== null && data !== undefined) {
  //     setId(data.memberId)
  //     setPassword(data.password)
  //     setName(data.name)
  //     setPhone(data.telNo)
  //     setSex(data.sex)
  //     setSalesMemberId(data.salesMemberId)
  //     setSalesCustomerCode(data.salesCustomerCode)
  //     setBYear(data.bYear)
  //     setBMonth(data.bMonth)
  //     setBDay(data.bDay)
  //   }
  // }

  function idDupCheck() {
    if (id === '') {
      Swal.fire({
        title: '실패',
        text: '아이디를 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        setDupCheck(false);
      })
    } else {
      if (emailValidation(id)) {
        const reqBody = {
          customerCode: customerCodeValue,
          memberId: id
        }
        api.post('/guest/member/duplication', reqBody).then(
          response => {
            if (response.data) {
              Swal.fire({
                title: '성공',
                text: '중복되지 않은 아이디 입니다.',
                icon: 'success',
                confirmButtonText: '확인',
              }).then(response => {
                setDupCheck(true);
              })
            } else {
              Swal.fire({
                title: '실패',
                text: '중복된 아이디 입니다. 다시 확인해 주세요.',
                icon: 'error',
                confirmButtonText: '확인',
              }).then(response => {
                setDupCheck(false);
              })
            }
          }
        ).catch(
          error => {
            console.log("ERROR : " + JSON.stringify(error))
            Swal.fire({
              title: '실패',
              text: '서버와의 통신이 정상적이지 않습니다. 다시 시도해 주세요.',
              icon: 'error',
              confirmButtonText: '확인',
            }).then(response => {
              setDupCheck(false);
            })
          }
        )
      } else {
        Swal.fire({
          title: '실패',
          text: '이메일 형식의 아이디를 입력해 주세요',
          icon: 'error',
          confirmButtonText: '확인',
        })
      }
    }
  }

  function validation() {
    if (!dupCheck) {
      Swal.fire({
        title: '실패',
        text: '아이디 중복체크를 먼저 진행해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        setDupCheck(false);
        return false;
      })
    } else if (password === '' || passwordConfirm === '') {
      Swal.fire({
        title: '실패',
        text: '패스워드를 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (name === '') {
      Swal.fire({
        title: '실패',
        text: '이름을 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (phone === '') {
      Swal.fire({
        title: '실패',
        text: '전화번호를 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (sex === '') {
      Swal.fire({
        title: '실패',
        text: '성별을 선택해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }
    //  else if (sale === '') {
    //   Swal.fire({
    //     title: '실패',
    //     text: '추천인 코드를 입력해 주세요.',
    //     icon: 'error',
    //     confirmButtonText: '확인',
    //   }).then(response => {
    //     return false;
    //   })
    // }
    else if (bYear === '' || bMonth === '' || bDay === '') {
      Swal.fire({
        title: '실패',
        text: '생년월일을 입력해 주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else {
      if(!passwordValid(password)) {
        Swal.fire({
          title: '실패',
          text: '비밀번호는 숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.',
          icon: 'error',
          confirmButtonText: '확인',
        }).then(response => {
          return false;
        })
      }
      else if (!passwordConfirmValidation(password, passwordConfirm)) {
        Swal.fire({
          title: '실패',
          text: '패스워드가 일치하지 않습니다.',
          icon: 'error',
          confirmButtonText: '확인',
        }).then(response => {
          return false;
        })
      } else if (!phoneValidation(phone)) {
        Swal.fire({
          title: '실패',
          text: '유효하지 않은 전화번호 입니다.',
          icon: 'error',
          confirmButtonText: '확인',
        }).then(response => {
          return false;
        })
      } else if (!birthValidation(bYear, bMonth, bDay)) {
        Swal.fire({
          title: '실패',
          text: '유효하지 않은 생년월일 입니다.',
          icon: 'error',
          confirmButtonText: '확인',
        }).then(response => {
          return false;
        })
      } else {
        return true;
      }
    }
  }

  function nextPage() {
    if (validation()) {
      const sendData = {
        memberId: id,
        password: password,
        name: name,
        telNo: phone,
        salesId: salesMemberId,
        salesCustomerCode: salesCustomerCode,
        bYear: bYear,
        bMonth: bMonth,
        bDay: bDay,
        birth: bYear + bMonth + formatNumber(bDay),
        sex: sex,
        customerCode: customerCodeValue,
        certiType: certiType,
        certificationCode: certificationCode
      }
      naviagte('/HC_6003', { state: sendData })
    }
  }

  const idHandle = (e) => {
    setId(e.target.value);
    setDupCheck(false);
  }

  const passwordHandle = (e) => {
    setPassword(e.target.value);
  }

  const passwordConfirmHandle = (e) => {
    setPasswordConfirm(e.target.value);
  }

  const nameHandle = (e) => {
    setName(e.target.value);
  }

  const sexHandle = (e) => {
    setSex(e);
  }

  const phoneHandle = (e) => {
    setPhone(e.target.value);
  }
  // const salesIdHandle = (e) => {
  //   setSalesId(e.target.value);
  // }

  const bYearHandle = (e) => {
    setBYear(e.target.value);
  }

  const bMonthHandle = (e) => {
    setBMonth(e.target.value);
  }

  const bDayHandle = (e) => {
    setBDay(e.target.value);
  }

  useEffect(() => {
    //dataCheck();
    getCustomerInfo();
  }, [])

  return (
    <React.Fragment>
      <div class="pg-title">
        <span>회원가입</span>
      </div>
      <section>
        <div class="form-wp">
          <div class="input-wp">
            <p>아이디(이메일)</p>
            <div class="dp-flex m-b-s">
              <input class="flex2 m-r-s" type="text" placeholder="아이디(이메일)을 입력하세요." value={id} onChange={(e) => { idHandle(e) }} />
              <button class="flex1 btn-formbt" onClick={() => { idDupCheck() }}>아이디 중복확인</button>
            </div>

          </div>
          <div class="input-wp">
            <p>비밀번호</p>
            <input class="w-100" type="password" value={password} onChange={(e) => { passwordHandle(e) }} />
          </div>
          <div class="input-wp">
            <p>비밀번호 확인</p>
            <input class="w-100" type="password" value={passwordConfirm} onChange={(e) => { passwordConfirmHandle(e) }} />
          </div>
          <div class="input-wp">
            <p>성명</p>
            <input class="w-100" name="name" type="text" value={name} onChange={(e) => { nameHandle(e) }} />
          </div>
          <div class="input-wp">
            <p>연락처</p>
            <input class="w-100" name = "phone" type="text" value={phone} placeholder="'-' 제외하고 입력하여 주세요." onChange={(e) => { phoneHandle(e) }} />
          </div>
          

          <div class="input-wp">
              <p>성별</p>
              <div class="radio">
                  <label>
                      <input type="radio" name="option" id="" value="M"
                          checked={sex === 'M'} onClick={() => sexHandle('M')} /> 남자
                  </label>
                  <label>
                      <input type="radio" name="option" id="" value="F"
                          checked={sex === 'F'} onClick={() => sexHandle('F')} /> 여자
                  </label>
              </div>
          </div>

          {/* <div class="input-wp">
            <p>추천인코드</p>
            <input class="w-100" type="text" value={sale} placeholder="추천인 코드를 입력해주세요." onChange={(e) => { saleHandle(e) }} />
          </div> */}


          <div class="input-wp">
            <p>생년월일</p>
            <div class="dp-flex">
              <input class="flex1 m-r-s" name = "bYear" placeholder="년(4자)" value={bYear} onChange={(e) => bYearHandle(e)} />
              <select class="flex1 m-r-s" name = "bMonth" value={bMonth} onChange={(e) => { bMonthHandle(e) }}>
                <option value="">월</option>
                <option value="01">1월</option>
                <option value="02">2월</option>
                <option value="03">3월</option>
                <option value="04">4월</option>
                <option value="05">5월</option>
                <option value="06">6월</option>
                <option value="07">7월</option>
                <option value="08">8월</option>
                <option value="09">9월</option>
                <option value="10">10월</option>
                <option value="11">11월</option>
                <option value="12">12월</option>
              </select>
              <input class="flex1" placeholder="일" name="bDay" value={bDay} onChange={(e) => { bDayHandle(e) }} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex1 m-r-s" onClick={() => naviagte(-1)}>이전</button>
          <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>다음</button>
        </div>
      </section>
    </React.Fragment>
  )
}

export default HC_6002; 
