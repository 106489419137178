/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-6021
 *
 *
 */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { userName,delayedItem } from '../data/Atom';
import { useRecoilValue } from 'recoil';
import { useCache } from "../components/common/CacheProvider";
import Swal from 'sweetalert2';
import api from '../api/api';

function HC_6021() {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const naviagte = useNavigate();
  const userNameValue = useRecoilValue(userName);
  const delayedItemValue = useRecoilValue(delayedItem);
  const { deleteCachedValue } = useCache();
  const [payed, setPayed] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  const orderId = urlParams.get('orderId');
  const paymentKey = urlParams.get('paymentKey')
  const amount = urlParams.get('amount')

  // btoa 함수를 사용하여 secretKey를 Base64로 인코딩
  const encryptedSecretKey = "Basic " + btoa(secretKey + ":");

  function dataCheck() {
    const data = delayedItemValue;
    api.patch('/cu/reserve/' + orderId + '/' + paymentKey, data).then(
      response => {
        if (response.data === false) {
          console.log('error')
        }
        deleteCachedValue(orderId);
      }
    ).catch(
      error => {
        console.log("ERROR : " + JSON.stringify(error))
        // Swal.fire({
        //   title: '에러',
        //   text: '서버 에러가 발생했습니다. 다시 시도해 주세요',
        //   icon: 'error',
        //   confirmButtonText: '확인',
        // })
      }
    )
    setPayed(true)
  }

  useEffect(() => {
    // dataCheck()
    if (orderId === null || orderId === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    } else {
      axios.post('https://api.tosspayments.com/v1/payments/confirm',
      {
          "amount": amount,
          "orderId": orderId,
          "paymentKey": paymentKey
      },
      {
          headers: {'Authorization' : encryptedSecretKey}
      }).then(() => {
        dataCheck() 
      }).catch( (e) => {
        setPayed(false)
        Swal.fire({
          title: '에러',
          text: '예상하지 못한 에러가 발생했습니다.',
          icon: 'error',
          confirmButtonText: '확인',
        }).then(response => {
          if(response.isConfirmed) {
            const reqBody = {
              'status' : e.response.status,
              'errorCode' : e.response.data.code,
              'errorMessage' : e.response.data.message,
            }
            api.post('/toss/fail', reqBody).catch(e => {
              console.log("ERROR : " + JSON.stringify(e));
            });
            naviagte('/HC6022');
          }
        })

      })
    }
  }, []);


  return (<React.Fragment>
    <section>
    {payed ? (
        <h5>
          {userNameValue}님, <p className="ft-thin">검진예약이 완료되었습니다.</p>
        </h5>
      ) : (
        <></>
      )}
      <div class="space-l"></div>
      <div class="align-center"><img src="/images/center.jpg" class="w-100" alt="" /></div>
    </section>
    
    <section>
    {payed ? (
      <div class="dp-flex">
      <button class="btn page-btn flex1 btn-primary" onClick={() => naviagte('/HC_6022')}>확인</button>
      </div>
      ) : (     
      <div class="dp-flex">
      <button class="btn page-btn flex1 btn-primary" onClick={() => naviagte('/HC_6006')}>확인</button>
      </div>
    )}
    </section>
  </React.Fragment>)
}

export default HC_6021; 
