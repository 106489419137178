/**
 * 회원가입완료
 * HC-6004
 *
 *
 */
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function HC_6004() {
  const naviagte = useNavigate();
  const location = useLocation();
  const data = location.state; // 전달된 데이터 가져오기

  function dataCheck() {
    if (data === null || data === undefined) {
      Swal.fire({
        title: '에러',
        text: '잘못된 접근입니다.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        naviagte(-1);
      })
    }
  }

  useEffect(() => {
    dataCheck()
  }, [])

  return (
    <React.Fragment>
      <section>
        <h5>{data}님,<p class="ft-thin">회원가입이 완료되었습니다.</p></h5>
        <div class="space-l"></div>
        <div class="align-center"><img src="/images/medical.jpg" class="w-100" alt="" /></div>
        <div class="space-l"></div>
        <div class="ft-thin">로그인 버튼을 누르시면<br />검진예약을 진행하실 수 있습니다.</div>
      </section>
      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex1 btn-primary" onClick={() => naviagte('/HC_6005')}>로그인</button>
        </div>
      </section>
    </React.Fragment>
  )
}

export default HC_6004; 
