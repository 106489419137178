/**
 * 
 * HC-6022
 *
 *
 */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import api from '../api/api'
import { userId, customerCode } from '../data/Atom';
import { amountDisplay, dateDisplay } from '../utils/common';
import Swal from 'sweetalert2';
import { func } from 'prop-types';
import HC_6031 from './HC_6031';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


function HC_6022() {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const naviagte = useNavigate();
    const userIdValue = useRecoilValue(userId);
    const customerCodeValue = useRecoilValue(customerCode);
    const [reserve, setReserve] = useState([]);
    const [showModal, setShowModal] = useState();
    const [modalData, setModalData] = useState();

    const encryptedSecretKey = "Basic " + btoa(secretKey + ":");

    function getReserveContent() {
        const url = '/cu/reserve';
        api.get(url).then(
            response => {
                setReserve(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getRelation(data) {
        if (data === null || data === undefined) {
            return '';
        } else {
            switch (data) {
                case 'A':
                    return '본인';
                case 'B':
                    return '배우자';
                case 'C':
                    return '부모';
                case 'D':
                    return '자녀';
                case 'E':
                    return '형제';
                default:
                    return '기타';
            }
        }
    }

    function getReserveStat(data) {
        if (data === null || data === undefined) {
            return '';
        } else {
            switch (data) {
                case '10':
                    return '예약대기';
                case '20':
                    return '예약확정';
                case '30':
                    return '취소완료';
                case '40':
                    return '검진완료';
                default:
                    return '미검진';
            }
        }
    }

    function cancel(data) {
        const url = '/cu/reserve/cancel';
        axios.post('https://api.tosspayments.com/v1/payments/'+data.paymentkey+'/cancel',
        {
            "cancelReason":"고객 변심"
        },
        {
            headers: {'Authorization' : encryptedSecretKey}
        }).then((result) => {
            //if result.status == 20?
            const reqBody = {
                customerCode : customerCodeValue,
                hospitalCode : data.id,
                memberId : userIdValue,
                reserveSerialNo : data.reserveSerialNo,
                paymentkey : data.paymentkey,
                statCode : data.statCode
            }
            api.post(
                url,
                reqBody
            ).catch(
            error => {
              console.log("ERROR : " + JSON.stringify(error))
            }).then(result => {
                if(result) {
                    Swal.fire({
                        title: '성공',
                        text: '취소 되었습니다.',
                        icon: 'success',
                        confirmButtonText: '확인',
                    }).then(response => {
                        if(response.isConfirmed) {
                            getReserveContent();
                        }
                    })
                }
            })
        }).catch(e => {
            // Swal.fire({
            //     title: '에러',
            //     text: '예상하지 못한 에러가 발생했습니다.',
            //     icon: 'error',
            //     confirmButtonText: '확인',
            //   })
              const reqBody = {
                'status' : e.response.status,
                'errorCode' : e.response.data.code,
                'errorMessage' : e.response.data.message,
              }
              api.post('/toss/fail', reqBody).catch(e => {
                console.log("ERROR : " + JSON.stringify(e));
              })
        })
    }

    function cancelReserve(index) {
        if(reserve[index].statCode == '00') {
            cancel(reserve[index])
        }
        if(reserve[index].statCode == '10') {
            cancel(reserve[index])
        }
        if(reserve[index].statCode == '20') {
            // 예약확정
            if(reserve[index].restDay >= 1) {
                cancel(reserve[index])
            } else {
            Swal.fire({
                title: '실패',
                text: '취소는 검진일 이틀 전부터 가능합니다.',
                icon: 'error',
                confirmButtonText: '확인',
            })
            }
            cancel(reserve[index])
        }
        if(reserve[index].statCode == '30') {
            // 취소완료
            Swal.fire({
                title: '실패',
                text: '이미 취소 된 항목입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            })
        }
        if(reserve[index].statCode == '40') {
            // 검진완료
            Swal.fire({
                title: '실패',
                text: '이미 검진완료 된 항목입니다.',
                icon: 'error',
                confirmButtonText: '확인',
              })
        }
    }

    function cancelReserveChk(index) {
        Swal.fire({
            title: '알림',
            text: '정말로 취소하시겠습니까?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: '확인',
            cancelButtonText: '취소',
          }).then((result) => {
            if (result.isConfirmed) {
                cancelReserve(index)
            }
          })
    }


    function showDetails(row) {
        setModalData(row)
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getReserveContent()
    }, [])
    
    return (<React.Fragment>
        <HC_6031 showModal={showModal} closeModal={closeModal} data = {modalData}/>
        <div class="pg-title">
            <span>검진예약현황</span>
            <span class="ft-orange">({reserve.length})</span>
        </div>
        {reserve.map((row, index) => (
           <React.Fragment>
           <div id="reservation">
                   <ul class="list-wrap">
                   <li class="ft-blue">
                       <div class="dp-flex">
                           <div class="flex1">예약번호</div>
                           <div class="flex1 align-right" onClick={() => showDetails(row)}><b>{row.reserveSerialNo}</b></div>
                       </div>
                   </li>
                   <li>
                       <div class="dp-flex">
                           <div class="flex1">검진기관명</div>
                           <div class="flex1 align-right"><b>{row.hospitalName}</b></div>
                       </div>
                   </li>
                   <li>
                       <div class="dp-flex">
                           <div class="flex1">검진패키지명</div>
                           <div class="flex1 align-right"><b>{row.packageName}</b></div>
                       </div>
                   </li>
                   </ul>
                   <div class="box-gray p-all-m">
               <section>
                   <div class="list-title" style={{display:'flex'}}><b>검진비용</b>
                   <div class="flex1 align-right">
                   {(row.statCode == '30') || (row.statCode == '40')? <></> :
                      <button className="btn btn-primary" style={{ width:'40px', height: '30px' }} onClick={() => {
                        cancelReserveChk(index)
                    }}>취소</button>}
                    </div>
                    </div>
                       <ul class="list-wrap">
                           <li>
                               <div class="dp-flex">
                                   <div class="flex1">총 금액</div>
                                   <div class="flex1 align-right"><b>{amountDisplay(row.totalAmount + 20000)}</b></div>
                               </div>
                           </li>
                           <li>
                               <div class="dp-flex">
                                   <div class="flex1">기본금액</div>
                                   <div class="flex1 align-right"><b>{amountDisplay(row.basicAmount)}원</b></div>
                               </div>
                           </li>
                           <li>
                               <div class="dp-flex">
                                   <div class="flex1">추가금액</div>
                                   <div class="flex1 align-right"><b>{amountDisplay(row.addAmount)}원</b></div>
                               </div>
                           </li>
                           <li>
                               <div class="dp-flex">
                                   <div class="flex1">결제방법</div>
                                   <div class="flex1 align-right"><b>카드결제</b></div>
                               </div>
                           </li>
                       </ul>
               </section>
               <section>
                   <div class="list-title">
                       <b>예약내용<span class="ft-orange">({getReserveStat(row.statCode)})</span></b>
                   </div>
                   <div class="box-blue">{row.checkerName} / {dateDisplay(row.checkerBirth)} / {row.checkerSex === 'M' ? '남성' : '여성'} / {getRelation(row.checkerRelation)}</div>
                   <ul class="list-wrap">
                       <li>
                           <div class="dp-flex">
                               <div class="flex1">예약일자</div>
                               <div class="flex1 align-right"><b>{dateDisplay(row.bookingDate)}</b></div>
                           </div>
                       </li>
                       <li>
                           <div class="dp-flex">
                               <div class="flex1">검진희망일</div>
                               <div class="flex1 align-right"><b>{dateDisplay(row.hopeDate)}</b></div>
                           </div>
                       </li>
                       <li>
                           <div class="dp-flex">
                               <div class="flex1">검진확정일</div>
                               <div class="flex1 align-right"><b>{dateDisplay(row.checkUpConfirmDate)}</b></div>
                           </div>
                       </li>
                       <li>
                           <div class="dp-flex">
                               <div class="flex1">검진완료일</div>
                               <div class="flex1 align-right"><b>{dateDisplay(row.checkUpCmpDate)}</b></div>
                           </div>
                       </li>
                   </ul>
               </section>
               </div>
               </div>
           </React.Fragment>
        ))}

        <section>
            <div class="dp-flex">
                <button class="btn page-btn flex2 btn-primary" onClick={() => naviagte('/HC_6006')}>확인</button>
            </div>
        </section>
    </React.Fragment>)
}

export default HC_6022; 