/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 검진기관상세조회
 * HC-6009
 *
 *
 */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api'
import { amountDisplay, convertBase64ToURL } from '../utils/common';
import Swal from 'sweetalert2';
import { useRecoilValue } from 'recoil';
import { customerCode,userId } from '../data/Atom';
import HC_6009_Detail from './HC_6009_Sample';

function HC_6009_PREVIEW() {
    const naviagte = useNavigate();
    const location = useLocation();
    const data = location.state; // 전달된 데이터 가져오기 (hospitalCode)
    // const customerCodeValue = 'CP00000001';
    const customerCodeValue = useRecoilValue(customerCode);
    const userIdValue = useRecoilValue(userId);
    const [displayData, setDisplayData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const [images, setImages] = useState([]);
    const [currnetImgId, setCurrnetImgId] = useState(0);
    const [mapImage, setMapImage] = useState(null);
    const [selRow, setSelRow] = useState({});
    const [timer, setTimer] = useState(null); // 타이머 상태
    const isInitialRender = useRef(true);
    const [tabMode, setTabMode] = useState('A');

    function getData() {
        if (data !== null && data !== undefined) {
            api.get('/guest/cu/package/'+ data.hospitalCode).then(
        //  api.get('/cu/package/' + customerCodeValue + '/' + data.hospitalCode).then(
                response => {
                    response.data.thumbNailList.map((img, i) => {
                        const url = convertBase64ToURL(img.file)
                        setImages(prevArray => {
                            const newArray = [...prevArray];
                            newArray[parseInt(i)] = url;
                            return newArray;
                        });
                    });
                    if (response.data.hospitalMap !== undefined && response.data.hospitalMap !== null) {
                        setMapImage(convertBase64ToURL(response.data.hospitalMap));
                    }
                    setDisplayData(response.data);
                }
            ).catch(
                error => {
                    console.log("ERROR : " + JSON.stringify(error))
                }
            )
        } else {
            Swal.fire({
                title: '에러',
                text: '잘못된 접근입니다.',
                icon: 'error',
                confirmButtonText: '확인',
            }).then(response => {
                naviagte(-1);
            })
        }
    }


    function currentImgHandle(value) {
        setCurrnetImgId(value);
        resetTimer();
    }

    // 타이머 리셋 및 재설정
    const resetTimer = () => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setCurrnetImgId((prevIndex) => {
                const nextIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
                return nextIndex;
            });
        }, 3000);
        setTimer(newTimer);
    };

    // 컴포넌트가 마운트될 때 타이머 설정
    useEffect(() => {
        if (images.length !== 0) {
            resetTimer();
        }
        return () => {
            clearTimeout(timer);
        };
    }, [images.length, currnetImgId]);

    function seleted(row) {
        if (row.packageCode === selRow.packageCode) {
            setSelRow({});
        } else {
            setSelRow(row);
            setModalData(row);
            setShowModal(true);
        }
    }

    const closeModal = () => {
        setShowModal(false);
        setModalData({});
    }


    function nextPage() {
        if (selRow.packageCode === null || selRow.packageCode === undefined) {
            Swal.fire({
                title: '알림',
                text: '검진패키지가 선택되지 않았습니다.',
                icon: 'info',
                confirmButtonText: '확인',
            })
        } else {
            data.packageCode = selRow.packageCode;
            data.packageName = selRow.name;
            data.packageAmount = selRow.amount;
            if (userIdValue === '') {
              naviagte('/HC_6005', { state: data })
            } else {
              naviagte('/HC_6010', { state: data });
            }
        }
    }

    function tabChange(data) {
        setTabMode(data);
    }

    useEffect(() => {
        getData()
    }, [])



    return (
        <React.Fragment>
            <HC_6009_Detail showModal={showModal} closeModal={closeModal} row={selRow} data={data}/>
            <div class="space-l"></div>
            <section>
                <img class="w-100" style={{height:200}} alt="" src={currnetImgId === -1 ? "/images/testimg.jpg" : images[currnetImgId]} />
                <div class="dot-nav">
                    {images.map((_row, i) => (
                        <a onClick={() => currentImgHandle(i)} class={i === currnetImgId ? "on" : ""}></a>
                    ))}
                </div>
            </section>
            <section>
                <div class="checkup-subject">
                    <b class="ft-blue ft-m">{displayData.hospitalName}</b>
                    <div class="space-l"></div>
                    <div class="overflow-auto-y" style={{ whiteSpace: 'pre-line' }}>
                        {displayData.hospitalDesc ? displayData.hospitalDesc.replace(/<br>/g, '\n') : ''}<br />
                    </div>
                </div>
            </section>
            <section>
            <div class="tab-menu">
                <ul>
                    <li className={tabMode === 'A' ? 'active' : ''} onClick={() => tabChange('A')}><a>기관 보기</a></li>
                    <li className={tabMode === 'B' ? 'active' : ''} onClick={() => tabChange('B')}><a>검진상품 보기 </a> <span class="ft-orange">({displayData && displayData.packageList && displayData.packageList.length})</span></li>
                </ul>
            </div>
            </section>
            {(tabMode=='A') ? <HospitalAddress displayData={displayData} mapImage={mapImage} /> : <PackageList displayData={displayData} selRow={selRow} seleted={seleted}/>}
           
            <section>
            <div class="dp-flex">
                    <button class="btn page-btn flex1 m-r-s" onClick={() => naviagte('/HC_6008')}>이전</button>
                    <button class="btn page-btn flex2 btn-primary" onClick={() => nextPage()}>예약하기</button>
            </div>
            </section>
        </React.Fragment>
    )
}



function PackageList({ displayData, selRow, seleted }) {
    return (
      <section>
        {/* <div class="dp-flex list-title">
          <div class="mr-auto">
            <b class="ft-l">검진상품 보기<span class="ft-orange">({displayData && displayData.packageList && displayData.packageList.length})</span></b>
          </div>
        </div> */}
        <ul class="list-wrap">
          {displayData && displayData.packageList && displayData.packageList.map((row) => (
                        <li onClick={() => seleted(row)} style={row.packageCode === selRow.packageCode ? { backgroundColor: '#3296FF', color: 'white' } : null} >
                        {/* <li style={row.packageCode === selRow.packageCode ? { backgroundColor: '#0160DA', color: 'white' } : null} > */}
              <div class="ellipsis" >
                <p  class="ellipsis" className="package-name">{row.name}</p>
              </div>
              <b class= "ft-red fl-m">(고객우대가) </b>
              <span>{amountDisplay(row.amount + 20000)} 원</span>
            </li>
          ))}
        </ul>
      </section>
    );
  }
  
function HospitalAddress({ displayData, mapImage }) {
  return (
    <section>
      <div class="box-blue"><span class="ft-gray">({displayData.hospitalZipNo})</span> {displayData.hospitalAddress + ' ' + displayData.hospitalAddressDetail}</div>
      <img src={mapImage === null ? "/images/img_location01_map.jpg" : mapImage} class="w-100" alt="" />
      <div class="p-t-m">
        T) <b>{displayData.hospitalTelNo}</b><br />H)
        <a href={/^http/i.test(displayData.hospitalHomePage) ? displayData.hospitalHomePage : 'http://' + displayData.hospitalHomePage} target="_blank">
          {displayData.hospitalHomePage}</a>
      </div>
    </section>
  );
}



export default HC_6009_PREVIEW; 
