/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/** HC-6006
 *
 *
 */
import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName , isLogout, salesId} from '../data/Atom';
import { useNavigate } from 'react-router-dom';
import api from '../api/api'
import { useRecoilValue } from 'recoil';
import { dateDisplay } from '../utils/common'

function HC_6006() {
    const naviagte = useNavigate();
    const [userNameValue, setUserName] = useRecoilState(userName);
    const [userIdValue, setUserId] = useRecoilState(userId);
    const [userRoleValue, setUserRole] = useRecoilState(userRole);
    const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode);
    const [customerNameValue, setCustomerName] = useRecoilState(customerName);
    const [salesIdValue, setSaledId] = useRecoilState(salesId);
    const [isLogoutValue, setIsLogout] = useRecoilState(isLogout);
    // const customerCodeValue = useRecoilValue(customerCode);
    const [reserveCount, setReserveCount] = useState(0);
    const [qstCount, setQstCount] = useState(0);
    const [noticeValue, setNoticeValue] = useState([]);
    const [faqValue, setFaqValue] = useState([]);
    //const location = useLocation();

    function getReserveCount() {
        const url = '/cu/count/reserve';
        api.get(url).then(
            response => {
                setReserveCount(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getQstCount() {
        const url = '/cu/count/qst';
        api.get(url).then(
            response => {
                setQstCount(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getNotice() {
        api.get('/guest/notice/A/simple').then(
            response => {
                setNoticeValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function getFaq() {
        api.get('/guest/notice/B/simple').then(
            response => {
                setFaqValue(response.data);
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    function goNoticeDetail(divCode, serialNo) {
        api.get('/guest/notice/detail/' + divCode + '/' + serialNo).then(
            response => {
                naviagte('/HC_6024', { state: response.data })
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
            }
        )
    }

    useEffect(() => {
        getReserveCount()
    }, [])

    useEffect(() => {
        getQstCount()
    }, [])

    useEffect(() => {
        getNotice()
    }, [])

    useEffect(() => {
        getFaq()
    }, [])

    useEffect(() => {
        if(isLogoutValue) {
            naviagte(-1)
        }
    })

    function goNoticeList(div) {
        const sendData = {
            div: div,
            list: div === 'A' ? noticeValue : faqValue
        }
        naviagte('/HC_6023', { state: sendData })
    }
    
    return (
        <React.Fragment>
        <section>
            <div class="dp-flex">
                <div class="flex1 m-r-s overflow-hidden">
                    <div class="checkup-status " onClick={() => naviagte('/HC_6022')}>
                        <span class="ft-l"><img src="/images/m_ic_status.png" alt="" /> 검진예약현황</span>
                        <h2 class="align-right">{reserveCount}</h2>
                    </div>
                </div>
                <div class="flex1 overflow-hidden">
                    <div class="checkup-ask" onClick={() => naviagte('/HC_6027')}>
                        <span class="ft-l"><img src="/images/m_ic_ask.png" alt="" /> 1:1문의</span>
                        <h2 class="align-right">{qstCount}</h2>
                    </div>
                </div>
            </div>
        </section>

        <section>
                <div className="banner-hospital"  onClick={() => { naviagte('/HC_6008') }}>
                검진기관둘러보기
                    <img src="/images/img_hospital.png" alt="" />
                </div>
           </section>
        {/* <section>
            <div class="pg-title-s">
                    <span>검진기관 둘러보기</span>
                </div>
                <div class="search-form" onClick={() => { naviagte('/HC_6008') }}>
                    <input type="text" readOnly/><i class="icon-search"></i>
                </div>
            </section> */}
            
        {/* <section>
            <span><b class="ft-l" onClick={() => naviagte('/HC_6008')}>검진기관 둘러보기</b></span>
                <div class="dp-flex flex-j-between">
                    <div class="search-form" onClick={() => naviagte('/HC_6008')}>
                        <input type="text" /><a ><i class="icon-search"></i></a>
                    </div>
                    <img src="/images/img_hospital.png" alt="" />
                </div>
        </section> */}
        <section>
            <div class="dp-flex list-title">
                <div class="mr-auto">
                    <b class="ft-l">공지사항</b>
                </div>
                <div class="ml-auto">
                    <a onClick={() => { goNoticeList('A') }} ><i class="icon-arrow-next"></i></a>
                </div>
            </div>
            <ul class="list-wrap-noti">
                {noticeValue.map((row) => (
                    <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                        <div class="ellipsis">
                            <a class="ellipsis">{row.name}</a>
                        </div>
                        <span class="date">{dateDisplay(row.regDate)}</span>
                    </li>
                ))}
            </ul>
        </section>
        <section>
            <div class="dp-flex list-title">
                <div class="mr-auto">
                    <b class="ft-l">자주하시는 질문</b>
                </div>
                <div class="ml-auto">
                    <a onClick={() => { goNoticeList('B') }}><i class="icon-arrow-next"></i></a>
                </div>
            </div>
            <ul class="list-wrap-noti">
                {faqValue.map((row) => (
                    <li onClick={() => goNoticeDetail(row.divCode, row.serialNo)}>
                        <div class="ellipsis">
                            <a class="ellipsis">{row.name}</a>
                        </div>
                        <span class="date">{dateDisplay(row.regDate)}</span>
                    </li>
                ))}
            </ul>
        </section>
    </React.Fragment>
    )
}

export default HC_6006; 
