/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 
 * HC-5013
 *
 *
 */
import React, { useEffect, useState } from 'react'
import {useNavigate } from 'react-router-dom';
import api from '../api/api'
import Swal from 'sweetalert2';
import { useRecoilValue, useRecoilState } from 'recoil';
import { userName, userId, userRole, customerCode, customerName, salesId, isLogout } from '../data/Atom';

function HC_6007_out({ showModal, closeModal}) {
  const naviagte = useNavigate();
  const [packageSample,setPackageSample] = useState(null);
  const [packageDetail,setPackageDetail] = useState(null);
  const [showData,setShowData] = useState(null);
  const [activeBasicIndex, setActiveBasicIndex] = useState(null);
  const [activeOptionalSections, setActiveOptionalSections] = useState([]);
  const [radioText, setRadioText] = useState("");
  const [radioFlag, setRadioFlag] = useState('');
  const [radioOption, setRadioOption] = useState('');
  const [etcConfirm, setEtcConfirm] = useState('');
  const [userNameValue, setUserName] = useRecoilState(userName);
  const [userIdValue, setUserId] = useRecoilState(userId);
  const [userRoleValue, setUserRole] = useRecoilState(userRole);
  const [customerNameValue, setCustomerName] = useRecoilState(customerName)
  const [customerCodeValue, setCustomerCode] = useRecoilState(customerCode)
  const [salesIdValue, setSalesId] = useRecoilState(salesId)
  const [isLogoutValue, setIsLogout] = useRecoilState(isLogout)

  const closeHandle = () => {
    setPackageSample();
    setPackageDetail();
    setRadioFlag('N');
    setRadioText("");
    setRadioOption('');
    closeModal();
  }

  const checkRadio = (e) => {
    setRadioFlag('Y');
    setRadioOption(e);
  }

  const radioTextHandle = (e) => {
    setRadioText(e.target.value);
  }


  function outUserBtn() {
    // radio버튼 validation
    if (radioFlag === 'N' || radioFlag === '' || (radioOption === 'G' && radioText === '') ) {
      Swal.fire({
        title: '실패',
        text: '떠나는 이유를 선택해주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    }else {
      // 탈퇴실행
    Swal.fire({
      title: '경고',
      text: '탈퇴 안내를 확인해주세요. 정말 탈퇴하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '탈퇴',
      cancelButtonText: '취소',
    }).then((result) => {
      if(result.isConfirmed) {
        const url = '/member/outUser';
        
        const reqBody = {
          'radtioOption' : radioOption,
          'radioText' : radioText,
        }

        api.post(url,
          reqBody,
          ).catch(
            error => {
              console.log("ERROR : " + JSON.stringify(error))
         })
         
         // 로그아웃 처리
         Swal.fire({
          title: '알림',
          text: '탈퇴되었습니다.',
          icon: 'info',
          confirmButtonText: '확인',
        }).then ( response => {
          api.post('/token/logout/LOGOUT').then(
            response => {
                Swal.fire({
                    title: '알림',
                    text: '로그아웃 되었습니다.',
                    icon: 'info',
                    confirmButtonText: '확인',
                }).then(
                    res => {
                        setUserName('')
                        setUserId('')
                        setUserRole('')
                        setIsLogout('')
                        localStorage.removeItem('access');
                    }
                )
        
                if(salesIdValue) {
                    naviagte('/'+customerCodeValue + '/'+salesIdValue)
                }
                else {
                    naviagte('/'+customerCodeValue)
                }
    
            }
        ).catch(
            error => {
                console.log("ERROR : " + JSON.stringify(error))
                if(salesIdValue) {
                    naviagte('/'+customerCodeValue + '/'+salesIdValue)
                }
                else {
                    naviagte('/'+customerCodeValue)
                }
                
            }
        )}
        )

      }
    })
    }

    
  }

  useEffect(() => {
  },[showData])

  function initData() {
    setActiveBasicIndex(null);
    setActiveOptionalSections([]);
  }

  return (
    <React.Fragment>
      {
        showModal && (
          <div id="HC-5013" class="modal">
            <div class="modal-flex">
              <div class="modal-flex-content" style={{ width: '600px' }}>

                <div class="dp-flex">
                  <div class="pg-title"><div class="ft-l">회원탈퇴</div></div>
                  <div class="ml-auto"><a onClick={() => closeHandle()} class="ml-auto"><i class="icon-close ft-xl"></i></a></div>
                </div>
                <div><span class="ft-s" style={{color: 'red'}}>※아이디 재사용 및 복구 불가.</span></div>
                <span class="ft-xs">사용하고 계신 아이디를 탈퇴하시면 본인과 타인 모두 재사용 및 복구가 불가하오니 신중하게 선택하시기 바랍니다.</span>
                <div class="space-l"></div>
                
                <div class="form-wp">
              <section>
                <b>떠나는 이유를 알려주세요</b>
                <ul class="check-list-wrap">
                <div class="input-wp">
                  <div class="radio">
                    <li>
                      <input type='radio' name='option' id='A' checked={radioOption === 'A'} onClick={() => checkRadio('A')}/><label for='A'> 건강검진이 끝나고 더이상 서비스를 사용하지 않습니다.</label>
                    </li>
                    <li>
                     <input type='radio' name='option' id='B' checked={radioOption === 'B'} onClick={() => checkRadio('B')} /><label for='B'> 문의답변이 너무 느립니다.</label>
                    </li>
                    <li>
                      <input type='radio' name='option' id='C' checked={radioOption === 'C'} onClick={() => checkRadio('C')} /><label for='C'> 서비스에 오류가 너무 많습니다.</label>
                    </li>
                    <li>
                      <input type='radio' name='option' id='D' checked={radioOption === 'D'} onClick={() => checkRadio('D')} /><label for='D'> 개인정보가 유출될까봐 불안합니다.</label>
                    </li>
                    <li>
                      <input type='radio' name='option' id='E' checked={radioOption === 'E'} onClick={() => checkRadio('E')} /><label for='E'> 건강검진 패키지 가격이 저렴하지 않습니다.</label>
                    </li>
                    <li>
                      <input type='radio' name='option' id='G' checked={radioOption === 'G'} onClick={() => checkRadio('G')} /><label for='G'> 기타</label>
                      <input class="w-100" type="text" onChange={(e) => { radioTextHandle(e) }} />
                    </li>
                  </div>
                </div>
                </ul>
              </section>
            
      </div>
              <div class="dp-flex">
                <button class="btn page-btn flex2 btn-primary" onClick={() => outUserBtn()}>탈퇴</button>
              </div>
              </div>
            </div>
          </div>
        )
      }

    </React.Fragment>
  )
}

export default HC_6007_out; 
