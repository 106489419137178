/**
 * 
 * HC-6007
 *
 *
 */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { userId } from '../data/Atom'
import { useRecoilValue } from 'recoil';
import Swal from 'sweetalert2';
import api from '../api/api'
import { passwordValid } from '../utils/common'
import HC_6007_outUser from './HC_6007_out';

function HC_6007() {
  const navigate = useNavigate();
  const userIdValue = useRecoilValue(userId);
  const [currentPassword, setCurrentPassword] = useState('');
  const [modPassword, setModPassword] = useState('');
  const [modPasswordConfirm, setModPasswordConfirm] = useState('');
  const [showModal, setShowModal] = useState(false);
  //const data = location.state; // 전달된 데이터 가져오기 (hospitalCode)

  function validation() {
    if (currentPassword === '') {
      Swal.fire({
        title: '오류',
        text: '현재 비밀번호를 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else if(!passwordValid(modPassword)) {
      Swal.fire({
        title: '실패',
        text: '비밀번호는 숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요.',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      })
    } else if (modPassword === '') {
      Swal.fire({
        title: '오류',
        text: '새 비밀번호를 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else if (modPasswordConfirm === '') {
      Swal.fire({
        title: '오류',
        text: '새 비밀번호확인 부분을 입력해 주세요',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else if (modPassword !== modPasswordConfirm) {
      Swal.fire({
        title: '오류',
        text: '변경할 비밀번호가 일치하지 않습니다',
        icon: 'error',
        confirmButtonText: '확인',
      }).then(response => {
        return false;
      });
    } else {
      return true;
    }
  }

  function modfiy() {
    if (validation()) {
      const url = '/member/password';
      const reqBody = {
        currentPw: currentPassword,
        changePw: modPassword
      }
      api.patch(
        url,
        reqBody
      ).then(response => {
        if (response.data) {
          Swal.fire({
              title: '변경완료',
              text: '정상적으로 변경 되었습니다.',
              icon: 'success',
              confirmButtonText: '확인',
            }).then((result)=> {
              if(result.isConfirmed) {
                navigate('/HC_6006')
              }
            });
        } else {
          Swal.fire({
            title: '변경실패',
            text: '현재 비밀번호를 다시 확인해 주세요.',
            icon: 'error',
            confirmButtonText: '확인',
          });
        }
        // 응답 처리
      }).catch(error => {
        Swal.fire({
          title: '변경실패',
          text: '비밀번호변경간 오류가 발생했습니다. 다시 시도해 주세요.',
          icon: 'error',
          confirmButtonText: '확인',
        });
      });
    }
  }

  const closeModal = () => {
    setShowModal(false);
  }

  function outUser() {

    api.get("/member/hasReserve")
    .then((response) => {
      if(response.data) {
        Swal.fire({
          title: '알림',
          html: '기예약 정보가 존재합니다.<br>관리자에게 문의주세요.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
        })
        return false;
      }
    })

    
    Swal.fire({
      title: '알림',
      text: '정말로 탈퇴하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then((result) => {
      if(result.isConfirmed) {
        //navigate('/HC_6007_outMember')
        setShowModal(true);
      }
     
    })
  }

  const currentPwHandle = (e) => {
    setCurrentPassword(e.target.value);
  }

  const changePwHandle = (e) => {
    setModPassword(e.target.value);
  }

  const confimrPwHandle = (e) => {
    setModPasswordConfirm(e.target.value);
  }

  return (
    <React.Fragment>
      <HC_6007_outUser showModal={showModal} closeModal={closeModal}/>
      <div class="pg-title">
        <span>비밀번호변경</span>
      </div>
      <section>
        <div class="form-wp">
          <div class="input-wp">
            <p>아이디 </p>
            <div class="dpview">{userIdValue}  </div>
          </div>
          <div class="input-wp">
            <p>현재 비밀번호</p>
            <input class="w-100" type="password" value={currentPassword} onChange={(e) => currentPwHandle(e)} />
          </div>
          <div class="input-wp">
            <p>새 비밀번호</p>
            <input class="w-100" type="password" value={modPassword} onChange={(e) => changePwHandle(e)} />
          </div>
          <div class="input-wp">
            <p>새 비밀번호확인</p>
            <input class="w-100" type="password" value={modPasswordConfirm} onChange={(e) => confimrPwHandle(e)} />
          </div>
        </div>
      </section>
      <section>
        <div class="dp-flex">
          <button class="btn page-btn flex2 btn-primary" onClick={() => modfiy()}>변경</button>
        </div>
        <div class="dp-flex">
          <button class="btn page-btn flex2 btn-"  onClick={() => outUser()}>탈퇴하기</button>
        </div>
      </section>
    </React.Fragment>
  )
}

export default HC_6007; 
